/* eslint-disable prettier/prettier */
/* eslint-disable no-template-curly-in-string */
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import React, { useState } from 'react';
import { dateFormat } from 'src/constants';
import {
  calculatorDayFromRangeDay,
  handleDataConvert,
  s2ab,
} from 'src/helpers';
import * as timesheetServices from 'src/services/timesheet';
import { IQueryParamTimesheet } from 'src/services/timesheet';
import XlsxTemplate from 'xlsx-template';
const queryString = require('query-string');
interface IExportTimeSheetProps {
  param: IQueryParamTimesheet;
}
const ExportTimeSheet: React.FC<IExportTimeSheetProps> = ({ param }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    startTime = moment(moment().format('YYYY-MM-01')).unix(),
    endTime = moment(moment().endOf('month').format('YYYY-MM-DD')).unix(),
  } = param;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const fileName = `Timesheet ${moment
    .unix(startTime)
    .format(dateFormat.DMY)}/${moment.unix(endTime).format(dateFormat.DMY)}`;
  const handleOnClickExport = async () => {
    setIsLoading(true);
    param.status = 'approved';
    const stringified = queryString.stringify({ ...param });
    try {
      const { data: dataGet } = await timesheetServices.getAllRequestTimesheet(
        stringified,
      );
      const dataConvert = handleDataConvert(dataGet.data);
      await axios
        .get('template-timesheet.xlsx', { responseType: 'arraybuffer' })
        .then((data) => {
          var template = new XlsxTemplate(data.data);
          var sheetNumber = 1;
          const dataOutput = {
            people: dataConvert.member,
            day: dataConvert.type,
            header: calculatorDayFromRangeDay(startTime).withYear,
            date: calculatorDayFromRangeDay(startTime).noYear,
          };
          template.substitute(sheetNumber, dataOutput);
          const a = template.generate() as any;
          FileSaver.saveAs(
            new Blob([s2ab(a)], { type: fileType }),
            fileName + fileExtension,
          );
        });
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  };

  return (
    <div className="date-header">
      <Tooltip
        placement="top"
        title={
          moment.unix(startTime).format('M') !==
          moment.unix(endTime).format('M')
            ? 'Could not export *Timesheet* for other months'
            : 'Export Timesheet'
        }
      >
        <Button
          disabled={
            moment.unix(startTime).format('M') !==
            moment.unix(endTime).format('M')
          }
          type="primary"
          onClick={handleOnClickExport}
          loading={isLoading}
          icon={<DownloadOutlined />}
        >
          Timesheet
        </Button>
      </Tooltip>
    </div>
  );
};
export default ExportTimeSheet;
