import React from 'react';
import { Layout } from 'antd';
import './style.less';
import { SiderBar, HeaderBar, FooterBar } from '../../components/index';
import BreadCrumbLink from '../../components/BreadCrumbLink';

const { Content } = Layout;
type TLayoutPage = {
  children: React.ReactNode;
};
const LayoutPage: React.FC<TLayoutPage> = (props: TLayoutPage) => {
  return (
    <Layout className="layout">
      <SiderBar />
      <Layout className="site-layout">
        <HeaderBar />
        <div className="content-scroll">
          <Content className="content">
            <BreadCrumbLink />
            <div className="content__body">{props.children}</div>
          </Content>
          <FooterBar />
        </div>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
