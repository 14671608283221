import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loading, PrivateRoute, PublicRoute } from './components';
import { AuthContext } from './contexts/Auth';
import { PlatformSigin } from './contexts/Payment';
import { isLogin } from './helpers';
import AddToSlack from './pages/Auth/AddToSlack';
import SignWithSlack from './pages/Auth/SignWithSlack';
import { ConfigWorkspace } from './pages/ConfigWorkspace';
import DetailTeam from './pages/DetailTeam';
import Errors from './pages/Errors';
import Home from './pages/Home';
import Login from './pages/Login';
// import Members from './pages/Members';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Payment from './pages/Payment/index';
import PaymentsHistory from './pages/PaymentHistory';
import { PaymentPackage } from './pages/PaymentPackage';
import Profile from './pages/Profile';
import QuestionPacks from './pages/QuestionPacks';
import { Register } from './pages/Register';
import Reminder from './pages/Reminder';
import Report from './pages/Report';
import { TeamPage } from 'src/pages/Team';
import { Timesheet } from './pages/Timesheet';
import WorkSpace from './pages/WorkSpace';
import * as userService from './services/user';
import { IUser } from './services/user';
import { Members } from './pages/Members';
import Departments from 'src/pages/Departments';
import { UserRole } from 'src/constants/Roles';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const App: React.FC = () => {
  toast.configure({
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
  const listAdminPage = [
    { link: '/teams/:id', page: <DetailTeam /> },
    { link: '/members', page: <Members /> },
    { link: '/question-packs', page: <QuestionPacks /> },
    { link: '/reports', page: <Report /> },
    { link: '/workspace', page: <WorkSpace /> },
    { link: '/timesheet', page: <Timesheet /> },
    { link: '/payments', page: <PaymentPackage /> },
    { link: '/history', page: <PaymentsHistory /> },
    { link: '/departments', page: <Departments /> },
  ];
  const listUserPage = [
    { link: '/reports', page: <Report /> },
    { link: '/timesheet', page: <Timesheet /> },
  ];
  const [user, setUser] = useState<Partial<IUser>>({});
  const [platform, setPlatform] = useState<string>('slack');
  const [loading, setLoading] = useState(true);
  const getUserInfo = useCallback(async () => {
    try {
      const { data } = await userService.getUserInfo();
      if (data.data) {
        setUser(data.data);
      }
    } catch (e) {
      throw e;
    }
  }, []);
  useEffect(() => {
    if (isLogin()) {
      getUserInfo();
    }
    setLoading(false);
  }, [getUserInfo]);

  return loading ? (
    <Loading />
  ) : (
    <Router>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GG_CLIENT_ID as string}
      >
        <AuthContext.Provider value={{ user, setUser, getUserInfo }}>
          <PlatformSigin.Provider value={{ platform, setPlatform }}>
            <Switch>
              <PublicRoute restricted={true} path="/login">
                <Login />
              </PublicRoute>
              <PublicRoute restricted={false} path="/register">
                <Register />
              </PublicRoute>
              <PrivateRoute exact path="/">
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute path="/reminders">
                <Reminder />
              </PrivateRoute>
              <PrivateRoute path="/payment">
                <Payment />
              </PrivateRoute>
              <PrivateRoute path="/contact">
                <Contact />
              </PrivateRoute>
              <PublicRoute restricted={false} path="/add-to-slack">
                <AddToSlack />
              </PublicRoute>
              <PublicRoute restricted={false} path="/sign-in-with-slack">
                <SignWithSlack />
              </PublicRoute>
              <PublicRoute restricted={false} path="/workspace-config/:domain">
                <ConfigWorkspace />
              </PublicRoute>
              <PublicRoute restricted={false} path="/error">
                <Errors />
              </PublicRoute>
              {user &&
              [UserRole.WORKSPACE_ADMIN, UserRole.DEPARTMENT_ADMIN].includes(
                user.role!,
              ) ? (
                <>
                  <PrivateRoute exact path="/teams">
                    <TeamPage />
                  </PrivateRoute>
                  {listAdminPage.map((item, index) => (
                    <PrivateRoute path={item.link} key={index}>
                      {item.page}
                    </PrivateRoute>
                  ))}
                </>
              ) : (
                <>
                  {listUserPage.map((item, index) => (
                    <PrivateRoute path={item.link} key={index}>
                      {item.page}
                    </PrivateRoute>
                  ))}
                </>
              )}
              <PublicRoute restricted={false} path="*">
                <NotFound />
              </PublicRoute>
            </Switch>
          </PlatformSigin.Provider>
        </AuthContext.Provider>
      </GoogleOAuthProvider>
    </Router>
  );
};

export default App;
