/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Spin } from 'antd';
import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts';
import React, { useEffect, useState } from 'react';
import * as timesheetServices from 'src/services/timesheet';
import { IDataCharts, IQueryParamTimesheet } from 'src/services/timesheet';
const queryString = require('query-string');

interface IChartsReportDateType {
  param: IQueryParamTimesheet;
}
const ChartsReportDate: React.FC<IChartsReportDateType> = ({ param }) => {
  const [dataCharts, setDataCharts] = useState<IDataCharts[]>([]);
  const [totalRequest, setTotalRequest] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const { page, offset, ...newParam } = param;
    const stringified = queryString.stringify(newParam);
    const getDataCharts = async () => {
      try {
        const { data } = await timesheetServices.getAllRequestTimesheet(
          stringified,
        );
        if (data.data) {
          setLoading(false);
          setDataCharts(convertData(data.data));
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    getDataCharts();
    return abortController.abort();
  }, [param]);
  const convertData = (dataInput): IDataCharts[] => {
    const dataOutPut: any = [];
    const monthReport = dataInput.map((month) => {
      const date = new Intl.DateTimeFormat(['ban', 'id']).format(
        new Date(Number(month.timestamp) * 1000),
      );
      const dateFormat = date.slice(0, date.length - 5);
      return dateFormat;
    });
    const uniqueMonth = Array.from(new Set(monthReport));
    const mapDataTimesheetGetType: string[] = dataInput.map((data2) => {
      return data2.request.type;
    });
    const uniqueType = Array.from(new Set(mapDataTimesheetGetType));
    uniqueMonth.forEach((month) => {
      const filterDate = dataInput.filter((data) => {
        const date = new Intl.DateTimeFormat(['ban', 'id']).format(
          new Date(Number(data.timestamp) * 1000),
        );
        const dateFormat = date.slice(0, date.length - 5);
        return dateFormat === month;
      });
      uniqueType.forEach((type) => {
        const filterType = filterDate.filter(
          (member) => member.request.type === type,
        );
        dataOutPut.push({
          month,
          city: type,
          revenue: filterType.length,
        });
      });
    });

    dataOutPut.sort(
      (a, b) => Number(a.month.slice(0, 2)) - Number(b.month.slice(0, 2)),
    );
    const maxCount: number[] = dataOutPut.map((count) => count.revenue);
    const listTotalRequest = Array.from(
      { length: Math.max(...maxCount) + 3 },
      (x, i) => i,
    );
    setTotalRequest(listTotalRequest);
    return dataOutPut;
  };

  const scale = {
    revenue: {
      range: [0, 1],
      ticks: totalRequest,
    },
    month: {
      range: [0, 1],
    },
  };
  return (
    <>
      {isLoading ? (
        <Spin />
      ) : dataCharts.length > 0 ? (
        <Chart height={400} data={dataCharts} scale={scale} autoFit>
          <Legend />
          <Axis name="month" />
          <Axis
            name="revenue"
            label={{
              formatter: (val) => val,
            }}
          />
          <Tooltip showCrosshairs shared />
          <Geom
            type="line"
            tooltip={[
              'revenue*city',
              (value, name) => {
                return {
                  value: `${value} request`,
                  name,
                };
              },
            ]}
            position="month*revenue"
            size={2}
            color={'city'}
          />
          <Geom
            type="point"
            tooltip={true}
            position="month*revenue"
            size={4}
            shape={'circle'}
            color={'city'}
            style={{
              stroke: '#fff',
              lineWidth: 1,
            }}
          />
        </Chart>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default ChartsReportDate;
