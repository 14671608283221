import React, { useState, useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { IQuestionPack } from '../../services/team';
import * as questionPackService from '../../services/questionPack';
import * as TeamService from '../../services/team';
import { Table, Space, Tooltip, Modal, Button, Divider } from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  PlusOutlined,
  EditFilled,
  EyeOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ChangeQuestionPackModal, ViewQuestionPackModal } from './components';
import { questionPackNotify } from '../../constants';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
const { confirm } = Modal;

const QuestionPacks = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [listQuestionPacks, setListQuestionPacks] = useState<IQuestionPack[]>(
    [],
  );
  const loadQuestionPacks = async () => {
    try {
      const questionPacks = await TeamService.getListQuestionPacks();
      //console.log(questionPacks.data.data);
      if (questionPacks.data.data) {
        setListQuestionPacks(
          questionPacks.data.data
            //      .filter((item) => item.workspaceId !== 'default')
            .map((item) => {
              let newItem = { ...item };
              newItem.key = item._id;
              return newItem;
            }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showDeleteConfirm = (record: IQuestionPack) => {
    confirm({
      title: 'Are you sure to delete this question pack?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        //  console.log('OK', record);
        try {
          let res = await questionPackService.deleteQuestionPack(record._id);
          if (res) {
            await loadQuestionPacks();
            toast.success(questionPackNotify.delete);
          }
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right' as 'right',
      render: (_: any, record: IQuestionPack) => (
        <Space size="small">
          <ViewQuestionPackModal
            title={record.name}
            BtnContent={
              <Tooltip title="View">
                <EyeOutlined
                  style={{
                    color: '#52c41a',
                    paddingRight: 8,
                  }}
                />
              </Tooltip>
            }
            detailQuestionPack={record}
          />
          {record.workspaceId !== 'default' && (
            <>
              <ChangeQuestionPackModal
                type="UPDATE"
                title="Update Question Pack"
                BtnContent={
                  <Tooltip title="Edit">
                    <EditFilled
                      style={{
                        color: '#faad14',
                        paddingRight: 8,
                      }}
                    />
                  </Tooltip>
                }
                detailQuestionPack={record}
                reload={loadQuestionPacks}
              />
              <Tooltip title={record.onTheTeam ? 'On the team' : 'Delete'}>
                <Button
                  danger
                  type="link"
                  disabled={record.onTheTeam}
                  icon={
                    <DeleteFilled
                      onClick={() => {
                        showDeleteConfirm(record);
                      }}
                    />
                  }
                />
              </Tooltip>
            </>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await loadQuestionPacks();
      setIsLoading(false);
    })();
  }, []);

  return (
    <MainLayout>
      <PageHeader headerName="Question packs">
        <ChangeQuestionPackModal
          type="ADD"
          title="Add new question pack"
          BtnContent={
            <Button type="primary" icon={<PlusOutlined />}>
              Create new question pack
            </Button>
          }
          detailQuestionPack={{}}
          reload={loadQuestionPacks}
        />
      </PageHeader>
      <Divider />
      <div className="question-pack-list">
        <SectionHeader
          className="question-pack-list__header"
          sectionName="List of question packages"
        />
        <Table
          className="question-pack-list__table"
          columns={columns}
          dataSource={listQuestionPacks}
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

export default QuestionPacks;
