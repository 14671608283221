/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Button, TimePicker, Tag, Input, Form, Switch } from 'antd';
import moment from 'moment';
import * as notifyService from '../../services/reminder';
import { IReportReminder } from '../../services/reminder';
import { listDays } from '../../constants';

type ScheduleModalProps = {
  reminder: IReportReminder;
  teamId: string;
  handleClose?: () => void;
  onDetailTeam?: boolean;
  reload?: () => Promise<void>;
};
export const ReportReminder: React.FC<ScheduleModalProps> = (
  props: ScheduleModalProps,
) => {
  const { CheckableTag } = Tag;
  const [form] = Form.useForm();
  const { reminder, teamId, handleClose, onDetailTeam } = props;
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [detailReminder, setDetailReminder] = useState<IReportReminder>({
    ...reminder,
  });

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const tailLayout = {
    wrapperCol: { offset: 18, span: 4 },
  };
  const handleClick = (isSelect) => {
    setDetailReminder({ ...detailReminder, status: isSelect });
  };
  const handleChange = (tag?, checked?) => {
    setDisableSubmit(false);
    const nextSelectedTags = checked
      ? [...detailReminder.days, tag]
      : detailReminder.days.filter((t) => t !== tag);
    setDetailReminder({ ...detailReminder, days: nextSelectedTags });
  };

  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      let res = await notifyService.updateReminderTeam({
        teamId: teamId,
        updateReminder: {
          ...detailReminder,
          time: values.time.format('HH:mm:ss'),
          days: detailReminder.days,
          message: values.message,
        },
      });
      if (res.data) {
        setConfirmLoading(false);
        setDisableSubmit(true);
        if (handleClose) {
          handleClose();
        }
      }
      props.reload && props.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onValuesChange = () => {
    setDisableSubmit(false);
  };
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);
  return (
    <Form
      {...layout}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      onValuesChange={onValuesChange}
      initialValues={{
        time: moment(detailReminder.time, 'h:mm'),
        message: detailReminder.message,
      }}
    >
      {onDetailTeam && (
        <Form.Item label="Enable Report">
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={detailReminder.status}
            onClick={handleClick}
            onChange={handleChange}
          />
        </Form.Item>
      )}

      <Form.Item label="Work days">
        {listDays.map((tag) => (
          <CheckableTag
            key={tag}
            checked={detailReminder.days.indexOf(tag) > -1}
            onChange={(checked) => handleChange(tag, checked)}
          >
            <Button
              shape="circle"
              type={
                detailReminder.days.indexOf(tag) > -1 ? 'primary' : 'default'
              }
            >
              {tag}
            </Button>
          </CheckableTag>
        ))}
      </Form.Item>
      <Form.Item
        name="time"
        label="Time Reminder"
        rules={[
          {
            required: true,
            message: 'Please select time!',
          },
        ]}
      >
        <TimePicker format="HH:mm" />
      </Form.Item>
      <Form.Item
        name="message"
        label="Message"
        rules={[
          {
            required: true,
            message: 'Please input your message!',
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          loading={confirmLoading}
          disabled={disableSubmit}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
