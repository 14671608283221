import React from 'react';

type TPaymentContext = {
  platform: string;
  setPlatform: React.Dispatch<string>;
};

export const PlatformSigin = React.createContext<TPaymentContext>({
  platform: 'slack',
  setPlatform: () => {},
});
