import { IResponse } from '../../types/response.interface';
import { IReportReminder, INewReminder } from './reminder.interface';
import axiosClient from '../../configs/axios.configs';
import { ResourceAPI } from 'src/configs/axiosInstances';

export const getReminderTeam = (teamId: string) => {
  return axiosClient.get<IResponse<IReportReminder>>(
    'notify/report-notify/' + teamId,
  );
};

export const updateReminderTeam = (reminder: {
  teamId: string;
  updateReminder: Partial<IReportReminder>;
}) => {
  return axiosClient.patch<IResponse<boolean>>(
    'notify/report-notify/' + reminder.teamId,
    reminder.updateReminder,
  );
};

export const getAllReminders = () => {
  return ResourceAPI.get<IResponse<INewReminder[]>>('reminders');
};
export const createReminder = (reminder: Partial<INewReminder>) => {
  return ResourceAPI.post<IResponse<INewReminder>>('reminders', reminder);
};
export const updateAReminder = (reminder: Partial<INewReminder>) => {
  const { _id, ...rest } = reminder;
  return ResourceAPI.put<IResponse<INewReminder>>(
    'reminders/' + reminder._id,
    rest,
  );
};
export const copyReminder = (reminder: Partial<INewReminder>) => {
  const { _id, ...rest } = reminder;
  return ResourceAPI.post<IResponse<INewReminder>>('reminders/copy', rest);
};
export const deleteAReminder = (reminderID) => {
  return ResourceAPI.delete<IResponse<boolean>>('reminders/' + reminderID);
};

export const toggleReminder = (id: string, isEnabled: boolean) => {
  return ResourceAPI.put<IResponse<boolean>>(`reminders/${id}/enable`, {
    enable: isEnabled,
  });
};

export const getReminder = (id: string) => {
  return ResourceAPI.get<IResponse<INewReminder & { isSpaceManager: boolean }>>(
    `reminders/${id}`,
  );
};

export const getListReminders = () => {
  return axiosClient.get<IResponse<IReportReminder[]>>('notify/flex-notify');
};
export const postReminder = (reminder: IReportReminder) => {
  return axiosClient.post<IResponse<IReportReminder>>(
    'notify/flex-notify',
    reminder,
  );
};
export const updateReminder = (reminder: IReportReminder) => {
  return axiosClient.patch<IResponse<IReportReminder>>(
    'notify/flex-notify/' + reminder._id,
    reminder,
  );
};
export const deleteReminder = (reminderID) => {
  return axiosClient.delete<IResponse<IReportReminder>>(
    'notify/flex-notify/' + reminderID,
  );
};
