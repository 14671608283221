import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import './styles.less';
import * as images from '../../assets/images/Help/image';
interface Props {
  modalVisible: boolean;
  handleModalVisibleChange: () => void;
}
export const Help = (props: Props) => {
  const [page, setPage] = useState(1);
  let textContent, title: string;
  const totalPage = 9;
  const firstParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>An overview of VMO Bot, how to config, deploy and more.</p>
        <p>
          This Bot can help you manage teams and reports,manage notifications.
          Besides, it can also send reminders, remind members on your team to
          report and send birthday congratulations. First create a new question
          pack and a new team.
        </p>
        <img src={images.DisplayExample} alt="" />
      </div>
    );
  };
  const secondParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>Manage your team and all members as well.</p>
        <p>Manage when the Bot will send reminders to the channel.</p>
        <img src={images.TeamsExample} alt="" />
      </div>
    );
  };

  const thirdParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>Manage your team and all members as well.</p>
        <p>Manage when the Bot will send reminders to the channel.</p>
        <div className="image-container">
          <img src={images.TeamsReminder} alt="" />
          <img src={images.TeamsSlack} alt="" />
        </div>
      </div>
    );
  };
  const fourthParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>Manage Teams’s Question Packs.</p>
        <p>
          Question Packs includes questionnaires, hints, types of questions.
        </p>
        <p>
          Question Packs are used in Teams and automatically generated
          questions. The Bot will send those questions to the users one by one.
        </p>
        <img src={images.QuestionExample} alt="" />
      </div>
    );
  };
  const fifthParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>
          Display all member's information including Name, Nickname, Avatar,
          Account type, Phone number, Date of Birth).
        </p>
        <p>Click on the [Action] icon to update the Team Members’ Birthday.</p>
        <img src={images.MemberExample} alt="" />
      </div>
    );
  };
  const sixthParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>Manage all Reminders. </p>
        <p>Create Reminders: only one time, daily, weekly, monthly, yearly.</p>
        <p> Filter reminders by Channels, or Types, or Status.</p>
        <p>
          Reminders will automatically sent to your channel by pre-settings.
        </p>
        <div className="image-container">
          <img src={images.ReminderWeb} alt="" />
          <img src={images.ReminderSlack} alt="" />
        </div>
      </div>
    );
  };
  const seventhParagraph = () => {
    return (
      <div className={`paragraph-${page}`}>
        <p>
          Display percentage of members reporting, reports submitted by each
          team, the number of obstacles, and a list of obstacles including
          members, teams, content of obstacles.
        </p>
        <p>
          Click on the pie chart to see a team’s report details: Date range, the
          number of reports, and the number of obstacles per day.
        </p>
        <p>All reports are displayed in the Table.</p>
        <p>Dump and export to Excel file.</p>
        <div className="image-container">
          <img src={images.ChartReport} alt="" />
          <img src={images.TableReport} alt="" />
        </div>
      </div>
    );
  };
  const eighthParagraph = () => {
    return (
      <div className="paragraph-8">
        <p>
          Configure notifications for Reminders and occasions of Birthday, or
          also remind users in case of missing reports.
        </p>
        <div className="image-container">
          <img src={images.WorkspaceReminderWeb} alt="" />
          <img src={images.WorkspaceReminderSlack} alt="" />
        </div>
      </div>
    );
  };

  const nineParagraph = () => {
    return (
      <div className="paragraph-9">
        <p>
          The Birthday configuration is used for configuring birthday
          notifications. After configuring, the bot will send birthday
          congratulations to the users on their birthday.
        </p>
        <div className="image-container">
          <img src={images.WorkspaceBirthdayWeb} alt="" />
          <img src={images.WorkspaceBirthdaySlack} alt="" />
        </div>
      </div>
    );
  };
  switch (page) {
    case 1:
      textContent = firstParagraph();
      title = 'Getting started';
      break;
    case 2:
      textContent = secondParagraph();
      title = 'Teams';
      break;
    case 3:
      textContent = thirdParagraph();
      title = 'Teams';
      break;
    case 4:
      textContent = fourthParagraph();
      title = 'Question Packs';
      break;
    case 5:
      textContent = fifthParagraph();
      title = 'Members';
      break;
    case 6:
      textContent = sixthParagraph();
      title = 'Reminders';
      break;
    case 7:
      textContent = seventhParagraph();
      title = 'Report';
      break;
    case 8:
      textContent = eighthParagraph();
      title = 'Workspace';
      break;
    default:
      textContent = nineParagraph();
      title = 'Workspace';
      break;
  }

  const nextClick = () => {
    if (page < totalPage) {
      setPage(page + 1);
    } else {
      props.handleModalVisibleChange();
    }
  };

  const previousClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const cancelClick = () => {
    props.handleModalVisibleChange();
  };

  return (
    <div>
      <Modal
        mask={false}
        className="my-modal-class"
        width={950}
        title="Help"
        visible={props.modalVisible}
        cancelText="Previous"
        onOk={() => nextClick()}
        onCancel={() => cancelClick()}
        centered
        style={{ left: 50 }}
        bodyStyle={{
          height: 400,
          backgroundColor: '#ededed',
          position: 'relative',
        }}
        afterClose={() => setPage(1)}
        footer={[
          <Button key="back" onClick={previousClick} hidden={page === 1}>
            Previous
          </Button>,
          <Button type="primary" onClick={nextClick}>
            {page === totalPage ? 'Finished!' : 'Next'}
          </Button>,
        ]}
      >
        <h1>{title}</h1>
        <div className="content-container ">
          <div className="content">{textContent}</div>
        </div>
      </Modal>
    </div>
  );
};
