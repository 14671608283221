import { Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import './style.less';
import { useMemberContext } from 'src/contexts/MemberContext';

export const SearchMemberInput = () => {
  const [text, setText] = useState('');
  const [deboundText, setDeboundText] = useState(text);
  const { setSearchText, setLoading, setPagination } = useMemberContext();

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeId = setTimeout(() => {
      setDeboundText(text);
    }, 700);
    return () => {
      clearTimeout(timeId);
    };
  }, [text]);

  useEffect(() => {
    setLoading(true);
    setPagination((prevState) => ({
      ...prevState,
      currentPage: 1,
      offset: 0,
    }));
    setSearchText(deboundText);
  }, [deboundText, setLoading, setPagination, setSearchText]);

  return (
    <Input
      placeholder="Search for members..."
      value={text}
      onChange={(e) => setText(e.target.value)}
      prefix={<SearchOutlined />}
    />
  );
};
