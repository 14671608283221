//import { API_URL } from './../constants';
import axios from 'axios';
import { isLogin } from '../helpers';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosClient.interceptors.request.use(async (config) => {
  if (isLogin()) {
    config.headers.token = localStorage.getItem('token');
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    // console.log(response.data);
    return response;
  },
  (error) => {
    // console.log('ERROR RES', error);
    // if (!error.response) {
    //   // localStorage.removeItem('token');
    //   // window.location.href = '/error/500';
    // }
    switch (error.response?.status) {
      //   case 401:
      //     // localStorage.removeItem('token');
      //     // window.location.href = '/error/401';
      //     break;
      case 403:
        localStorage.removeItem('token');
        window.location.href = '/login';
        break;
      //   case 500:
      //     window.location.href = '/error/500';
      //     break;
      //   default:
      //     // window.location.href = '/error';
      //     break;
    }

    return error;
  },
);
export default axiosClient;
