import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Button,
  Space,
  Tooltip,
  Table,
  Divider,
  Switch,
  Form,
  Select,
} from 'antd';
import { toast } from 'react-toastify';
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import * as TeamService from '../../services/team/team.services';
import * as MemberService from 'src/services/member';

import { IMember } from '../../services/member/member.interface';
import { memberNotify } from '../../constants';
import { MemberInfo } from '../MemberInfo';
import { removeAccents } from 'src/helpers';

const { Option } = Select;
const { confirm } = Modal;
type ManageMemberProps = {
  teamId: string;
  membersOnTeam: { id: string; active: boolean }[];
  reload: () => Promise<void>;
};

export const ManageMember: React.FC<ManageMemberProps> = (
  props: ManageMemberProps,
) => {
  const { teamId, membersOnTeam, reload } = props;
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [listMembersInTeam, setListMembersInTeam] = useState<IMember[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const loadMembers = useCallback(() => {
    setLoading(true);
    let keyIndex = 0;
    try {
      (async () => {
        const { data: membersListActive } =
          await MemberService.getListActiveMember();
        if (membersListActive.data) {
          setLoading(false);
          const teamMembers = membersListActive?.data.map((item, index) => {
            for (let i = 0; i < membersOnTeam.length; i++) {
              if (membersOnTeam[i].id === item.userId) {
                keyIndex++;
                return {
                  ...item,
                  key: item.userId,
                  onTeam: true,
                  no: keyIndex,
                  active: membersOnTeam[i].active,
                };
              }
            }
            return {
              ...item,
              key: item.userId,
              onTeam: false,
              no: index + 1,
              active: false,
            };
          });
          setListMembersInTeam(teamMembers);
        }
      })();
    } catch (error) {
      throw error;
    }
  }, [membersOnTeam]);

  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      let res = await TeamService.addMemberForTeam({
        members: values.members,
        _id: teamId,
      });
      if (res.data.data) {
        await reload();
        toast.success(memberNotify.create);
        setConfirmLoading(false);
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeSwitch = async (isSelect, record) => {
    record.active = isSelect;
    try {
      let res = await TeamService.updateActiveMember({
        teamId,
        memberId: record.userId,
      });
      if (res) {
        await reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure to remove this member?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          let res = await TeamService.removeMemberOnTeam({
            teamId,
            userId: record.userId,
          });
          if (res) {
            await reload();
            toast.success(memberNotify.delete);
          }
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        //console.log('Cancel', record);
      },
    });
  };

  const columnsTableDepartmentModal = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Name',
      dataIndex: 'real_name',
      key: 'id',
      render: (text, record) => <MemberInfo info={record} />,
    },
    {
      title: 'Action',
      dataIndex: 'member_on_team',
      key: 'member_on_team',
      render: (e, record) => (
        <Space>
          <Tooltip title="Delete">
            <DeleteFilled
              style={{ color: '#ff4d4f', fontSize: 20 }}
              onClick={() => {
                showDeleteConfirm(record);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'acive',
      key: 'acive',
      align: 'right' as 'right',
      render: (e, record) => (
        <Switch
          defaultChecked={record.active}
          onChange={(value) => onChangeSwitch(value, record)}
        />
      ),
    },
  ];
  useEffect(() => {
    loadMembers();
    return () => {
      form.resetFields();
    };
  }, [loadMembers, form]);

  return (
    <React.Fragment>
      <Form
        layout="inline"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{}}
      >
        <Form.Item
          style={{ flex: 1 }}
          name="members"
          label="Members"
          rules={[
            {
              required: true,
              message: 'You must select members to add',
            },
          ]}
        >
          <Select
            mode="multiple"
            showSearch
            showArrow
            allowClear
            placeholder="Select members"
            filterOption={(input, option) => {
              if (option) {
                return (
                  removeAccents(option.children.props.info.real_name)
                    .toLowerCase()
                    .indexOf(removeAccents(input).toLowerCase()) >= 0
                );
              }

              return true;
            }}
          >
            {listMembersInTeam
              .filter((item) => item.onTeam === false)
              .map((item: IMember) => (
                <Option value={item.userId} key={item.userId}>
                  <MemberInfo info={item} />
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={confirmLoading}>
            Add
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Table
        loading={isLoading}
        columns={columnsTableDepartmentModal}
        dataSource={listMembersInTeam.filter((item) => item.onTeam === true)}
        pagination={false}
      />
    </React.Fragment>
  );
};
