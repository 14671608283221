/* eslint-disable prettier/prettier */
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import React, { useState } from 'react';
import { IParamDate } from 'src/constants/TimesheetType';
import { s2ab, timeConvert } from 'src/helpers';
import XlsxTemplate from 'xlsx-template';
import { IDataResponRequestRanking } from 'src/services/timesheet';
interface IExportRankingProps {
  dataExport: IDataResponRequestRanking[];
  param: IParamDate;
}
export const ExportRanking: React.FC<IExportRankingProps> = ({
  dataExport,
  param,
}) => {
  const { startTime, endTime, top } = param;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const fileName = `Request Ranking ${moment
    .unix(startTime)
    .format('YYYY-MM-DD')}/${moment.unix(endTime).format('YYYY-MM-DD')}`;
  const handleExportRanking = async () => {
    setIsLoading(true);
    try {
      await axios
        .get('template-request-ranking.xlsx', { responseType: 'arraybuffer' })
        .then((data) => {
          var template = new XlsxTemplate(data.data);
          var sheetNumber = 1;
          const dataOutput = {
            people: handleDataExport(dataExport),
            date: [
              `Top : ${top === -1 ? 'All' : top} ranking (${moment
                .unix(startTime)
                .format('YYYY-MM-DD')}/${moment
                .unix(endTime)
                .format('YYYY-MM-DD')})`,
            ],
          };
          template.substitute(sheetNumber, dataOutput);
          const a = template.generate() as any;
          FileSaver.saveAs(
            new Blob([s2ab(a)], { type: fileType }),
            fileName + fileExtension,
          );
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  return (
    <Button
      onClick={handleExportRanking}
      icon={<DownloadOutlined />}
      type="primary"
      loading={isLoading}
    >
      Ranking
    </Button>
  );
};

function handleDataExport(dataExport) {
  return dataExport
    .sort((a, b) => b.overalPoint - a.overalPoint)
    .map((dataMember, i) => {
      console.log(dataMember);
      return {
        no: i + 1,
        top: i + 1,
        name: dataMember.newData.member.real_name,
        total: dataMember.newData.totalRequest,
        point: Math.ceil(dataMember.newData.overalPoint),
        off: dataMember.newData.totalDayOff,
        offM: dataMember.newData.totalMorningOff,
        offN: dataMember.newData.totalAfternoonOff,
        break: dataMember.newData.totalBreak,
        timeB: timeConvert(dataMember.newData.totalTimeBreak),
        early: dataMember.newData.totalEarly,
        timeE: timeConvert(dataMember.newData.totalTimeEarly),
        late: dataMember.newData.totalLate,
        timeL: timeConvert(dataMember.newData.totalTimeLate),
      };
    });
}
