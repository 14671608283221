import React from 'react';
import { MemberProvider } from 'src/contexts/MemberContext';
import { DisplayMemberTable } from './components/DisplayMemberTable';
import { SearchMember } from './components/SearchMember';
import MainLayout from '../../layouts/MainLayout';
import { DisplayMemberHeader } from './components/DisplayMemberHeader';
import { Divider } from 'antd';
import { PaginationMember } from 'src/pages/Members/components/PaginationMember';

export const Members = () => {
  return (
    <MainLayout>
      <MemberProvider>
        <DisplayMemberHeader />
        <Divider />
        <SearchMember />
        <DisplayMemberTable />
        <PaginationMember />
      </MemberProvider>
    </MainLayout>
  );
};
