import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MemberInfo } from 'src/components';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
import { IParamDate } from 'src/constants/TimesheetType';
import { removeAccents, timeConvert } from 'src/helpers';
import { getRankingRequestTimesheet } from 'src/services/timesheet';
import CheckStatus from './CheckStatus';
import { ExportRanking } from './ExportRanking';
import { RoleTop } from './RoleTop';
const queryString = require('query-string');
const { RangePicker } = DatePicker;
const { Option } = Select;
const TableRanking = ({ listMember }) => {
  const [paramDate, setParamDate] = useState<IParamDate>({
    startTime: moment(moment().startOf('month').format()).unix(),
    endTime: moment(moment().endOf('month').format()).unix(),
    top: 5,
  });
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState<[]>([]);
  const [dataTableTotal, setDataTableTotal] = useState<[]>([]);

  useEffect(() => {
    const stringified = queryString.stringify(paramDate);
    const { top, ...newParam } = paramDate;
    const stringifiedTable = queryString.stringify({ ...newParam, top: -1 });
    setLoading(true);
    try {
      (async () => {
        const { data } = await getRankingRequestTimesheet(stringified);
        const { data: dataTable } = await getRankingRequestTimesheet(
          stringifiedTable,
        );
        if (data.data) {
          setLoading(false);
          setDataTable(handleDataNew(data.data));
          setDataTableTotal(handleDataNew(dataTable.data));
        }
      })();
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [paramDate]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: 'Ranking',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => {
        return b.rankPoint - a.rankPoint;
      },
    },
    { title: 'Overal Point', dataIndex: 'point', key: 'point' },
    { title: 'Total Request', dataIndex: 'request', key: 'request' },
  ];
  const handleChangDate = (date) => {
    if (!date) return;
    const startDate = moment(date[0]).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date[1]).endOf('month').format('YYYY-MM-DD');
    const startDateUnix = moment(startDate).unix();
    const endDateUnix = moment(endDate).unix();
    setParamDate({
      ...paramDate,
      startTime: startDateUnix,
      endTime: endDateUnix,
    });
  };
  const handleChangeMember = async (valueMember: string) => {
    const dataFilter: any = dataTableTotal.filter(
      (dataRequest: any) => dataRequest.key === valueMember,
    );
    if (!valueMember) {
      setParamDate({ ...paramDate });
    } else {
      setDataTable(dataFilter);
    }
  };
  return (
    <>
      <SectionHeader
        sectionName="Request ranking"
        className="request-ranking-header"
      >
        <Select
          onChange={(value: number | string) => {
            if (value === 'All') {
              setParamDate({ ...paramDate, top: -1 });
              return;
            }
            setParamDate({ ...paramDate, top: Number(value) });
          }}
          defaultValue="Top 5"
        >
          <Option value="5">Top 5</Option>
          <Option value="10">Top 10</Option>
          <Option value="20">Top 20</Option>
          <Option value="All">All</Option>
        </Select>
        <Select
          showArrow
          showSearch
          placeholder="Select a member"
          optionFilterProp="children"
          onChange={handleChangeMember}
          loading={isLoading}
          allowClear
          filterOption={(input, option) => {
            if (option) {
              return (
                removeAccents(option.children.props.info.real_name)
                  .toLowerCase()
                  .indexOf(removeAccents(input).toLowerCase()) >= 0
              );
            }

            return true;
          }}
        >
          {listMember?.map((member) => (
            <Option key={member.userId} value={member.userId}>
              <MemberInfo info={member} />
            </Option>
          ))}
        </Select>

        <RangePicker
          defaultValue={[
            moment(
              moment.unix(paramDate.startTime).format('YYYY-MM-DD'),
              'YYYY/MM/DD',
            ),
            moment(
              moment.unix(paramDate.endTime).format('YYYY-MM-DD'),
              'YYYY/MM/DD',
            ),
          ]}
          picker="month"
          onChange={handleChangDate}
          size="middle"
          clearIcon={false}
        />
        <ExportRanking param={paramDate} dataExport={dataTable} />
      </SectionHeader>

      <Table
        className="table-request-ranking"
        scroll={{ x: 600 }}
        loading={isLoading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            return <ExpandedRowRender totalRequest={record.newData} />;
          },
        }}
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          showSizeChanger: dataTable.length > 5,
        }}
        dataSource={dataTable}
      />
    </>
  );
};

export default TableRanking;
function ExpandedRowRender({ totalRequest }) {
  return (
    <div className="report-ranking">
      <div>
        <div className="report-content-request">
          <CheckStatus status="LATE" />:{' '}
          <span>
            {totalRequest.totalLate}{' '}
            {totalRequest.totalTimeLate === 0
              ? ''
              : `${`( total :
            ${timeConvert(totalRequest.totalTimeLate)} hours )`}`}
          </span>
        </div>
        <div className="report-content-request">
          <CheckStatus status="AFTERNOON OFF" />:{' '}
          <span>{totalRequest.totalAfternoonOff}</span>
        </div>
      </div>
      <div>
        <div className="report-content-request">
          <CheckStatus status="BREAK" />:{' '}
          <span>
            {totalRequest.totalBreak}{' '}
            {totalRequest.totalTimeBreak === 0
              ? ''
              : `${`( total :
            ${timeConvert(totalRequest.totalTimeBreak)} hours )`}`}
          </span>
        </div>
        <div className="report-content-request">
          <CheckStatus status="MORNING OFF" />:{' '}
          <span>{totalRequest.totalMorningOff}</span>
        </div>
      </div>
      <div>
        <div className="report-content-request">
          <CheckStatus status="EARLY" />:{' '}
          <span>
            {totalRequest.totalEarly}{' '}
            {totalRequest.totalTimeEarly === 0
              ? ''
              : `${`( total :
            ${timeConvert(totalRequest.totalTimeEarly)} hours )`}`}
          </span>
        </div>
        <div className="report-content-request">
          <CheckStatus status="DAY OFF" />:{' '}
          <span>{totalRequest.totalDayOff}</span>
        </div>
      </div>
    </div>
  );
}
const handleDataNew = (data) => {
  return data.map((request, i) => {
    return {
      key: request.member.userId,
      rank: <RoleTop ranking={i + 1} />,
      name: <MemberInfo info={request.member} />,
      point: Math.ceil(request.overalPoint),
      request: request.totalRequest,
      newData: request,
      rankPoint: i + 1,
      nameSearch: request.member.real_name,
    };
  });
};
