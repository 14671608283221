import { ContactsFilled, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Descriptions,
  Tooltip,
  Form,
  Modal,
  Button,
  DatePicker,
} from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import { dateFormat } from 'src/constants';
import * as membersService from 'src/services/member';
import moment from 'moment';
import { AuthContext } from '../../contexts/Auth';
import MainLayout from '../../layouts/MainLayout';
import './style.less';
import { toast } from 'react-toastify';
import { allowDatePicker, disabledBirthday } from 'src/helpers';

const Profile = () => {
  const { user, getUserInfo } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      const { data } = await membersService.updateBirthdayMember({
        ...user,
        birthday: values.birthday.format(dateFormat.DMY),
      });
      if (data) {
        toast.success('Update birthday success!!');
        setConfirmLoading(false);
        getUserInfo();
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
    form.resetFields();
  };

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <MainLayout>
      <div className="wrap-profile">
        <div className="image">
          <Avatar
            src={user.avatar}
            size={150}
            icon={<UserOutlined />}
            style={{ margin: '24px 32px 24px 24px' }}
          />
        </div>
        <Descriptions title="Your Profile" column={2}>
          <Descriptions.Item label="Name">{user.real_name}</Descriptions.Item>
          <Descriptions.Item label="Nick name">
            {user.display_name}
          </Descriptions.Item>
          <Descriptions.Item label="Role">{user.role}</Descriptions.Item>
          <Descriptions.Item
            label="Birthday"
            style={{
              display: 'flex',
              alignItems: 'flex-center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-center',
              }}
            >
              <span>{user.birthday ? user.birthday : 'Wait update'}</span>
              <Tooltip title="Update Birthday">
                <ContactsFilled
                  style={{
                    color: '#52c41a',
                    fontSize: 25,
                    cursor: 'pointer',
                    marginLeft: 20,
                  }}
                  onClick={() => {
                    if (user.birthday) {
                      form.setFieldsValue({
                        birthday: moment(user.birthday, dateFormat.DMY),
                      });
                    }
                    setVisible(!visible);
                  }}
                />
              </Tooltip>
            </div>
            <Modal
              visible={visible}
              title={'Title'}
              onCancel={handleCancel}
              footer={null}
            >
              <Form {...layout} onFinish={onFinish} form={form}>
                <Form.Item
                  name="birthday"
                  label="Birthday"
                  rules={[
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select date!',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledBirthday}
                    placeholder={dateFormat.DMY}
                    format={dateFormat.DMY}
                    className="vbot-w-full"
                    onKeyDown={allowDatePicker}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
                  <div className="vbot-text-right">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={confirmLoading}
                    >
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          </Descriptions.Item>
          <Descriptions.Item label="Telephone">{user.phone}</Descriptions.Item>
          <Descriptions.Item label="Department">
            {user?.departmentName}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </MainLayout>
  );
};

export default Profile;
