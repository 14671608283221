import React from 'react';
import '../style.less';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { IMentionsOfChannel } from 'src/services/member';
import { IChannel } from 'src/services/team';
import { ChangeReminderModal } from './ChangeReminderModal';

interface IProps {
  dateTime: {
    time: string;
    date: string[];
  };
  listChannels: IChannel[];
  reload: () => Promise<void>;
  mentions: IMentionsOfChannel[];
}

const EmptyReminder = ({
  dateTime,
  listChannels,
  reload,
  mentions,
}: IProps) => {
  return (
    <Empty
      description={
        <span>
          Seem like you have no reminder{' '}
          <span role="img" aria-label="doubt">
            🙄
          </span>
        </span>
      }
    >
      <ChangeReminderModal
        reload={reload}
        typeModal="ADD"
        title="Create new reminder"
        reminder={dateTime}
        listChannels={listChannels}
        BtnContent={
          <Button type="primary" icon={<PlusOutlined />}>
            Create reminder now
          </Button>
        }
        mentions={mentions}
      />
    </Empty>
  );
};

export default EmptyReminder;
