//export const API_URL = 'http://5f4471283fb92f0016753745.mockapi.io/slack/';

import ReminderType from './ReminderType';
export const API_URL = 'http://172.16.7.99:5000/slack/api/';
export const SLACK_AUTHORIZE_URL = 'https://slack.com/oauth/v2/authorize';
export const GOOGLE_AUTHORIZE_URL =
  'https://accounts.google.com/o/oauth2/v2/auth';

export const teamNotify = {
  create: '👌 Create team success!',
  update: '👌 Update team success!',
  delete: '👌 Delete team success!',
};
export const reminderNotify = {
  create: '👌 Create reminder success!',
  update: '👌 Update reminder success!',
  delete: '👌 Delete reminder success!',
  copy: '👌 Copy reminder success!',
};
export const questionPackNotify = {
  create: '👌 Add question pack success!',
  update: '👌 Update question pack success!',
  delete: '👌 Delete question pack success!',
};
export const memberNotify = {
  create: '👌 Add member success!',
  update: '👌 Update member success!',
  delete: '👌 Delete member success!',
};
export const profileNotify = {
  update: '👌 Update profile success!',
};
export const packageNotify = {
  update: '👌Update email success!',
  error: 'Update error!',
};

export const timesheetNotify = {
  update: '👌 Update request success!',
  error: ' Request error!',
  minDay: 'Time update not suitable',
  create: '👌 Create request success!',
};
export const maxWidthInput = 300;
export const ADMIN_PERMISSION = 'admin';
export const listDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const daysOfWeek = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export const defaultPage = 1;
export const defaultPageSize = 5;
export const PAGINATION = {
  page: defaultPage,
  pageSize: defaultPageSize,
};

export const reminderTypes = [
  { value: ReminderType.ONETIME, label: 'One Time' },
  { value: ReminderType.DAY, label: 'Daily' },
  { value: ReminderType.WORK_DAYS, label: 'Weekdays' },
  { value: ReminderType.WEEK, label: 'Weekly' },
  { value: ReminderType.MONTH, label: 'Monthly' },
  { value: ReminderType.YEAR, label: 'Yearly' },
];

export const dateFormat = {
  DMY: 'DD-MM-YYYY',
  YMD: 'YYYY-MM-DD',
  DM: 'DD-MM',
  DD: 'DD',
  DDD: 'ddd',
  YM: 'YYYY-MM',
};

export const minutesInOneDay = 1440;
export const minutesInHour = 60;

export const SUNDAY = 'Sun';
export const SATURDAY = 'Sat';
export const rangeDate = [
  {
    value: 0,
    label: 'Today',
  },
  {
    value: 1,
    label: 'Yesterday',
  },
  {
    value: 'thisWeek',
    label: 'This week',
  },
  {
    value: 'lastWeek',
    label: 'Last week',
  },
  {
    value: 'thisMonth',
    label: 'This month',
  },
  {
    value: 'lastMonth',
    label: 'Last Month',
  },
  {
    value: 90,
    label: '3 months',
  },
];
export const columnsTable = [
  {
    title: 'Member',
    dataIndex: 'member',
  },
  {
    title: 'Type',
    dataIndex: 'status',
  },
  {
    title: 'Period',
    dataIndex: 'period',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
  },
  {
    title: 'Apply on',
    dataIndex: 'request',
  },
  {
    title: 'Reviewed by',
    dataIndex: 'reviewed',
  },
  {
    title: 'Status',
    dataIndex: 'action',
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
  },
];

const ggParam = {
  scope:
    'profile email https://www.googleapis.com/auth/admin.directory.user.readonly',
  client_id: process.env.REACT_APP_GG_CLIENT_ID!,
  response_type: 'code',
  access_type: 'offline',
  include_granted_scopes: 'true',
  redirect_uri: process.env.REACT_APP_ADD_TO_GOOGLE_CALLBACK!,
};
export const ADD_GOOGLE_URL = `${GOOGLE_AUTHORIZE_URL}?${new URLSearchParams(
  ggParam,
).toString()}`;
