import React, { useCallback, useState } from 'react';
import { Modal, Button } from 'antd';
import * as notifyService from '../../../services/reminder';
import { IReportReminder } from '../../../services/reminder';
import { ReportReminder } from '../../../components/TeamInfo';

type ScheduleModalProps = {
  title: string;
  BtnContent: React.ReactNode;
  teamId: string;
  reload: () => Promise<void>;
};
export const ScheduleModal: React.FC<ScheduleModalProps> = ({
  title,
  BtnContent,
  teamId,
  reload,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [loadingReminder, setLoadingReminder] = useState<boolean>(false);
  const [detailReminder, setDetailReminder] = useState<IReportReminder>({
    message:
      "Hi, it's time for today's Daily Report. Feel free to type report now?",
    days: ['Tue'],
    time: '08:15',
  });

  const showModal = async () => {
    setLoadingReminder(true);
    await loadReminder();
    setLoadingReminder(false);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const loadReminder = useCallback(async () => {
    let { data } = await notifyService.getReminderTeam(teamId);
    if (data?.data) {
      setDetailReminder(data.data);
    } else {
      setDetailReminder({ ...detailReminder });
    }
  }, [teamId, detailReminder]);

  return (
    <React.Fragment>
      <Button
        type="link"
        onClick={showModal}
        loading={loadingReminder}
        icon={BtnContent}
      />
      {visible && (
        <Modal
          visible={visible}
          // title={
          //   <div>
          //     <span style={{ paddingRight: '1rem' }}> {title}</span>
          //     <EnableReminder
          //       detailReminder={detailReminder}
          //       reload={reload}
          //       teamId={teamId}
          //     />
          //   </div>
          // }
          onCancel={handleCancel}
          footer={null}
        >
          <ReportReminder
            reminder={detailReminder}
            teamId={teamId}
            handleClose={handleCancel}
            onDetailTeam={true}
            reload={reload}
          />
        </Modal>
      )}
    </React.Fragment>
  );
};
