/* eslint-disable prettier/prettier */
import { EditTwoTone } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { dateFormat, timesheetNotify } from 'src/constants/index';
import TimesheetStatusType from 'src/constants/TimesheetType';
import * as timesheetServices from 'src/services/timesheet';
import { IDataMember, IQueryParamTimesheet } from 'src/services/timesheet';

interface IUpdateRequestType {
  dataRequest: IDataMember;
  param: IQueryParamTimesheet;
  setParam: React.Dispatch<React.SetStateAction<IQueryParamTimesheet>>;
}
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};
const { RangePicker } = DatePicker;
const { RangePicker: RangePickTime } = TimePicker;
export const UpdateRequest: React.FC<IUpdateRequestType> = ({
  dataRequest,
  param,
  setParam,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [statusUpdate, setStatusUpdate] = useState<string>(
    dataRequest.request.type,
  );
  // console.log(dataRequest)
  const [form] = Form.useForm();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const startHour = moment
    .unix(dataRequest.request?.isOt?.start)
    .format('HH:mm');
  const endHour = moment.unix(dataRequest.request?.isOt?.end).format('HH:mm');

  const dateNow = new Intl.DateTimeFormat('fr-ca').format(new Date());
  const dateStart = new Date(
    dataRequest.request.isOff !== undefined &&
    dataRequest.request.isOff.start !== undefined
      ? dataRequest.request.isOff.start * 1000
      : dateNow,
  );
  const endDate = moment(
    new Date(
      dataRequest.request.isOff !== undefined &&
      dataRequest.request.isOff.end !== undefined
        ? dataRequest.request.isOff.end * 1000
        : dateNow,
    ),
  ).format(dateFormat.YMD);
  const startDate = moment(dateStart).format(dateFormat.YMD);
  const handleChangeTimeRequest = async ({
    type,
    reason,
    timestamp,
    period,
    status,
    rangePickerTime,
    rangePickHour,
  }) => {
    const startHour =
      rangePickHour === undefined ? null : moment(rangePickHour[0]._d);
    const endHour =
      rangePickHour === undefined ? null : moment(rangePickHour[1]._d);
    const total = moment
      .utc(
        moment(endHour, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(startHour, 'DD/MM/YYYY HH:mm:ss'),
        ),
      )
      .format('HH:mm:ss');
    const duration = moment.duration(total).asMinutes();
    const unixStartHour =
      rangePickHour === undefined ? null : moment(startHour).unix() * 1000;
    const unixEndHour =
      rangePickHour === undefined ? null : moment(endHour).unix() * 1000;
    const unixStartDate =
      rangePickerTime === undefined
        ? null
        : moment(rangePickerTime[0]._d).startOf('day').unix() * 1000;
    const unixEndDate =
      rangePickerTime === undefined
        ? null
        : moment(rangePickerTime[1]._d).endOf('day').unix() * 1000;
    const getPeriod =
      moment(new Date(period?._d)).hour() * 60 +
      moment(new Date(period?._d)).minutes();
    const totalDate = unixStartDate
      ? moment(
          moment(rangePickerTime[1]).format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).diff(
          moment(moment(rangePickerTime[0]).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
          'days',
        ) + 1
      : 0;
    let secondsPeriod = totalDate * 24 * 60 * 60;
    const periodDynamic = period === undefined ? secondsPeriod : getPeriod;
    if (
      periodDynamic === 0 &&
      statusUpdate !== TimesheetStatusType.MORNING_OFF &&
      statusUpdate !== TimesheetStatusType.AFTERNOON_OFF &&
      statusUpdate !== TimesheetStatusType.WORK_FROM_HOME_AFTERNOON &&
      statusUpdate !== TimesheetStatusType.WORK_FROM_HOME_MORNING &&
      duration === 0
    ) {
      toast.error(timesheetNotify.minDay);
      return;
    }
    const dataUpdateRequest =
      period === undefined
        ? statusUpdate === TimesheetStatusType.OT
          ? {
              status: type,
              timestamp: `${moment(timestamp).unix()}`,
              request: {
                isOt: {
                  start: unixStartHour && unixStartHour / 1000,
                  end: unixEndHour && unixEndHour / 1000,
                },
                requestAt: `${moment(timestamp).unix()}`,
                reason,
                period: Math.round(duration),
                type: status,
              },
            }
          : {
              status: type,
              timestamp: `${moment(timestamp).unix()}`,
              request: {
                isOff: {
                  start: unixStartDate && unixStartDate / 1000,
                  end: unixEndDate && unixEndDate / 1000,
                },
                requestAt: `${moment(timestamp).unix()}`,
                reason,
                period: periodDynamic,
                type: status,
              },
            }
        : {
            status: type,
            timestamp: `${moment(timestamp).unix()}`,
            request: {
              requestAt: `${moment(timestamp).unix()}`,
              reason,
              period: periodDynamic,
              type: status,
            },
          };
    setLoading(true);
    try {
      const { data: dataUpdate } =
        await timesheetServices.updateRequestTimesheet({
          id: dataRequest._id,
          data: dataUpdateRequest,
        });
      if (dataUpdate.data) {
        toast.success(timesheetNotify.update);
        setLoading(false);
        setOpen(false);
        setTimeout(() => {
          setParam({ ...param });
        }, 400);
      }
    } catch (error) {
      toast.error(timesheetNotify.error);
    }
  };

  const handleStatusUpdate = (status: string) => {
    switch (status) {
      case TimesheetStatusType.DAY_OFF:
        return (
          <div className="form-control-date">
            <Form.Item
              name="rangePickerTime"
              label="Time"
              rules={[{ required: true }]}
            >
              <RangePicker />
            </Form.Item>
          </div>
        );
      case TimesheetStatusType.MORNING_OFF:
        break;
      case TimesheetStatusType.AFTERNOON_OFF:
        break;
      case TimesheetStatusType.WORK_FROM_HOME:
        return (
          <div className="form-control-date">
            <Form.Item
              name="rangePickerTime"
              label="Time"
              rules={[{ required: true }]}
            >
              <RangePicker />
            </Form.Item>
          </div>
        );
      case TimesheetStatusType.OT:
        return (
          <div className="form-control-date">
            <Form.Item
              name="rangePickHour"
              label="Time"
              rules={[{ required: true }]}
            >
              <RangePickTime format="HH:mm" />
            </Form.Item>
          </div>
        );
      case TimesheetStatusType.WORK_FROM_HOME_AFTERNOON:
        break;
      case TimesheetStatusType.WORK_FROM_HOME_MORNING:
        break;
      default:
        return (
          <div className="period-time-picker">
            <Form.Item rules={[{ required: true }]} name="period" label="Total">
              <TimePicker />
            </Form.Item>
          </div>
        );
    }
  };
  const initialValues = {
    status: dataRequest.request.type,
    reason: dataRequest.request.reason,
    timestamp: moment(Number(dataRequest.request.requestAt) * 1000),
    period: moment(convertTime(dataRequest.request.period), 'HH:mm:ss'),
    type: dataRequest.status,
    rangePickHour: [
      dataRequest.request?.isOt?.start && moment(startHour, 'HH:mm'),
      dataRequest.request?.isOt?.end && moment(endHour, 'HH:mm'),
    ],
    rangePickerTime: [
      dataRequest.request?.isOff?.start === null
        ? moment(dateNow, dateFormat.YMD)
        : moment(startDate, dateFormat.YMD),
      dataRequest.request?.isOff?.end === null
        ? moment(dateNow, dateFormat.YMD)
        : moment(endDate, dateFormat.YMD),
    ],
  };

  return (
    <>
      <span onClick={() => setOpen(true)}>
        <EditTwoTone
          style={{ cursor: 'pointer', marginLeft: '6px', fontSize: '16px' }}
        />
      </span>
      <Modal
        title={`Update request ${dataRequest.requestedByMember.real_name}`}
        visible={isOpen}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form
          initialValues={initialValues}
          {...layout}
          form={form}
          name="control-hooks"
          onValuesChange={() => setDisabled(false)}
          onFinish={handleChangeTimeRequest}
        >
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select onChange={(value: string) => setStatusUpdate(value)}>
              <Select.Option value={TimesheetStatusType.EARLY}>
                {TimesheetStatusType.EARLY}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.LATE}>
                {TimesheetStatusType.LATE}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.BREAK}>
                {TimesheetStatusType.BREAK}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.AFTERNOON_OFF}>
                {TimesheetStatusType.AFTERNOON_OFF}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.MORNING_OFF}>
                {TimesheetStatusType.MORNING_OFF}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.DAY_OFF}>
                DAY OFF
              </Select.Option>
              <Select.Option
                value={TimesheetStatusType.WORK_FROM_HOME_AFTERNOON}
              >
                {TimesheetStatusType.WORK_FROM_HOME_AFTERNOON}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.WORK_FROM_HOME_MORNING}>
                {TimesheetStatusType.WORK_FROM_HOME_MORNING}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.WORK_FROM_HOME}>
                {TimesheetStatusType.WORK_FROM_HOME}
              </Select.Option>
              <Select.Option value={TimesheetStatusType.OT}>
                {TimesheetStatusType.OT}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="reason" label="Reason">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="timestamp"
            label="Apply On"
          >
            <DatePicker />
          </Form.Item>
          {handleStatusUpdate(statusUpdate)}
          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select>
              <Select.Option value={TimesheetStatusType.APPROVED}>
                Approved
              </Select.Option>
              <Select.Option value={TimesheetStatusType.REJECTED}>
                Rejected
              </Select.Option>
            </Select>
          </Form.Item>
          <div className="button-update">
            <Form.Item>
              <Button type="primary" ghost onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                disabled={isDisabled}
                loading={isLoading}
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

function convertTime(time): string {
  return moment
    .utc()
    .startOf('day')
    .add({ minutes: Number(time) })
    .format('H:mm');
}
