/* eslint-disable react-hooks/exhaustive-deps */
import {
  ClockCircleOutlined,
  HomeOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  BankOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { UserRole } from 'src/constants/Roles';
import Logo from '../assets/images/195.png';
import { AuthContext } from '../contexts/Auth';
import './style.less';
const { Sider } = Layout;
const { SubMenu } = Menu;

interface ISubMenu {
  name: string;
  link: string;
}
interface IMenuSiderBar {
  link?: string;
  name: string;
  icon: JSX.Element;
  subMenu?: ISubMenu[];
}

const menuBar = {
  user: [
    { link: '/', name: 'Home', icon: <HomeOutlined /> },
    // {
    //   link: '/reports',
    //   name: 'Report',
    //   icon: <BarChartOutlined />,
    // },
    // {
    //   link: '/timesheet',
    //   name: 'Timesheet',
    //   icon: <TableOutlined />,
    // },
    {
      link: '/reminders',
      name: 'Reminders',
      icon: <ClockCircleOutlined />,
    },
  ],
  departmentAdmin: [
    { link: '/', name: 'Home', icon: <HomeOutlined /> },
    // {
    //   link: '/teams',
    //   name: 'Teams',
    //   icon: <AppstoreOutlined />,
    // },
    // {
    //   link: '/question-packs',
    //   name: 'Question Packs',
    //   icon: <FileOutlined />,
    // },
    {
      link: '/members',
      name: 'Members',
      icon: <TeamOutlined />,
    },
    {
      link: '/reminders',
      name: 'Reminders',
      icon: <ClockCircleOutlined />,
    },
    // {
    //   link: '/reports',
    //   name: 'Report',
    //   icon: <BarChartOutlined />,
    // },
    // {
    //   link: '/timesheet',
    //   name: 'Timesheet',
    //   icon: <TableOutlined />,
    // },
    // {
    //   name: 'Payment',
    //   icon: <PayCircleOutlined style={{ fontSize: '18px' }} />,
    //   subMenu: [
    //     {
    //       name: 'Package Info',
    //       link: '/payments',
    //     },
    //     {
    //       name: 'History',
    //       link: '/history',
    //     },
    //   ],
    // },
    {
      link: '/departments',
      name: 'Departments',
      icon: <BankOutlined />,
    },
  ],
  workspaceAdmin: [
    { link: '/', name: 'Home', icon: <HomeOutlined /> },
    // {
    //   link: '/teams',
    //   name: 'Teams',
    //   icon: <AppstoreOutlined />,
    // },
    // {
    //   link: '/question-packs',
    //   name: 'Question Packs',
    //   icon: <FileOutlined />,
    // },
    {
      link: '/members',
      name: 'Members',
      icon: <TeamOutlined />,
    },
    {
      link: '/reminders',
      name: 'Reminders',
      icon: <ClockCircleOutlined />,
    },
    // {
    //   link: '/reports',
    //   name: 'Report',
    //   icon: <BarChartOutlined />,
    // },
    // {
    //   link: '/timesheet',
    //   name: 'Timesheet',
    //   icon: <TableOutlined />,
    // },
    // {
    //   name: 'Payment',
    //   icon: <PayCircleOutlined style={{ fontSize: '18px' }} />,
    //   subMenu: [
    //     {
    //       name: 'Package Info',
    //       link: '/payments',
    //     },
    //     {
    //       name: 'History',
    //       link: '/history',
    //     },
    //   ],
    // },
    {
      link: '/workspace',
      name: 'Workspace',
      icon: <SettingOutlined />,
    },
    {
      link: '/departments',
      name: 'Departments',
      icon: <BankOutlined />,
    },
  ],
};

export const SiderBar: React.FC = () => {
  const [menu, setMenu] = useState<IMenuSiderBar[]>([
    {
      link: '/',
      name: 'Home',
      icon: <PieChartOutlined />,
    },
  ]);
  const [collapsed, setCollapse] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [collapsedClass, setCollapsedClass] = useState('');

  const [openKeys, setOpenKeys] = useState<any>([]);
  const onCollapse = (collapsed: boolean): void => {
    setCollapse(collapsed);
  };
  const location = useLocation();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (user.role === UserRole.WORKSPACE_ADMIN) {
        setMenu(menuBar.workspaceAdmin);
      } else if (user.role === UserRole.DEPARTMENT_ADMIN) {
        setMenu(menuBar.departmentAdmin);
      } else {
        setMenu(menuBar.user);
      }
    }
  }, [user]);
  useEffect(() => {
    if (!menu.find((item) => item.link === location.pathname)) {
      if (
        menu.find(
          (item) =>
            item.subMenu &&
            item.subMenu.find((item) => item.link === location.pathname),
        )
      ) {
        const currentSubItem = menu.find(
          (item) =>
            item.subMenu &&
            item.subMenu.find((item) => item.link === location.pathname),
        );
        setOpenKeys([currentSubItem?.name]);
      }
      return;
    }
  }, [menu, location?.pathname]);
  const onOpenChange = (items) => {
    setOpenKeys(items);
  };
  return (
    <Sider
      className={`sider-container ${collapsedClass}`}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      width={165}
      collapsedWidth={collapsedWidth}
      breakpoint={'xs'}
      onBreakpoint={(broken) => {
        if (broken) {
          setCollapsedClass('is-mobile');
          setCollapsedWidth(0);
        } else {
          setCollapsedClass('');
          setCollapsedWidth(80);
        }
      }}
    >
      <div
        className="logo"
        onClick={() => {
          onCollapse(!collapsed);
        }}
      >
        <img src={Logo} alt="vmobot-logo" />
      </div>
      <Menu
        openKeys={openKeys}
        theme="dark"
        selectedKeys={[location?.pathname]}
        mode="inline"
        onOpenChange={onOpenChange}
      >
        {menu.map((item, index) =>
          item.subMenu ? (
            <SubMenu key={item.name} icon={item.icon} title={item.name}>
              {item.subMenu.map((subMenu) => (
                <Menu.Item key={subMenu.link}>
                  <Link to={subMenu.link}>{subMenu.name} </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={item.link}
              icon={item.icon}
              id={`menu-item-${index}`}
            >
              <NavLink to={item.link ? item.link : '/'}>{item.name} </NavLink>
            </Menu.Item>
          ),
        )}
        <Menu.Item
          key="/contact"
          icon={<ContactsOutlined />}
          id={'menu-item-contact'}
        >
          <NavLink to={'/contact'}>Contact</NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
