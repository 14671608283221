import React from 'react';
import { DonutChart } from 'bizcharts';
//import Modal from 'antd/lib/modal/Modal';
type CountReportProps = {
  pending: number;
  reported: number;
};
export const CountReport: React.FC<CountReportProps> = (
  props: CountReportProps,
) => {
  const { pending, reported } = props;
  //const [visible, setVisible] = useState<boolean>(false);
  // const [title, setTitle] = useState<string>('list members');
  const data = [
    {
      type: 'Reported',
      value: reported,
    },
    {
      type: 'Pending',
      value: pending,
    },
  ];
  // const handleCancel = () => {
  //   setVisible(false);
  // };
  // const onRingClick = (event) => {
  //   console.log(event.data);
  //   setVisible(true);
  //   setTitle(event.data.type);
  // };
  return (
    <div>
      <DonutChart
        data={data || []}
        height={200}
        // title={{
        //   visible: true,
        //   text: 'title',
        //   alignTo: 'middle',
        // }}
        forceFit
        // description={{
        //   visible: true,
        //   text: 'description',
        // }}
        radius={1}
        padding="auto"
        angleField="value"
        legend={{ visible: false, position: 'bottom-center' }}
        colorField="type"
        color={['#ff7200', '#dddddd']}
        statistic={{
          visible: true,
          //  totalLabel: 'Total',
          content: {
            name: 'report',
            value:
              pending + reported > 0
                ? Math.round((reported * 100) / (pending + reported)) + '%'
                : '0',
          },
        }}
        // events={{
        //   onRingClick: onRingClick,
        // }}
      />
      {/* <Modal
        visible={visible}
        title={title || 'Title'}
        onCancel={handleCancel}
        footer={null}
      >
        {visible && <div>member</div>}
      </Modal> */}
    </div>
  );
};
