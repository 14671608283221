import React from 'react';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
import { SearchMemberFilter } from './SearchMemberFilter';
import { SearchMemberInput } from './SearchMemberInput';
import './style.less';

export const SearchMember = () => {
  return (
    <SectionHeader sectionName="Filter list of members">
      <SearchMemberInput />
      <SearchMemberFilter />
    </SectionHeader>
  );
};
