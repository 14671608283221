import React from 'react';
import { Result, Button } from 'antd';
import './style.less';
import { Link, useParams, useRouteMatch, Switch } from 'react-router-dom';
import { PublicRoute } from '../../components';

const Errors: React.FC = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <PublicRoute exact restricted={false} path={path}>
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            }
          />
        </PublicRoute>
        <PublicRoute restricted={false} path={`${path}/:errCode`}>
          <Topic />
        </PublicRoute>
      </Switch>
    </div>
  );
};
const Topic: React.FC = () => {
  // The <Route> that rendered this component has a
  // path of `/topics/:topicId`. The `:topicId` portion
  // of the URL indicates a placeholder that we can
  // get from `useParams()`.
  interface IParamTypes {
    errCode: string;
  }
  let { errCode } = useParams<IParamTypes>();
  let contentErr = () => {
    switch (errCode) {
      case '401':
        return (
          <Result
            status="403"
            title="401"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Link to="/login">
                <Button type="primary">Login</Button>
              </Link>
            }
          />
        );
      case '403':
        return (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            }
          />
        );
      default:
        return (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            }
          />
        );
    }
  };
  return <div>{contentErr()}</div>;
};

export default Errors;
