/* eslint-disable prettier/prettier */
import { Descriptions, Tag, Button } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import React from 'react';
import moment from 'moment';
import { dateFormat, listDays, reminderTypes } from 'src/constants';
import ReminderType from 'src/constants/ReminderType';
import { INewReminder } from 'src/services/reminder';

type ViewReminderProps = { detailReminder: INewReminder };
export const ViewReminder = ({ detailReminder }: ViewReminderProps) => {
  const statusColor = {
    Pending: '#87d068',
    Sent: '#ff7200',
    Disable: '#c4c3c3',
    Enable: '#13c2c2',
  };
  const type = reminderTypes.find(
    (item) => detailReminder?.type === item.value,
  );
  switch (detailReminder && detailReminder.type) {
    case ReminderType.ONETIME:
      detailReminder.date = moment(detailReminder.startDate[0] * 1000).format(
        dateFormat.DMY,
      );
      break;
    case ReminderType.MONTH:
      detailReminder.date = moment(detailReminder.startDate[0] * 1000).format(
        dateFormat.DD,
      );
      break;
    case ReminderType.YEAR:
      detailReminder.date = moment(detailReminder.startDate[0] * 1000).format(
        dateFormat.DM,
      );
      break;
    default:
      break;
  }
  return (
    <div>
      <Descriptions size="small" column={5}>
        <Descriptions.Item label="Created by" span={5}>
          {detailReminder.userName}
        </Descriptions.Item>
        <Descriptions.Item label="Type" span={2}>
          {type?.label}
        </Descriptions.Item>
        <Descriptions.Item label="Channel / Space" span={3}>
          {detailReminder?.notifyAtChannel &&
            detailReminder.notifyAtChannel.name}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={5}>
          <Tag color={statusColor[detailReminder?.status]}>
            {detailReminder?.status}
          </Tag>
        </Descriptions.Item>
        {detailReminder?.type === ReminderType.WEEK ? (
          <>
            <Descriptions.Item label="Time" span={5}>
              {detailReminder.time}
            </Descriptions.Item>
            <Descriptions.Item label="Days" span={5}>
              {listDays.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={detailReminder.date.indexOf(tag) > -1}
                >
                  <Button
                    shape="circle"
                    type={
                      detailReminder.date.indexOf(tag) > -1
                        ? 'primary'
                        : 'default'
                    }
                  >
                    {tag}
                  </Button>
                </CheckableTag>
              ))}
            </Descriptions.Item>
          </>
        ) : detailReminder.type === ReminderType.WORK_DAYS ? (
          <>
            <Descriptions.Item label="Time" span={5}>
              {detailReminder.time}
            </Descriptions.Item>
            <Descriptions.Item label="Days" span={5}>
              {listDays.slice(0, listDays.length - 2).map((tag) => (
                <CheckableTag key={tag} checked>
                  <Button shape="circle" type={'primary'}>
                    {tag}
                  </Button>
                </CheckableTag>
              ))}
            </Descriptions.Item>
          </>
        ) : (
          <>
            <Descriptions.Item
              label="Time"
              span={detailReminder?.type !== ReminderType.DAY ? 2 : 5}
            >
              {detailReminder?.time}
            </Descriptions.Item>
            {detailReminder?.type !== ReminderType.DAY && (
              <Descriptions.Item label="Day" span={3}>
                {detailReminder?.date}
              </Descriptions.Item>
            )}
          </>
        )}
        {detailReminder?.type !== ReminderType.ONETIME && (
          <>
            <Descriptions.Item label="Repeat every" span={2}>
              {`${detailReminder?.recurrence} ${
                detailReminder?.type === ReminderType.WORK_DAYS
                  ? detailReminder?.type
                      .slice(0, detailReminder?.type.length - 1)
                      .split('_')
                      .join(' ')
                  : detailReminder?.type
              }(s)`}
            </Descriptions.Item>
            <Descriptions.Item label="End date" span={3}>
              {detailReminder?.endDate === 0
                ? 'No end date'
                : moment(detailReminder?.endDate * 1000).format(dateFormat.DMY)}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label="Message" span={5}>
          {detailReminder?.message}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
