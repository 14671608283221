import React from 'react';
import { useLocation } from 'react-router-dom';
import notFoundImg from '../../assets/images/not_found_page.svg';
import './style.less';

const NotFound = () => {
  let location = useLocation();
  return (
    <div className="not-found">
      <p>
        Oops! Not found <code>{location.pathname}</code>{' '}
      </p>
      <img src={notFoundImg} alt="not found" height="400" />
    </div>
  );
};

export default NotFound;
