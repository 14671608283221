import { Avatar } from 'antd';
import React from 'react';
import { UserOutlined, NotificationOutlined } from '@ant-design/icons';
import './style.less';

type TMemberInfoProps = {
  info: any;
  groupMember?: boolean;
};
export const MemberInfo: React.FC<TMemberInfoProps> = (
  props: TMemberInfoProps,
) => {
  const { info, groupMember } = props;
  return (
    <div className="warp-info-member">
      <div className="avt-member">
        <Avatar
          src={info.avatar}
          shape="square"
          size="large"
          icon={groupMember ? <NotificationOutlined /> : <UserOutlined />}
        />
      </div>
      <div className="content">
        <b>{info.real_name}</b>
        <p>{info.display_name}</p>
      </div>
    </div>
  );
};
