import { Button, Divider, Form } from 'antd';
import React from 'react';
import Logo from '../../assets/images/195.png';
import SlackLogo from '../../assets/images/slack-logo.png';
import { SLACK_AUTHORIZE_URL } from '../../constants';
import LoginWithGoogle from '../Auth/LoginGoogle';
import './style.less';

export const Login: React.FC = () => {
  const slackParam = {
    user_scope: 'users:read',
    client_id: process.env.REACT_APP_CLIENT_ID!,
    redirect_uri: process.env.REACT_APP_SLACK_AUTH_CALLBACK!,
  };
  let SLACK_URL = `${SLACK_AUTHORIZE_URL}?${new URLSearchParams(
    slackParam,
  ).toString()}`;
  const addSlackParam = {
    scope:
      'channels:history,channels:manage,channels:read,chat:write,commands,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,team:read,users:read,users:read.email',
    user_scope:
      'channels:history,channels:read,channels:write,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,team:read,users:read,users:read.email',
    client_id: process.env.REACT_APP_CLIENT_ID!,
    redirect_uri: process.env.REACT_APP_ADD_TO_SLACK_CALLBACK!,
  };
  let ADD_SLACK_URL = `${SLACK_AUTHORIZE_URL}?${new URLSearchParams(
    addSlackParam,
  ).toString()}`;

  return (
    <div className="wrap">
      <div className="login-content">
        <div className="header">
          <img src={Logo} alt="chat-bot" height="150" />
          <h2>Welcome to VMO BOT</h2>
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{}}
          // onFinish={onFinish}
        >
          <Form.Item className="center" style={{ marginBottom: 0 }}>
            <Button
              type="default"
              shape="round"
              size="large"
              className="login-form-button"
              style={{ display: 'none' }}
            >
              <a href={SLACK_URL}>
                <img src={SlackLogo} alt="slack-logo" height="22" />{' '}
                <span>
                  Log in with <b>Slack</b>
                </span>
              </a>
            </Button>
            <LoginWithGoogle />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0, textAlign: 'center', display: 'none' }}
          >
            <Divider>Or</Divider>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0, display: 'none' }}>
            <Button
              type="default"
              shape="round"
              size="large"
              className="login-form-button"
            >
              <a href={ADD_SLACK_URL}>
                <img src={SlackLogo} alt="slack-logo" height="22" />{' '}
                <span>
                  <b>Add to Slack</b>
                </span>
              </a>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
