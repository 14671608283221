import React, { useState, useContext } from 'react';
import { Modal, Form, Button, Input, DatePicker } from 'antd';
import { AuthContext } from 'src/contexts/Auth';
import * as notifyService from 'src/services/member';
import { dateFormat, profileNotify } from 'src/constants';
import { toast } from 'react-toastify';
import { PatternFormat } from 'react-number-format';
import { allowDatePicker, disabledBirthday } from 'src/helpers';

const Popup = ({ visible, onCancel }) => {
  const { user, getUserInfo } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setConfirmLoading(true);
      let res;
      if (!user.phone || !user.birthday) {
        res = await notifyService.updateProfile({
          ...values,
          departmentId: user?.departmentId,
          phone: values.phone ? values.phone : user.phone,
          birthday: values.birthday
            ? values.birthday.format(dateFormat.DMY)
            : user.birthday,
        });
        if (res.data) {
          toast.success(profileNotify.update);
          setConfirmLoading(false);
          getUserInfo();
          onCancel();

          form.resetFields();
        }
      }
    } catch (error) {
      form.resetFields();
    }
  };
  const onFinishFailed = (errorInfo) => {
    setConfirmLoading(false);
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title="Update profile"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      confirmLoading={confirmLoading}
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
      >
        {!user.phone && (
          <Form.Item style={{ marginTop: '10px' }} label="Phone" name="phone">
            <PatternFormat
              customInput={Input}
              placeholder="Enter phone number"
              format="############"
            />
          </Form.Item>
        )}
        {!user.birthday && (
          <Form.Item label="Birthday" name="birthday">
            <DatePicker
              disabledDate={disabledBirthday}
              placeholder={dateFormat.DMY}
              format={dateFormat.DMY}
              className="vbot-w-full"
              onKeyDown={allowDatePicker}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
          <div className="vbot-text-right">
            <Button type="primary" htmlType="submit" loading={confirmLoading}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Popup;
