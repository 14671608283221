import { Button, Tag } from 'antd';
import React, { useState } from 'react';
import PageHeader from 'src/components/PageHeader/PageHeader';
import { useMemberContext } from 'src/contexts/MemberContext';
import { PlusOutlined } from '@ant-design/icons';
import ModalMember from '../Modal';
import * as memberService from 'src/services/member';
import ModalImport from '../ModalImport';

export const DisplayMemberHeader = () => {
  const { activeMembers, modalImportInfo, setModalImportInfo } =
    useMemberContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    memberService.syncMembersAdmin();
  };

  return (
    <PageHeader headerName="Members">
      {isModalOpen && (
        <ModalMember
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={handleOk}
        />
      )}
      <Tag className="layout-header-member__member-active">
        Member Active : {activeMembers.length}
      </Tag>
      <Button
        style={{ marginLeft: 8 }}
        onClick={() => setIsModalOpen(true)}
        type="primary"
        icon={<PlusOutlined />}
      >
        Sync/Update new member
      </Button>
      <Button
        style={{ marginLeft: 8 }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={() =>
          setModalImportInfo({
            ...modalImportInfo,
            showModal: true,
            fileSelected: null,
            isUploading: false,
            isUploadSuccess: false,
            listDuplicated: [],
            departmentId: '',
          })
        }
      >
        Import
      </Button>
      {modalImportInfo.showModal && <ModalImport />}
    </PageHeader>
  );
};
