import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UserRole } from 'src/constants/Roles';
import { AuthContext } from 'src/contexts/Auth';
import { useMemberContext } from 'src/contexts/MemberContext';
import { IDepartment } from 'src/services/departments/department.interface';
import { getDepartments } from 'src/services/departments/department.services';
import { IMember } from 'src/services/member';
import { dateFormat, memberNotify } from 'src/constants';
import * as notifyService from 'src/services/member';
import * as membersService from 'src/services/member';
import { PatternFormat } from 'react-number-format';
import { allowDatePicker, disabledBirthday } from 'src/helpers';

const { Option } = Select;

type ModalMemberProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  initialValues?: IMember;
};
const ModalMember: React.FC<ModalMemberProps> = ({
  visible,
  onOk,
  onCancel,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newMember, setNewMember] = useState<IMember[]>([]);
  const [chooseMember, setChooseMember] = useState({
    key: '',
    label: '',
    value: '',
  });
  const { user } = useContext(AuthContext);
  const { fetchFilterMembers, setSortedInfo } = useMemberContext();
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(false);
  const [departmentId, setDepartmentId] = useState<string | null>(null);
  const [syncLoading, setSyncLoading] = useState(false);

  const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
  };
  const getnewMember = useCallback(async (departmentId: string) => {
    try {
      const listNewMember = await notifyService.getListNewMember(departmentId);
      if (listNewMember.data.data) {
        setNewMember(listNewMember.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleMemberChange = (value) => {
    if (value) {
      setChooseMember(value);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { key, label, value } = chooseMember;

  const fetchDepartments = async () => {
    try {
      setIsLoadingDepartments(true);
      const res = await getDepartments();
      if (res instanceof Error) {
        return console.log('error', res);
      }
      setDepartments(res.data.data.data);
      setDepartmentId(initialValues?.departmentId as string);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDepartments(false);
    }
  };

  const handleSyncMembers = useCallback(
    async (departmentId: string) => {
      try {
        setSyncLoading(true);
        const selectedDepartmentId = departmentId as string;
        const resSyncMembers = await membersService.syncMembersAdmin(
          selectedDepartmentId,
        );

        if (resSyncMembers instanceof Error) {
          let error = resSyncMembers as unknown as AxiosError;
          return toast.error(
            error.response?.data?.errors?.message || 'Can not sync members',
          );
        }
        await getnewMember(departmentId);
      } catch (error) {
        toast.error('Something went wrong!');
        console.log('error', error);
      } finally {
        setSyncLoading(false);
      }
    },
    [getnewMember],
  );
  useEffect(() => {
    fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (visible && user.role === UserRole.DEPARTMENT_ADMIN) {
      handleSyncMembers(user.departmentId as string);
    }
  }, [handleSyncMembers, user.departmentId, user.role, visible]);

  useEffect(() => {
    if (departmentId && user.role === UserRole.WORKSPACE_ADMIN) {
      handleSyncMembers(departmentId);
    }
  }, [departmentId, handleSyncMembers, user.role]);

  const { getUserInfo } = useContext(AuthContext);
  const onFinish = async (values: any) => {
    try {
      setConfirmLoading(true);
      let res;
      if (!initialValues) {
        res = await notifyService.postMember({
          _id: value,
          email: values.email,
          phone: values.phone,
          birthday: values.birthday.format(dateFormat.DMY),
        });
        if (res instanceof Error) {
          let error = res as AxiosError;
          toast.error(
            error.response?.data?.errors?.message ||
              error.response?.data?.error?.messages ||
              'Something went wrong!',
          );
          setConfirmLoading(false);
          return;
        }
        if (res.data) {
          toast.success(memberNotify.create);
          setConfirmLoading(false);
          setSortedInfo({ order: null, columnKey: '' });
          onOk();
          form.resetFields();
          setTimeout(() => {
            fetchFilterMembers();
          }, 500);
        }
      } else {
        res = await notifyService.updateMember({
          ...values,
          _id: initialValues._id,
          phone: values.phone ? values.phone : initialValues.phone,
          birthday: values.birthday
            ? values.birthday.format(dateFormat.DMY)
            : initialValues.birthday,
          departmentId:
            user.role !== UserRole.WORKSPACE_ADMIN
              ? initialValues.departmentId
              : departmentId,
        });
        if (res.data) {
          toast.success(memberNotify.update);
          setConfirmLoading(false);
          onOk();
          getUserInfo();
          form.resetFields();
          setTimeout(() => {
            fetchFilterMembers();
          }, 500);
        }
        if (res instanceof Error) {
          console.log('error', res);
          toast.error('Something went wrong');
        }
      }
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    } finally {
      setConfirmLoading(false);
    }

    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    setConfirmLoading(false);
    console.log('Failed:', errorInfo);
  };

  const renderDepartmentDefault = () => {
    if (!initialValues) return null;

    if (initialValues.departmentName === 'DEFAULT') {
      return (
        <Option value={initialValues.departmentId} style={{ display: 'none' }}>
          DEFAULT
        </Option>
      );
    }
  };

  return (
    <Modal
      title={`${initialValues ? 'Edit member' : 'Sync/Update new member'}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      confirmLoading={confirmLoading}
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={{
          email: initialValues?.email,
          department: initialValues?.departmentId,
          phone: initialValues?.phone,
          birthday: initialValues?.birthday
            ? moment(initialValues?.birthday, dateFormat.DMY)
            : '',
        }}
      >
        {user.role === UserRole.WORKSPACE_ADMIN && (
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Department is required',
              },
            ]}
            label="Department"
            name="department"
            // extra={
            //   departmentId &&
            //   !initialValues && (
            //     <Button
            //       loading={syncLoading}
            //       onClick={handleSyncMembers}
            //       size="small"
            //       type="primary"
            //       style={{ marginTop: '8px' }}
            //     >
            //       Sync members
            //     </Button>
            //   )
            // }
          >
            <Select
              placeholder="Select a department"
              loading={isLoadingDepartments}
              onChange={(v) => {
                setDepartmentId(v as string);
                setNewMember([]);
                form.resetFields(['display_name']);
              }}
            >
              {renderDepartmentDefault()}
              {departments.map((department) => (
                <Option key={department._id} value={department._id}>
                  {department.department_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {initialValues ? (
          <Form.Item label="Name" name="display_name">
            <Input
              disabled
              placeholder={initialValues ? initialValues.display_name : ''}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="Name"
            name="display_name"
            rules={[{ required: true, message: 'Please select a name' }]}
            tooltip="The list only displays members who are already in your department space/channel"
          >
            <Select
              loading={syncLoading}
              labelInValue
              placeholder="Select name"
              allowClear
              onChange={handleMemberChange}
            >
              {newMember.map((item: IMember) => (
                <Option key={item._id} value={item._id}>
                  {item.display_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {initialValues ? (
          <Form.Item label="Email" name="email">
            <Input disabled placeholder="Enter email" />
          </Form.Item>
        ) : (
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter an email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
        )}
        {initialValues ? (
          <Form.Item label="Phone" name="phone">
            <PatternFormat
              customInput={Input}
              placeholder="Enter phone number"
              format="############"
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: 'Please enter a phone number' }]}
          >
            <PatternFormat
              customInput={Input}
              placeholder="Enter phone number"
              format="############"
            />
          </Form.Item>
        )}
        {initialValues ? (
          <Form.Item label="Birthday" name="birthday">
            <DatePicker
              disabledDate={disabledBirthday}
              placeholder={dateFormat.DMY}
              format={dateFormat.DMY}
              className="vbot-w-full"
              onKeyDown={allowDatePicker}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="Birthday"
            name="birthday"
            rules={[{ required: true, message: 'Please enter DOB' }]}
          >
            <DatePicker
              disabledDate={disabledBirthday}
              placeholder={dateFormat.DMY}
              format={dateFormat.DMY}
              className="vbot-w-full"
              onKeyDown={allowDatePicker}
            />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <div className="vbot-text-right">
            <Button type="primary" htmlType="submit" loading={confirmLoading}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalMember;
