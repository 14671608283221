import React from 'react';
import { IUser } from '../services/user';

type TAuthContext = {
  user: Partial<IUser>;
  setUser: React.Dispatch<Partial<IUser>>;
  getUserInfo: Function;
};

export const AuthContext = React.createContext<TAuthContext>({
  user: {},
  setUser: () => {},
  getUserInfo: () => {},
});
