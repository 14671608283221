import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import { useMemberContext } from 'src/contexts/MemberContext';

import './style.less';

export const PaginationMember = () => {
  const [size, setSize] = useState(window.innerWidth);

  const { pagination, setPagination, setLoading } = useMemberContext();

  const handlePageChange = (
    currentPage: number,
    pageSize: number | undefined,
  ) => {
    if (pageSize) {
      const offset = (currentPage - 1) * pageSize;
      if (pagination.total === 0) {
        return;
      } else {
        setLoading(true);
        setPagination((prevState) => ({
          ...prevState,
          currentPage,
          offset,
          limit: pageSize,
        }));
      }
    }
  };

  useEffect(() => {
    const updateSize = () => {
      setSize(window.innerWidth);
      if (size < 576) {
        setPagination((prevState) => ({ ...prevState, limit: 10 }));
      }
    };
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [size, setPagination]);

  if (pagination.total === 0) return null;

  return (
    <Pagination
      size={size > 576 ? 'default' : 'small'}
      className="member-pagination"
      current={pagination.currentPage}
      onChange={handlePageChange}
      pageSize={pagination.limit}
      total={pagination.total}
      pageSizeOptions={['10', '20', '50']}
      showSizeChanger={true}
    />
  );
};
