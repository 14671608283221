import React from 'react';
import LoadingImg from '../assets/images/loading.gif';
import './style.less';

export const Loading = () => {
  return (
    <div className="loading">
      <img src={LoadingImg} alt="" />
    </div>
  );
};
