import { IQuestion } from './questionPack.interface';
import { IQuestionPack } from '../team/team.interface';
import axiosClient from '../../configs/axios.configs';
import { IResponse } from '../../types/response.interface';

export const getOneQuestionPack = (id: string) => {
  return axiosClient.get<IResponse<IQuestionPack>>('question-pack/' + id);
};
export const createQuestionPack = (newData: string[]) => {
  return axiosClient.post<IResponse<boolean>>('question-pack', newData);
};
export const deleteQuestionPack = (id: string) => {
  return axiosClient.delete<IResponse<boolean>>('question-pack/' + id);
};
export const updateQuestionPack = (data) => {
  return axiosClient.put<IResponse<boolean>>(
    'question-pack/' + data._id,
    data.updatedPack,
  );
};

export const getOneQuestion = (id: string) => {
  return axiosClient.get<IResponse<IQuestion>>('questions/' + id);
};
export const getQuestionsOfPack = (id: string) => {
  return axiosClient.get<IResponse<IQuestion[]>>(
    'question-pack/' + id + '/questions',
  );
};
export const createQuestion = (newData: IQuestion) => {
  return axiosClient.post<IResponse<boolean>>('question-pack', newData);
};
export const deleteQuestion = (data) => {
  return axiosClient.patch<IResponse<boolean>>('question-pack/' + data.packId, {
    questionId: data.questionId,
  });
};
