import React from 'react';
import { Table, Select, Button } from 'antd';
import { updateMembers } from 'src/services/member';
import { toast } from 'react-toastify';
import { useMemberContext } from 'src/contexts/MemberContext';
import AvatarInfo from './AvatarInfo';
import * as memberService from 'src/services/member';

const { Option } = Select;

export const DuplicateMemberTable = () => {
  const { modalImportInfo, setModalImportInfo } = useMemberContext();
  const { listDuplicated } = modalImportInfo;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 170,
      render: (_, record) => {
        return <AvatarInfo avatar={record.avatar} name={record.name} />;
      },
    },
    {
      title: 'Select email',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      render: (_, record) => {
        const groupSelected: string[] = [];
        listDuplicated.forEach((item) => {
          if (item.name.toLowerCase() === record.name.toLowerCase()) {
            groupSelected.push(item.selectedEmail);
          }
        });
        return (
          <Select
            defaultValue=""
            className="vbot-w-full"
            optionLabelProp="label"
            allowClear
            onChange={(value) => handleChangeDuplicated(record, value)}
          >
            {record.list_duplicated.length > 0 &&
              record.list_duplicated.map((item) => (
                <Option
                  key={item.No}
                  value={item.Email}
                  label={item.Email}
                  disabled={groupSelected.includes(item.Email)}
                  style={{ fontSize: '12px' }}
                >
                  <div>
                    Email: <b>{item.Email}</b>
                  </div>
                  <div>
                    Phone: <b>{item.Phone}</b>
                  </div>
                </Option>
              ))}
          </Select>
        );
      },
    },
  ];

  const handleChangeDuplicated = (record: any, value: string) => {
    const temp = [...listDuplicated];
    listDuplicated.forEach((item) => {
      if (item.id === record.id) {
        item.selectedEmail = value;
      }
    });
    setModalImportInfo({
      ...modalImportInfo,
      listDuplicated: temp,
    });
  };

  const handleSubmit = async () => {
    const selectedMembers = listDuplicated.filter((item) => item.selectedEmail);
    const payload: any[] = [];
    selectedMembers.forEach((item) => {
      const findItem = item.list_duplicated.find(
        (element) => element.Email === item.selectedEmail,
      );
      if (findItem) {
        payload.push({
          id: item.id,
          real_name: findItem.Name,
          email: findItem.Email,
          phone: findItem.Phone,
          birthday: findItem.YoB,
        });
      }
    });
    const res = await updateMembers({ members: payload });
    if (res.status === 200) {
      toast.success('Imported success');
      memberService.syncMembersAdmin();
      setModalImportInfo({ ...modalImportInfo, showModal: false });
    }
  };

  return (
    <>
      <h4>Duplicated members</h4>
      <Table
        pagination={false}
        columns={columns}
        dataSource={listDuplicated}
        rowKey="id"
      />
      <div className="vbot-text-right vbot-mt-1">
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};
