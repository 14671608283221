import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Modal,
  PageHeader,
  Row,
  Select,
  Spin,
} from 'antd';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
} from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
import { SwitchReminder } from 'src/pages/Reminder/components/SwitchReminder';
import PageHeaderCustom from 'src/components/PageHeader/PageHeader';
import { dateFormat, reminderNotify, reminderTypes } from 'src/constants';
import ReminderStatus from 'src/constants/ReminderStatus';
import ReminderType from 'src/constants/ReminderType';
import MainLayout from 'src/layouts/MainLayout';
import {
  IMentionsOfChannel,
  getMentionsByChannelId,
} from 'src/services/member';
import * as notifyService from 'src/services/reminder';
import { INewReminder } from 'src/services/reminder';
import * as TeamService from 'src/services/team';
import { IChannel } from 'src/services/team';
import * as workspaceService from 'src/services/workspace';
import { ChangeReminderModal, ViewReminder } from './components';
import './style.less';
import EmptyReminder from './components/EmptyReminder';
import { AuthContext } from 'src/contexts/Auth';
import { isEmptyObject } from 'src/helpers';

const { confirm } = Modal;
const { Option } = Select;

const Reminder = () => {
  const { user } = useContext(AuthContext);
  const [dateTime, setDateTime] = useState({
    time: '09:00',
    date: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
  });
  const [listChannels, setListChannels] = useState<IChannel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listReminders, setListReminders] = useState<INewReminder[]>([]);
  const [totalReminders, setTotalReminders] = useState<INewReminder[]>([]);
  const [filterCondition, setFilterCondition] = useState({
    channel: undefined,
    type: undefined,
    status: undefined,
  });
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [mentions, setMentions] = useState<IMentionsOfChannel[]>([]);

  // const [switchedReminder, setSwitchedReminder] = useState<{
  //   reminder: INewReminder | null;
  //   checked: boolean | null;
  // }>({ reminder: null, checked: null });

  const formatReminder = useCallback((item) => {
    let reminder = { ...item };
    if (reminder.recurrence === 0) {
      reminder.type = ReminderType.ONETIME;
      if (!reminder.status) {
        reminder.status = ReminderStatus.SENT;
      } else {
        reminder.status = checkTimeValid(
          reminder.time,
          moment(reminder.startDate[0] * 1000).format(dateFormat.DMY),
        )
          ? ReminderStatus.PENDING
          : ReminderStatus.SENT;
      }
    } else {
      if (!reminder.status) {
        reminder.status = ReminderStatus.DISABLE;
      } else {
        reminder.status = ReminderStatus.ENABLE;
      }
    }
    switch (reminder.type) {
      case ReminderType.WEEK:
        reminder.date = item.startDate.map((date) =>
          moment(date * 1000).format(dateFormat.DDD),
        );
        break;
      default:
        reminder.date = [];
        break;
    }
    return reminder;
  }, []);
  const loadWorkspaceConfig = async () => {
    const { data } = await workspaceService.getReminderWorkSpace();
    if (data.data) {
      const config = data.data.workspaceConfig;
      setDateTime({ time: config.time, date: config.days });
    }
  };
  const loadChannels = async () => {
    try {
      const channels = await TeamService.getListChannels();
      if (channels.data.data) {
        setListChannels(channels.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadReminders = useCallback(async () => {
    try {
      const { data } = await notifyService.getAllReminders();
      if (data.data) {
        setListReminders(data.data.map((item) => formatReminder(item)));
        setTotalReminders(data.data.map((item) => formatReminder(item)));
        setFilterCondition((prev) => ({ ...prev, channel: undefined }));
        setSelectedChannel(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  }, [formatReminder]);

  const checkTimeValid = (time, date) => {
    const curTime = moment(`${date} ${time}`, 'DD-MM-YYYY hh:mm:ss');
    if (moment().diff(curTime) < 0) return true; // time in future
    return false;
  };
  const handleChannelChange = (value) => {
    setSelectedChannel(value);
    setFilterCondition({
      ...filterCondition,
      channel: value ? value.value : undefined,
    });
    filterReminder({
      ...filterCondition,
      channel: value ? value.value : undefined,
    });
  };
  const filterReminder = (filters) => {
    const result: INewReminder[] = [];
    totalReminders.forEach((e) => {
      let match = false;
      for (const [key, value] of Object.entries(filters)) {
        if (key === 'channel') {
          if (e.notifyAtChannel?.channelId === value || value === undefined) {
            match = true;
          } else {
            match = false;
            break;
          }
        } else {
          if (e[key] === value || value === undefined) {
            match = true;
          } else {
            match = false;
            break;
          }
        }
      }
      if (match === true) {
        result.push(e);
      }
    });
    setListReminders(result);
  };
  const handleTypeChange = (value) => {
    setFilterCondition({ ...filterCondition, type: value });
    filterReminder({ ...filterCondition, type: value });
  };
  const handleStatusChange = (value) => {
    setFilterCondition({ ...filterCondition, status: value });
    filterReminder({ ...filterCondition, status: value });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure to delete this reminder?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        //  console.log('OK', record);
        try {
          let res = await notifyService.deleteAReminder(id);
          if (res) {
            await loadReminders();
            toast.success(reminderNotify.delete);
          }
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        // console.log('Cancel', record);
      },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    ReactDOM.unstable_batchedUpdates(async () => {
      await Promise.all([
        loadWorkspaceConfig(),
        loadChannels(),
        loadReminders(),
      ]);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmptyObject(user)) return;
    if (listChannels.length === 0) return;

    async function fetchMentionsFromGoole() {
      const newMentions: IMentionsOfChannel[] = [];
      for (let i = 0; i < listChannels.length; i++) {
        const channel = listChannels[i];
        const response = await getMentionsByChannelId(channel.channelId);
        if (response.data && response.data.data) {
          const listMentions = response.data.data;
          listMentions.push({
            name: 'users/all',
            displayName: 'all',
          });
          newMentions.push({
            channelId: channel.channelId,
            listMentions,
          });
        }
      }
      setMentions(newMentions);
    }

    if (user.pref === 'google') {
      fetchMentionsFromGoole();
    }
  }, [user, listChannels]);

  return (
    <MainLayout>
      <PageHeaderCustom headerName="Reminders">
        <ChangeReminderModal
          typeModal="ADD"
          title="Create new reminder"
          reminder={dateTime}
          listChannels={listChannels}
          BtnContent={
            <Button type="primary" icon={<PlusOutlined />}>
              Create reminder
            </Button>
          }
          reload={loadReminders}
          mentions={mentions}
        />
      </PageHeaderCustom>

      <Divider />

      <div className="reminder-list">
        <SectionHeader
          className="reminder-list__header-filter"
          sectionName="Filter list of reminders"
        >
          <Select
            className="select"
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            labelInValue
            placeholder="Select a channel / space"
            allowClear
            onChange={handleChannelChange}
            value={selectedChannel}
          >
            {listChannels.map((item: IChannel) => (
              <Option key={item.channelId} value={item.channelId}>
                {item.channelName}
              </Option>
            ))}
          </Select>

          <Select
            className="select"
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            placeholder="Select a type"
            allowClear
            onChange={handleTypeChange}
          >
            {reminderTypes.map((item) => (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>

          <Select
            className="select"
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            placeholder="Select a status"
            allowClear
            onChange={handleStatusChange}
          >
            <Option value={ReminderStatus.ENABLE}>
              {ReminderStatus.ENABLE}
            </Option>
            <Option value={ReminderStatus.DISABLE}>
              {ReminderStatus.DISABLE}
            </Option>
            <Option value={ReminderStatus.SENT}>{ReminderStatus.SENT}</Option>
            <Option value={ReminderStatus.PENDING}>
              {ReminderStatus.PENDING}
            </Option>
          </Select>
        </SectionHeader>

        {isLoading ? (
          <div className="wrap-container">
            <Spin />
          </div>
        ) : (
          <div className="reminder-list">
            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
              {listReminders.map((reminder, index) => {
                const extraButton = [
                  <div
                    key={reminder._id + 'switch'}
                    style={{ marginTop: '4px' }}
                  >
                    <SwitchReminder
                      reload={loadReminders}
                      reminder={reminder}
                    />
                  </div>,
                  <ChangeReminderModal
                    key={reminder._id}
                    typeModal="ADD"
                    title="Create reminder"
                    reminder={reminder}
                    listChannels={listChannels}
                    BtnContent={<Button>Copy</Button>}
                    reload={loadReminders}
                    mentions={mentions}
                  />,
                  reminder.status === ReminderStatus.SENT ||
                  reminder.status === ReminderStatus.DISABLE ? (
                    <Fragment key={reminder._id + 'empty'} />
                  ) : (
                    <ChangeReminderModal
                      key={reminder._id + 'update'}
                      typeModal="UPDATE"
                      title="Edit reminder"
                      reminder={reminder}
                      listChannels={listChannels}
                      BtnContent={<Button type="primary">Edit</Button>}
                      reload={loadReminders}
                      mentions={mentions}
                    />
                  ),
                  <Button
                    key={reminder._id + 'delete'}
                    type="link"
                    danger
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      if (reminder._id) {
                        showDeleteConfirm(reminder._id);
                      }
                    }}
                  />,
                ];
                return (
                  <Col sm={24} md={12} key={reminder._id}>
                    <PageHeader
                      ghost={false}
                      className="site-page-header"
                      title={reminder.name}
                      extra={extraButton}
                    >
                      <ViewReminder detailReminder={reminder} />
                    </PageHeader>
                  </Col>
                );
              })}
            </Row>
            {listReminders.length === 0 && (
              <EmptyReminder
                dateTime={dateTime}
                listChannels={listChannels}
                reload={loadReminders}
                mentions={mentions}
              />
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Reminder;
