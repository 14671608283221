import { Select } from 'antd';
import React, { useContext } from 'react';
import { UserRole } from 'src/constants/Roles';
import { AuthContext } from 'src/contexts/Auth';
import { useMemberContext } from 'src/contexts/MemberContext';
import { OnService } from 'src/helpers';
import './style.less';

const { Option } = Select;

export const SearchMemberFilter = () => {
  const {
    setOnService,
    setLoading,
    setPagination,
    setAccountType,
    setDepartmentId,
    departments,
  } = useMemberContext();

  const { user } = useContext(AuthContext);

  const handleOnServiceChange = (value: OnService) => {
    setLoading(true);
    setOnService(value);
    setPagination((prevState) => ({ ...prevState, currentPage: 1, offset: 0 }));
  };

  const handleChangeAccountType = (value: string) => {
    setLoading(true);
    setAccountType(value as UserRole);
    setPagination((prevState) => ({ ...prevState, currentPage: 1, offset: 0 }));
  };
  const handleChangeDepartment = (value: string) => {
    setLoading(true);
    setDepartmentId(value);
    setPagination((prevState) => ({ ...prevState, currentPage: 1, offset: 0 }));
  };

  const createDepartmentOptions = () => {
    return departments.map((d) => ({
      key: d._id,
      label: d.department_name,
      value: d._id,
    }));
  };

  const createRolesOptions = () => {
    return Object.values(UserRole).map((role) => {
      if (role === UserRole.WORKSPACE_ADMIN) {
        return {
          label: 'Workspace Admin',
          value: role,
        };
      } else if (role === UserRole.DEPARTMENT_ADMIN) {
        return { label: 'Department Admin', value: role };
      } else {
        return { label: 'Full Member', value: role };
      }
    });
  };

  return (
    <>
      {user.role === UserRole.WORKSPACE_ADMIN && (
        <Select
          style={{ minWidth: '100px' }}
          allowClear
          showSearch
          filterOption={(input, option) => {
            const label = option?.label as string;
            return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          placeholder="Select department"
          onChange={handleChangeDepartment}
          options={createDepartmentOptions()}
        />
      )}
      <Select
        style={{ minWidth: '100px' }}
        allowClear
        showSearch
        filterOption={(input, option) => {
          const label = option?.label as string;
          return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        placeholder="Select account type"
        onChange={handleChangeAccountType}
        options={createRolesOptions()}
      />
      <Select
        style={{ minWidth: '100px' }}
        showSearch
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        // defaultValue={OnService.All}
        allowClear
        onChange={handleOnServiceChange}
        placeholder="Member status"
      >
        <Option key={2} value={OnService.Active}>
          {OnService.Active}
        </Option>
        <Option key={3} value={OnService.InActive}>
          {OnService.InActive}
        </Option>
      </Select>
    </>
  );
};
