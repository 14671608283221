import React from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface IProps {
  tooltip?: string;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
}

const DeleteIcon = ({ tooltip, onClick, style, className }: IProps) => {
  return (
    <Tooltip title={tooltip}>
      <DeleteFilled
        onClick={onClick}
        style={{ color: '#ff4d4f', fontSize: 16, ...style }}
        className={className}
      />
    </Tooltip>
  );
};

export default DeleteIcon;
