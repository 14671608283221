import { Result } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { Loading } from '../../components';
//import * as userService from '../../services/user';
//import Logo from '../../assets/images/195.png';
import { AuthContext } from '../../contexts/Auth';
import { addToSlack } from '../../services/user';
const AddToSlack = () => {
  let history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const code = query.get('code');
  const { setUser } = useContext(AuthContext);
  const [addSuccess, setAddSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      if (code) {
        console.log(code);
        try {
          const { data } = await addToSlack(code);
          if (data.token) {
            localStorage.setItem('token', data.token);
            setAddSuccess(true);
            setUser(data.data);
            setTimeout(() => {
              history.push('/');
            }, 3000);
          }
        } catch (e) {
          //
        }
      }
    })();
  }, [code, history, setUser]);
  if (!code)
    return (
      <Route>
        <Redirect to="/" />
      </Route>
    );

  return (
    <div>
      {addSuccess ? (
        <Result
          status="success"
          title="Successfully Added VMO BOT to Slack!"
          subTitle="Will takes 3s to go home page, please wait."
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AddToSlack;
