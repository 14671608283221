/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Checkbox, Form } from 'antd';
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TimezoneSelect from 'react-timezone-select';
import { configWorkspaceGoogle } from 'src/services/configWorkspace';
import Logo from '../../assets/images/195.png';
import './style.less';
// const { Option } = Select;
export const ConfigWorkspace = () => {
  const [selectedTimezone, setSelectedTimezone] = useState({
    abbrev: 'Asia/Bangkok',
    altName: 'Asia/Bangkok',
    label: '(GMT+7:00) Bangkok, Hanoi, Jakarta',
    value: 'Asia/Bangkok',
  });
  const [loading, setLoading] = useState(false);
  const { domain } = useParams();
  const location = useLocation();
  const state: any = location.state;

  const [isAgree, setAgree] = useState(false);

  const onFinish = async () => {
    const GMT = selectedTimezone.label
      .split(' ')[0]
      .replace('(GMT', '')
      .replace(')', '');
    const configWorkspace = {
      domain: domain === 'google' ? state.accountType : domain,
      workspaceId: 'unknown',
      timezone: GMT,
      isLoginGoogle: domain === 'google',
    };
    setLoading(true);
    try {
      const { data } = await configWorkspaceGoogle(configWorkspace);
      if (data.message === 'Go to google-chat to finish configuration') {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  return (
    <>
      <div className="wrap-config">
        <header className="header">
          <div className="header-img">
            <img src={Logo} alt="chat-bot" width="70" height="70" />
          </div>
          <div className="header-title">
            <h1>Creating your organization</h1>
            <h3>Get it running by config workspace</h3>
          </div>
        </header>
        <Form
          name="normal_login"
          className="login-form-config"
          onFinish={onFinish}
        >
          <section>
            <p>TIME ZONE</p>
            <TimezoneSelect
              value={selectedTimezone}
              onChange={setSelectedTimezone}
            />
          </section>
          <Form.Item
            name="remember"
            style={{ width: '100%' }}
            className="check"
          >
            <Checkbox onChange={() => setAgree(!isAgree)}>
              I have read and agree with VMOBot's{' '}
              <a href="#">terms and conditions</a> and{' '}
              <a href="#">privacy policy</a>.
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              loading={loading}
              disabled={!isAgree}
              htmlType="submit"
              size="large"
              type="primary"
              shape="round"
              className="config-form-button"
            >
              Start
            </Button>
          </Form.Item>
          <Form.Item>
            <a href="/login">Log out and log in with another account</a>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
