import { ITeamReport, IReportByTeam, IListReports } from './report.interface';
import axiosClient from '../../configs/axios.configs';
import { IResponse } from '../../types/response.interface';

export const getReports = (data) => {
  const teams =
    data.teams && data.teams.length > 0 ? '&teams=' + data.teams : '';
  return axiosClient.get<IResponse<IListReports>>(
    `reports/?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&limit=${data.limit}${teams}`,
  );
};
export const getReportsByTeam = () => {
  return axiosClient.get<IResponse<IReportByTeam>>('reports/team/');
};
export const getTeamReport = (data) => {
  return axiosClient.get<IResponse<ITeamReport[]>>(
    `reports/team/${data.id}?startDate=${data.startDate}&endDate=${data.endDate}`,
  );
};
export const getObstacles = (data) => {
  const teams = data.teamId !== '' ? '&teams=' + data.teamId : '';
  return axiosClient.get<IResponse<any>>(
    `reports/obstacle/?date=${data.date}${teams}`,
  );
};
export const getRankingReports = (data) => {
  return axiosClient.get<IResponse<any>>(
    `reports/ranking?startDate=${data.startTime}&endDate=${data.endTime}`,
  );
};
