import { Empty, Spin } from 'antd';
import {
  Axis,
  Chart,
  Coordinate,
  getTheme,
  Interaction,
  Interval,
  Tooltip,
} from 'bizcharts';
import React, { useEffect, useState } from 'react';
import TimesheetStatusType from 'src/constants/TimesheetType';
import { IDataCharts, IQueryParamTimesheet } from 'src/services/timesheet';
import * as timesheetServices from 'src/services/timesheet';
const queryString = require('query-string');

interface IPiChartsTimesheetProps {
  param: IQueryParamTimesheet;
  setParam: React.Dispatch<React.SetStateAction<IQueryParamTimesheet>>;
}
const PiChartsTimesheet: React.FC<IPiChartsTimesheetProps> = ({
  param,
  setParam,
}) => {
  const [dataChart, setDataCharts] = useState<IDataCharts[]>([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const { page, offset, ...newParam } = param;
    const stringified = queryString.stringify(newParam);
    const getDataCharts = async () => {
      try {
        const { data } = await timesheetServices.getAllRequestTimesheet(
          stringified,
        );
        if (data.data) {
          setLoading(false);
          if (param.requestType) {
            setDataCharts(handleDataStatusMember(data.data));
            return;
          }
          setDataCharts(handleData(data.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDataCharts();
  }, [param]);
  const handleData = (dataInput) => {
    const arrDataOutput: IDataCharts[] = [];
    const changeData = dataInput.map((member) => member.request.type);
    const uniqueType: string[] = Array.from(new Set(changeData));
    uniqueType.forEach((type: string) => {
      const filterData = dataInput.filter((data) => data.request.type === type);
      const percent = filterData.length / dataInput.length;
      arrDataOutput.push({
        item: type,
        count: filterData.length,
        percent: +percent.toFixed(3),
      });
    });
    return arrDataOutput;
  };
  const handleDataStatusMember = (dataHandle) => {
    const arrDataOutput: IDataCharts[] = [];
    const changeData = dataHandle.map(
      (member) => member.requestedByMember.real_name,
    );
    const uniqueMember: string[] = Array.from(new Set(changeData));
    uniqueMember.forEach((member) => {
      const filterData = dataHandle.filter(
        (data) => data.requestedByMember.real_name === member,
      );
      const percent = filterData.length / dataHandle.length;
      arrDataOutput.push({
        item: member,
        count: filterData.length,
        percent: +percent.toFixed(3),
      });
    });
    return arrDataOutput;
  };
  const handleClickPiCharts = (value) => {
    const { data } = value.data;
    if (
      data.item === TimesheetStatusType.LATE ||
      data.item === TimesheetStatusType.MORNING_OFF ||
      data.item === TimesheetStatusType.AFTERNOON_OFF ||
      data.item === TimesheetStatusType.WORK_FROM_HOME_MORNING ||
      data.item === TimesheetStatusType.WORK_FROM_HOME_AFTERNOON ||
      data.item === TimesheetStatusType.WORK_FROM_HOME ||
      data.item === TimesheetStatusType.BREAK ||
      data.item === TimesheetStatusType.EARLY ||
      data.item === TimesheetStatusType.DAY_OFF
    ) {
      setTimeout(() => {
        setParam({ ...param, requestType: data.item });
      }, 300);
      return;
    }
  };
  const cols = {
    percent: {
      formatter: (val) => {
        val = (val * 100).toFixed(1) + '%';
        return val;
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : dataChart.length > 0 ? (
        <Chart
          height={400}
          data={dataChart}
          scale={cols}
          onIntervalClick={(ev) => handleClickPiCharts(ev)}
          autoFit
        >
          <Coordinate type="theta" radius={0.75} />
          <Tooltip showTitle={false} />
          <Axis visible={false} />
          <Interval
            position="percent"
            adjust="stack"
            color="item"
            style={{
              lineWidth: 1,
              stroke: '#fff',
            }}
            label={[
              'count',
              {
                content: (data) => {
                  const percent = (data.percent * 100).toFixed(1);
                  return `${data.item}: ${percent}%`;
                },
              },
            ]}
            state={{
              selected: {
                style: (t) => {
                  const res =
                    getTheme().geometries.interval.rect.selected.style(t);
                  return { ...res, fill: 'red' };
                },
              },
            }}
          />
          <Interaction type="element-single-selected" />
        </Chart>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default React.memo(PiChartsTimesheet);
