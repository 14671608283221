import { TransactionOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from 'src/components/PageHeader/PageHeader';
import MainLayout from 'src/layouts/MainLayout';
import { CheckoutForm } from './components/CheckoutForm';
import { InfoPackage } from './components/InforPackage';
import './style.less';
export const PaymentPackage = () => {
  const [reset, setRest] = useState(false);
  const history = useHistory();
  const [isShowModal, setShowModal] = useState<boolean>(false);
  return (
    <MainLayout>
      <PageHeader headerName="Payment">
        <Button
          onClick={() => setShowModal(true)}
          type="primary"
          icon={<TransactionOutlined style={{ fontSize: '17px' }} />}
        >
          Update Card
        </Button>
      </PageHeader>

      <Divider />
      <Modal
        title="Update Card"
        visible={isShowModal}
        // onOk={this.hideModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <CheckoutForm
          reset={reset}
          setRefresh={setRest}
          setShowModal={setShowModal}
        />
      </Modal>
      <InfoPackage setRefresh={setRest} reset={reset} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '30px',
        }}
      >
        <Button onClick={() => history.push('/payment')} type="primary">
          Change Package
        </Button>
      </div>
    </MainLayout>
  );
};
