/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { DatePicker, Divider, Table } from 'antd';
//import * as reportService from '../../../services/report';
import { IListReports, IReport } from '../../../services/report';
import moment from 'moment';
import { MemberInfo } from '../../../components';
import { ReportContent } from './ReportContent';
import { ExportExcel } from './ExportExcel';
import { dateFormat, PAGINATION } from '../../../constants';
import { checkDateRange } from '../../../helpers';

const { RangePicker } = DatePicker;

type TeamReportProps = {
  listTeams: {
    value: string;
    text: string;
  }[];
  listDefaultReports: IListReports;
  loadListReports: (
    page: number,
    limit: number,
    startDate?,
    endDate?,
    teams?: string[],
  ) => Promise<void>;
};
export const TeamReport: React.FC<TeamReportProps> = (
  props: TeamReportProps,
) => {
  const { listDefaultReports, loadListReports, listTeams } = props;
  const [dates, setDates] = useState<number[]>([
    checkDateRange(7).startDate,
    checkDateRange(7).endDate,
  ]);
  const [listReports, setListReports] = useState<IReport[]>([]);
  const [listFilterTeams, setListFilterTeams] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deletedTeam = 'This team had been deleted';
  const handleTableChange = async (pagination, filters, sorter) => {
    setListFilterTeams(filters.teams);
    setIsLoading(true);
    await loadListReports(
      pagination.current,
      pagination.pageSize,
      dates[0],
      dates[1],
      filters.teams,
    );
    setIsLoading(false);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      render: (e, record) => {
        return (
          <MemberInfo
            info={{
              real_name: record.member.real_name || '',
              avatar: record.member.avatar || '',
            }}
          />
        );
      },
    },
    {
      title: 'Team',
      dataIndex: 'teams',
      key: 'teams',
      filters: listTeams,
      render: (e, record) => {
        return <span>{record.team ? record.team.name : deletedTeam}</span>;
      },
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{text}</span>,
    },
  ];

  const onDateRangeChange = async (value, dateString) => {
    //console.log('Formatted Selected Time: ', dateString);
    setDates([value[0].unix(), value[1].unix()]);
    setIsLoading(true);
    await loadListReports(
      PAGINATION.page,
      PAGINATION.pageSize,
      value[0].unix(),
      value[1].unix(),
      listFilterTeams,
    );
    setIsLoading(false);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  useEffect(() => {
    setListReports(
      listDefaultReports.reports.map((item, index) => {
        return {
          ...item,
          key: index,
          createdAt: moment(item.createdAt).format(dateFormat.DMY),
        };
      }),
    );
  }, [listDefaultReports]);
  return (
    <>
      <div className="header-report-chart">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <RangePicker
            defaultValue={[moment().subtract(7, 'days'), moment()]}
            disabledDate={disabledDate}
            style={{ margin: '0px 20px' }}
            allowClear={false}
            format={dateFormat.DMY}
            onChange={onDateRangeChange}
          />
          <ExportExcel
            filterTeam={listFilterTeams}
            dateRange={dates}
            listTeams={listTeams}
          />
        </div>
      </div>
      <Divider />
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <ReportContent content={record.reportContent} />
          ),
        }}
        dataSource={listReports}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          total: listDefaultReports.totalReports,
          showSizeChanger: true,
          defaultPageSize: PAGINATION.pageSize,
        }}
      />
    </>
  );
};
