import React from 'react';
import { Table } from 'antd';
import { MemberInfo } from '../../../components';
import { ReportContent } from './ReportContent';
import { IReport } from '../../../services/report';

type ListObstacleModalProps = {
  listObstacles?: Partial<IReport>[];
};
export const ListObstacleModal: React.FC<ListObstacleModalProps> = (
  props: ListObstacleModalProps,
) => {
  const { listObstacles } = props;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      //...getColumnSearchProps('member'),
      render: (e, record) => {
        return (
          <MemberInfo
            info={{
              real_name: record.member.real_name || '',
              image_72: record.member.avatar || '',
            }}
          />
        );
      },
    },
    {
      title: 'Obstacle',
      dataIndex: 'teams',
      key: 'teams',
      render: (e, record) => {
        //console.log('reportContent', record.reportContent);
        return (
          <ReportContent
            content={record.reportContent
              .filter((item) => item.question.type === 'OBSTACLE')
              .map((item) => {
                return { ...item, question: { ...item.question, hint: '' } };
              })}
          />
        );
      },
    },
    {
      title: 'Team',
      dataIndex: 'teams',
      key: 'teams',
      render: (e, record) => {
        return <span>{record.team.name}</span>;
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={listObstacles?.map((item, index) => {
        return { ...item, key: index };
      })}
    />
  );
};
