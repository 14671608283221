import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useHistory, Redirect, Route } from 'react-router-dom';
import { Loading } from '../../components';
import { signInWithSlack } from '../../services/user';
import { AuthContext } from '../../contexts/Auth';
import { SLACK_AUTHORIZE_URL } from '../../constants';
import { Button, Result } from 'antd';
import SlackLogo from '../../assets/images/slack-logo.png';

const SignWithSlack = () => {
  const history = useHistory();
  const { setUser } = useContext(AuthContext);
  const [addSuccess, setAddSuccess] = useState(true);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const code = query.get('code');
  const addSlackParam = {
    scope:
      'channels:history,channels:manage,channels:read,chat:write,commands,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,team:read,users:read,users:read.email',
    user_scope:
      'channels:history,channels:read,channels:write,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,team:read,users:read,users:read.email',
    client_id: process.env.REACT_APP_CLIENT_ID!,
    redirect_uri: process.env.REACT_APP_ADD_TO_SLACK_CALLBACK!,
  };
  let ADD_SLACK_URL = `${SLACK_AUTHORIZE_URL}?${new URLSearchParams(
    addSlackParam,
  ).toString()}`;

  useEffect(() => {
    (async () => {
      if (code) {
        try {
          const { data } = await signInWithSlack(code);
          if (data.data) {
            localStorage.setItem('token', data.token);
            history.push('/');
            setUser(data.data);
          } else {
            setAddSuccess(false);
          }
        } catch (e) {
          //
        }
      }
    })();
  }, [code, history, setUser]);

  if (!code)
    return (
      <Route>
        <Redirect to="/" />
      </Route>
    );

  return (
    <div>
      {!addSuccess ? (
        <Result
          title="Please add to slack"
          extra={
            <Button type="default" shape="round" size="large">
              <a href={ADD_SLACK_URL}>
                <img src={SlackLogo} alt="slack-logo" height="22" />{' '}
                <span>
                  <b>Add to Slack</b>
                </span>
              </a>
            </Button>
          }
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SignWithSlack;
