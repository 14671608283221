/* eslint-disable prettier/prettier */
import { PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Select, Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MemberInfo } from 'src/components';
import {
  columnsTable,
  dateFormat,
  defaultPage,
  defaultPageSize,
} from 'src/constants/index';
import TimesheetStatusType from 'src/constants/TimesheetType';
import { AuthContext } from 'src/contexts/Auth';
import {
  getDateRange,
  getDateRangeMinutesSecond,
  removeAccents,
  timeConvert,
} from 'src/helpers';
import MainLayout from 'src/layouts/MainLayout';
import { getListActiveMember, IMember } from 'src/services/member';
import * as timesheetServices from 'src/services/timesheet';
import {
  IDataMember,
  IDataTableRow,
  IQueryParamTimesheet,
} from 'src/services/timesheet';
import ChartsReportDate from './components/ChartsReportDate';
import CheckStatus from './components/CheckStatus';
import { CreateNewRequest } from './components/CreateNewRequest';
import ExportTimeSheet from './components/ExportTimesheet';
import PiChartsTimesheet from './components/PiChartsTimeSheet';
import TableRanking from './components/TableRanking';
import { UpdateRequest } from './components/UpdateRequest';
import PageHeader from 'src/components/PageHeader/PageHeader';
import './style.less';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
import { UserRole } from 'src/constants/Roles';
const queryString = require('query-string');
const { RangePicker } = DatePicker;
const { Option } = Select;

export const Timesheet: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [param, setParam] = useState<IQueryParamTimesheet>({
    page: defaultPage,
    offset: defaultPageSize,
  });
  const [totalItems, setTotalItem] = useState<number>(0);
  const [stateDataTimesheet, setStateDataTimesheet] = useState<IDataTableRow[]>(
    [],
  );
  const [listMember, setListMember] = useState<IMember[]>([]);
  const { user } = useContext(AuthContext);

  const handleDataTimesheet = useCallback(
    (dataTimesheet: IDataMember[]): IDataTableRow[] => {
      return dataTimesheet.map((member) => {
        // console.log(member);
        return {
          key: member._id,
          member: <MemberInfo info={member.requestedByMember} />,
          period: [
            TimesheetStatusType.AFTERNOON_OFF,
            TimesheetStatusType.WORK_FROM_HOME_AFTERNOON,
            TimesheetStatusType.MORNING_OFF,
            TimesheetStatusType.WORK_FROM_HOME_MORNING,
          ].includes(member.request.type)
            ? '04:00'
            : member.request.isOff &&
              [
                TimesheetStatusType.WORK_FROM_HOME,
                TimesheetStatusType.DAY_OFF,
              ].includes(member.request.type)
            ? getDateRange(member.request.isOff.start, member.request.isOff.end)
            : member.request.isOt
            ? getDateRangeMinutesSecond(
                member.request.isOt.start,
                member.request.isOt.end,
              )
            : timeConvert(member.request.period),
          reason: `${
            member.request.reason === undefined ? 'none' : member.request.reason
          }`,
          request: moment
            .unix(+member.request.requestAt)
            .format(dateFormat.DMY),
          status: <CheckStatus status={member.request.type} />,
          reviewed: member.approvedByMember?.real_name || '',
          action: member.status,
          edit: (
            <UpdateRequest
              param={param}
              setParam={setParam}
              dataRequest={member}
            />
          ),
        };
      });
    },
    [param],
  );
  const getDataTimesheet = useCallback(() => {
    const stringified = queryString.stringify(param);
    async function getData() {
      setLoading(true);
      try {
        const { data: dataTimeSheet } =
          await timesheetServices.getLimitRequestTimesheet(stringified);
        if (dataTimeSheet.data?.requests) {
          const changeDataInput = handleDataTimesheet(
            dataTimeSheet.data.requests,
          );
          setTotalItem(dataTimeSheet.data.totalRequests);
          setStateDataTimesheet(changeDataInput);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    getData();
  }, [param, handleDataTimesheet]);
  useEffect(() => {
    getDataTimesheet();
  }, [getDataTimesheet, param]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getListActiveMember();
        if (data.data) {
          setListMember(data.data);
        }
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  const onDateRangeChange = (date) => {
    const startTime = date[0].unix();
    const endTime = date[1].unix();
    setParam({ ...param, startTime, endTime });
  };
  // const disabledDate = (current) => {
  //   return current && current > moment().endOf('day');
  // };
  const handleChangeType = (valueStatus: string) => {
    setParam({ ...param, requestType: valueStatus });
  };
  const handleChangeStatus = (valueAction: string) => {
    setParam({ ...param, status: valueAction });
  };
  const handleChangeMember = (valueMember: string) => {
    setParam({ ...param, requestedBy: valueMember });
  };
  const handlePagination = (valuePagination) => {
    setParam({
      ...param,
      page: valuePagination.current,
      offset: valuePagination.pageSize,
    });
  };
  return (
    <MainLayout>
      <PageHeader headerName="Timesheet">
        <RangePicker
          defaultValue={[moment().subtract(0, 'days'), moment()]}
          allowClear={false}
          format={dateFormat.DMY}
          onChange={onDateRangeChange}
        />

        {user &&
          [UserRole.WORKSPACE_ADMIN, UserRole.DEPARTMENT_ADMIN].includes(
            user.role!,
          ) && (
            <>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => setCreate(true)}
              >
                New request
              </Button>
              <CreateNewRequest
                listMember={listMember}
                param={param}
                setParam={setParam}
                isShow={isCreate}
                setShow={setCreate}
              />
            </>
          )}
        <ExportTimeSheet param={param} />
      </PageHeader>

      <Divider />

      <SectionHeader
        sectionName="Filter Timesheet"
        className="filter-timesheet"
      >
        <Select
          className="filter-timesheet__select"
          mode="multiple"
          showArrow
          showSearch
          placeholder="Select members"
          optionFilterProp="children"
          onChange={handleChangeMember}
          loading={isLoading}
          allowClear
          filterOption={(input, option) => {
            if (option) {
              return (
                removeAccents(option.children.props.info.real_name)
                  .toLowerCase()
                  .indexOf(removeAccents(input).toLowerCase()) >= 0
              );
            }
            return true;
          }}
        >
          {listMember?.map((member) => (
            <Option key={member.userId} value={member.userId}>
              <MemberInfo info={member} />
            </Option>
          ))}
        </Select>

        <Select
          // className="filter-select"
          showArrow
          mode="multiple"
          placeholder="Select types"
          value={param.requestType}
          onChange={handleChangeType}
          allowClear
        >
          <Option value={TimesheetStatusType.EARLY}>
            {TimesheetStatusType.EARLY}
          </Option>
          <Option value={TimesheetStatusType.LATE}>
            {TimesheetStatusType.LATE}
          </Option>
          <Option value={TimesheetStatusType.BREAK}>
            {TimesheetStatusType.BREAK}
          </Option>
          <Option value={TimesheetStatusType.MORNING_OFF}>
            {TimesheetStatusType.MORNING_OFF}
          </Option>
          <Option value={TimesheetStatusType.AFTERNOON_OFF}>
            {TimesheetStatusType.AFTERNOON_OFF}
          </Option>
          <Option value={TimesheetStatusType.DAY_OFF}>DAY OFF</Option>
          <Option value={TimesheetStatusType.WORK_FROM_HOME}>
            {TimesheetStatusType.WORK_FROM_HOME}
          </Option>
          <Option value={TimesheetStatusType.WORK_FROM_HOME_AFTERNOON}>
            {TimesheetStatusType.WORK_FROM_HOME_AFTERNOON}
          </Option>
          <Option value={TimesheetStatusType.WORK_FROM_HOME_MORNING}>
            {TimesheetStatusType.WORK_FROM_HOME_MORNING}
          </Option>
          <Option value={TimesheetStatusType.OT}>
            {TimesheetStatusType.OT}
          </Option>
        </Select>

        <Select
          // className="filter-select"
          placeholder="Select status"
          onChange={handleChangeStatus}
          allowClear
        >
          <Option value={TimesheetStatusType.PENDING}>Pending</Option>
          <Option value={TimesheetStatusType.APPROVED}>Approve</Option>
          <Option value={TimesheetStatusType.REJECTED}>Reject</Option>
        </Select>
      </SectionHeader>

      <div className="table-timesheet">
        <div className="timesheet-charts">
          <div className="charts-report-date">
            <ChartsReportDate param={param} />
          </div>
          <div className="pi-charts-timesheet">
            <PiChartsTimesheet param={param} setParam={setParam} />
          </div>
        </div>
        <Table
          className="timesheet-detail-member-table"
          scroll={{ x: 1000 }}
          pagination={{
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showSizeChanger: totalItems > defaultPageSize,
            current: param.page,
            pageSize: param.offset,
            total: totalItems,
          }}
          onChange={handlePagination}
          columns={
            user &&
            [UserRole.WORKSPACE_ADMIN, UserRole.DEPARTMENT_ADMIN].includes(
              user.role!,
            )
              ? columnsTable
              : columnsTable.slice(0, columnsTable.length - 1)
          }
          dataSource={stateDataTimesheet}
          loading={isLoading}
        />
        <TableRanking listMember={listMember} />
      </div>
    </MainLayout>
  );
};
