/* eslint-disable prettier/prettier */
import moment from 'moment';
import { dateFormat, minutesInHour, SATURDAY, SUNDAY } from 'src/constants';
import { IMember } from 'src/services/member';
import TimesheetStatusType from '../constants/TimesheetType';
interface INameMember {
  name: string;
}
export const isLogin = () => !!window.localStorage.getItem('token');
export const platformLogin = () => window.localStorage.getItem('platform');
export const checkDateRange = (days: number | string = 0) => {
  switch (days) {
    case 'thisWeek':
      return {
        endDate: moment().unix(),
        startDate: moment().startOf('isoWeek').unix(),
      };
    case 'lastWeek':
      return {
        endDate: moment().subtract(1, 'weeks').endOf('isoWeek').unix(),
        startDate: moment().subtract(1, 'weeks').startOf('isoWeek').unix(),
      };
    case 'thisMonth':
      return {
        endDate: moment().unix(),
        startDate: moment().startOf('month').unix(),
      };
    case 'lastMonth':
      return {
        endDate: moment().subtract(1, 'months').endOf('month').unix(),
        startDate: moment().subtract(1, 'months').startOf('month').unix(),
      };
    default:
      return {
        endDate: moment().unix(),
        startDate: moment().subtract(days, 'days').unix(),
      };
  }
};

export function calculatorDayFromRangeDay(startTime): {
  noYear: string[];
  withYear: string[];
} {
  const monthStartTime = new Date(startTime * 1000);
  const dateMonth = monthStartTime.getMonth() + 1;
  const rangeDay = creatArrRange(
    +moment.unix(startTime).startOf('month').format('D'),
    +moment.unix(startTime).endOf('month').format('D'),
  );
  const noYear = rangeDay.range.map((date: number) => {
    return `${dateMonth < 10 ? `0${dateMonth}` : dateMonth}/${
      date < 10 ? `0${date}` : date
    }`;
  });
  const withYear = rangeDay.range
    .map((date) => {
      return `${monthStartTime.getFullYear()}/${
        dateMonth < 10 ? `0${dateMonth}` : dateMonth
      }/${date < 10 ? `0${date}` : date}`;
    })
    .map((date) => moment(new Date(date)).format(dateFormat.DDD));
  return {
    noYear: noYear,
    withYear: withYear,
  };
}
export function handleDataConvert(dataTimesheet) {
  const tableName: INameMember[] = [];
  console.log('tablename', tableName);
  const mapDataTimesheetGetName: string[] = dataTimesheet.map((member) => {
    return member.requestedByMember.real_name;
  });
  const uniqueName = Array.from(new Set(mapDataTimesheetGetName));
  const tableDate: object[] = [];
  uniqueName.forEach((uniqueMember, i) => {
    const arrColumnDate: any = [];
    const columDate: object[] = [];
    const filterUniqueMember = dataTimesheet.filter((member) => {
      return member.requestedByMember.real_name === uniqueMember;
    });
    console.log('filter', filterUniqueMember);
    const mapDate = filterUniqueMember.map((date) =>
      new Date(+date.timestamp * 1000).getDate(),
    );
    const uniqueDate = Array.from(new Set(mapDate));
    uniqueDate.forEach((date) => {
      const filterDate = filterUniqueMember.filter(
        (data) => new Date(+data.timestamp * 1000).getDate() === date,
      );
      arrColumnDate.push(filterDate);
    });
    const maxLengthArr = Math.max(...arrColumnDate.map((arr) => arr.length));
    for (let i = 0; i < maxLengthArr; i++) {
      tableName.push({ name: uniqueMember });
      columDate.push({});
    }
    for (let i = 0; i < maxLengthArr; i++) {
      for (let j = 0; j < arrColumnDate.length; j++) {
        if (arrColumnDate[j][i] !== undefined) {
          const dateNow = moment.unix(arrColumnDate[j][i].timestamp).date();
          if (
            arrColumnDate[j][i].request.isOff &&
            [
              TimesheetStatusType.WORK_FROM_HOME,
              TimesheetStatusType.DAY_OFF,
            ].includes(arrColumnDate[j][i].request.type)
          ) {
            const startDayOff = moment
              .unix(arrColumnDate[j][i].request.isOff.start)
              .date();
            const endDayOff = moment
              .unix(arrColumnDate[j][i].request.isOff.end)
              .date();
            const yearDay = moment
              .unix(arrColumnDate[j][i].request.isOff.end)
              .year();
            const monthDay =
              moment.unix(arrColumnDate[j][i].request.isOff.end).month() + 1;
            const rangeDateOff = creatArrRange(startDayOff, endDayOff);
            rangeDateOff.range.forEach((dayOff) => {
              if (
                moment(
                  `${yearDay}/${monthDay}/${dayOff}`,
                  dateFormat.YMD,
                ).format(dateFormat.DDD) !== SUNDAY &&
                moment(
                  `${yearDay}/${monthDay}/${dayOff}`,
                  dateFormat.YMD,
                ).format(dateFormat.DDD) !== SATURDAY
              ) {
                columDate[i][`date${dayOff}`] = checkOff(
                  arrColumnDate[j][i].request.type,
                );
              }
            });
          } else {
            if (columDate[i][`date${dateNow}`]) {
              columDate.push({});
              tableName.push({ name: uniqueMember });
              const formatTime =
                arrColumnDate[j][i].request.period / minutesInHour;
              columDate[i + 1][`date${dateNow}`] = checkStatusRequest(
                arrColumnDate[j][i].request.type,
                Number(formatTime.toFixed(2)),
              );
            } else {
              const formatTime =
                arrColumnDate[j][i].request.period / minutesInHour;
              columDate[i][`date${dateNow}`] = checkStatusRequest(
                arrColumnDate[j][i].request.type,
                Number(formatTime.toFixed(2)),
              );
            }
          }
        }
      }
    }
    tableDate.push(...columDate);
    const offDay = filterUniqueMember
      .filter((member) => member.request.type === TimesheetStatusType.DAY_OFF)
      .map((dayOff) => {
        const startDayOff = moment.unix(dayOff.request.isOff.start).date();
        const endDayOff = moment.unix(dayOff.request.isOff.end).date();
        const yearDay = moment.unix(dayOff.request.isOff.end).year();
        const monthDay = moment.unix(dayOff.request.isOff.end).month() + 1;
        const rangeDateOff = creatArrRange(startDayOff, endDayOff);
        let totalDate = rangeDateOff.range.length;
        rangeDateOff.range.forEach((dayOff) => {
          if (
            moment(`${yearDay}/${monthDay}/${dayOff}`, dateFormat.YMD).format(
              dateFormat.DDD,
            ) === SUNDAY ||
            moment(`${yearDay}/${monthDay}/${dayOff}`, dateFormat.YMD).format(
              dateFormat.DDD,
            ) === SATURDAY
          ) {
            totalDate -= 1;
          }
        });
        return totalDate;
      })
      .reduce((a, b) => a + b, 0);
    console.log('this is offDay', offDay);
    const offMorning = filterUniqueMember.filter((member) => {
      return member.request.type === TimesheetStatusType.MORNING_OFF;
    });
    const offNoon = filterUniqueMember.filter((member) => {
      return member.request.type === TimesheetStatusType.AFTERNOON_OFF;
    });
    const wfhDay = filterUniqueMember
      .filter(
        (member) => member.request.type === TimesheetStatusType.WORK_FROM_HOME,
      )
      .map((dayOff) => {
        const startDayOff = moment.unix(dayOff.request.isOff.start).date();
        const endDayOff = moment.unix(dayOff.request.isOff.end).date();
        const yearDay = moment.unix(dayOff.request.isOff.end).year();
        const monthDay = moment.unix(dayOff.request.isOff.end).month() + 1;
        const rangeDateOff = creatArrRange(startDayOff, endDayOff);
        let totalDate = rangeDateOff.range.length;
        rangeDateOff.range.forEach((dayOff) => {
          if (
            moment(`${yearDay}/${monthDay}/${dayOff}`, dateFormat.YMD).format(
              dateFormat.DDD,
            ) === SUNDAY ||
            moment(`${yearDay}/${monthDay}/${dayOff}`, dateFormat.YMD).format(
              dateFormat.DDD,
            ) === SATURDAY
          ) {
            totalDate -= 1;
          }
        });
        return totalDate;
      })
      .reduce((a, b) => a + b, 0);
    const wfhMorning = filterUniqueMember.filter((member) => {
      return member.request.type === TimesheetStatusType.WORK_FROM_HOME_MORNING;
    });
    const wfhNoon = filterUniqueMember.filter((member) => {
      return (
        member.request.type === TimesheetStatusType.WORK_FROM_HOME_AFTERNOON
      );
    });
    const requestBreak = filterUniqueMember
      .filter((member) => {
        return member.request.type === TimesheetStatusType.BREAK;
      })
      .map((breakDay) => breakDay.request.period / minutesInHour)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    const requestLate = filterUniqueMember
      .filter((member) => {
        return member.request.type === TimesheetStatusType.LATE;
      })
      .map((late) => late.request.period / minutesInHour)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    console.log('requestLate', requestLate);
    const requestEarly = filterUniqueMember
      .filter((member) => {
        return member.request.type === TimesheetStatusType.EARLY;
      })
      .map((early) => early.request.period / minutesInHour)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    const requestOt = filterUniqueMember
      .filter((member) => {
        return member.request.type === TimesheetStatusType.OT;
      })
      .map((ot) => {
        return ot.request.period / minutesInHour;
      })
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    console.log('requestOt', requestOt);
    const indexName = tableName.findIndex((i) => i.name === uniqueMember);
    tableName[indexName][`no`] = i + 1;
    tableName[indexName][`ot`] = requestOt;
    tableName[indexName][`off`] = Math.ceil(offDay);
    tableName[indexName][`wfh`] = Math.ceil(wfhDay);
    tableName[indexName][`offM`] = offMorning.length;
    tableName[indexName][`offN`] = offNoon.length;
    tableName[indexName][`wfhM`] = wfhMorning.length;
    tableName[indexName][`wfhN`] = wfhNoon.length;
    tableName[indexName][`break`] = requestBreak;
    tableName[indexName][`late`] = requestLate;
    tableName[indexName][`early`] = requestEarly;
  });
  return {
    member: tableName,
    type: tableDate,
  };
}

function checkStatusRequest(status: string, period: number | string = 0) {
  if (period === null) {
    period = '';
  }
  switch (status) {
    case TimesheetStatusType.LATE:
      return 'L ' + period;
    case TimesheetStatusType.OT:
      return 'OT ' + period;
    case TimesheetStatusType.BREAK:
      return 'B ' + period;
    case TimesheetStatusType.AFTERNOON_OFF:
      return 'N';
    case TimesheetStatusType.MORNING_OFF:
      return 'M';
    case TimesheetStatusType.WORK_FROM_HOME_AFTERNOON:
      return 'WFHN';
    case TimesheetStatusType.WORK_FROM_HOME_MORNING:
      return 'WFHM';
    case TimesheetStatusType.EARLY:
      return 'E ' + period;
    case TimesheetStatusType.DAY_OFF:
      return '';
    case TimesheetStatusType.WORK_FROM_HOME:
      return '';
    default:
      return status;
  }
}
function checkOff(status) {
  switch (status) {
    case TimesheetStatusType.DAY_OFF:
      return 'OFF';
    case TimesheetStatusType.WORK_FROM_HOME:
      return 'WFH';
    default:
      return status;
  }
}
function creatArrRange(
  x: number | null,
  y: number | null,
  totalDay = 31,
): { range: number[]; startDate?: number[]; endDate?: number[] } {
  if (x === null || y === null) return { range: [] };
  const startDate: number[] = [];
  const endDate: number[] = [];
  if (x > y) {
    for (let i = x; i <= totalDay; i++) {
      startDate.push(i);
    }
    for (let j = 1; j <= y; j++) {
      endDate.push(j);
    }
    return { range: startDate.concat(endDate), startDate, endDate };
  }
  return {
    range: Array.from(
      (function* () {
        while (x <= y) yield x++;
      })(),
    ),
  };
}

export default creatArrRange;

export function timeConvert(num: number | null): string {
  if (num === null || num === undefined) return '00:00';
  const hours = Math.floor(num) / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes =
    Math.round(minutes) < 10 ? `0${Math.round(minutes)}` : Math.round(minutes);
  const hourConvert = rhours < 10 ? `0${rhours}` : rhours;
  return hourConvert + ':' + rminutes;
}

export function getDateRange(startDate: number, endDate: number) {
  return `${moment(startDate * 1000)
    .format(dateFormat.DMY)
    .toString()} | ${moment(endDate * 1000)
    .format(dateFormat.DMY)
    .toString()}`;
}

export function getDateRangeMinutesSecond(startDate: number, endDate: number) {
  const string = `${moment(startDate * 1000)
    .format('HH:mm')
    .toString()} | ${moment(endDate * 1000)
    .format('HH:mm')
    .toString()}`;
  return string;
}

export function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

export enum OnService {
  Active = 'Active',
  InActive = 'Inactive',
  All = 'All Members',
}

export const filterMembersByOnService = (
  status: OnService,
  members: IMember[],
) => {
  switch (status) {
    case OnService.All:
      return members;
    case OnService.Active:
      return members.filter((member) => member.onService);
    case OnService.InActive:
      return members.filter((member) => !member.onService);
    default:
      return members;
  }
};

export const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const allowDatePicker = (e) => {
  // Allow only numbers, backspace, tab, enter, left arrow, right arrow, delete, dash (-)
  if (
    (e.keyCode < 48 || e.keyCode > 57) &&
    e.keyCode !== 8 &&
    e.keyCode !== 13 &&
    e.keyCode !== 37 &&
    e.keyCode !== 39 &&
    e.keyCode !== 96 &&
    e.keyCode !== 97 &&
    e.keyCode !== 98 &&
    e.keyCode !== 99 &&
    e.keyCode !== 100 &&
    e.keyCode !== 101 &&
    e.keyCode !== 102 &&
    e.keyCode !== 103 &&
    e.keyCode !== 104 &&
    e.keyCode !== 105 &&
    e.keyCode !== 109 &&
    e.keyCode !== 189
  ) {
    e.preventDefault();
  }
};

export const disabledBirthday = (current) => {
  // Can not select days before today
  return current && current > moment().endOf('day');
};

export const randStr = (prefix: string) => {
  return Math.random()
    .toString(36)
    .replace('0.', prefix || '');
};

export const convertMessageToObject = (messages: string[]) => {
  return messages.map((msg) => ({
    id: randStr('message_'),
    message: msg,
  }));
};
