import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Table } from 'antd';
import { IParamDate } from 'src/constants/ReportType';
import moment from 'moment';
import { getRankingReports } from 'src/services/report';
import { RoleTop } from './RoleTop';
import { removeAccents } from 'src/helpers';
import { MemberInfo } from 'src/components';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
import './TableRanking.less';
const { RangePicker } = DatePicker;
const { Option } = Select;

type ReportListProps = {
  listMember;
  listTeams: {
    value: string;
    text: string;
  }[];
};
const TableRaking: React.FC<ReportListProps> = (props: ReportListProps) => {
  const { listMember } = props;
  const [paramDate, setParamDate] = useState<IParamDate>({
    startTime: moment().startOf('month').unix(),
    endTime: moment().endOf('month').unix(),
    top: 5,
  });
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState<[]>([]);
  const [dataTableTotal, setDataTableTotal] = useState<[]>([]);
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await getRankingReports(paramDate);
        const { data: dataTable } = await getRankingReports(paramDate);
        if (data.data) {
          setLoading(false);
          setDataTable(handleDataNew(data.data));
          setDataTableTotal(handleDataNew(dataTable.data));
        }
      })();
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [paramDate]);

  const morningColums = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    { title: 'Total report', dataIndex: 'totalReport', key: 'totalReport' },
    { title: 'Daily report', dataIndex: 'count', key: 'count' },
    { title: 'Wrapup report', dataIndex: 'countWrapup', key: 'countWrapup' },
    {
      title: 'Ranking',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => {
        return a.rankPoint - b.rankPoint;
      },
    },
  ];
  const handleChangDate = (date) => {
    if (!date) return;
    const startDate = moment(date[0]).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date[1]).endOf('month').format('YYYY-MM-DD');
    const startDateUnix = moment(startDate).unix();
    const endDateUnix = moment(endDate).unix();
    setParamDate({
      ...paramDate,
      startTime: startDateUnix,
      endTime: endDateUnix,
    });
  };
  const handleChangeMember = async (valueMember: string) => {
    const dataFilter: any = dataTableTotal.filter(
      (dataRequest: any) => dataRequest.key === valueMember,
    );
    if (!valueMember) {
      setParamDate({ ...paramDate });
    } else {
      setDataTable(dataFilter);
    }
  };

  return (
    <div className="member-report-ranking">
      <SectionHeader
        className="member-report-ranking__header"
        sectionName="Report ranking"
      >
        <RangePicker
          defaultValue={[
            moment(
              moment.unix(paramDate.startTime).format('YYYY-MM-DD'),
              'YYYY/MM/DD',
            ),
            moment(
              moment.unix(paramDate.endTime).format('YYYY-MM-DD'),
              'YYYY/MM/DD',
            ),
          ]}
          picker="month"
          onChange={handleChangDate}
          clearIcon={false}
        />

        <Select
          showSearch
          allowClear
          placeholder="Select a member"
          optionFilterProp="children"
          onChange={handleChangeMember}
          filterOption={(input, option) => {
            if (option) {
              return (
                removeAccents(option.children.props.info.real_name)
                  .toLowerCase()
                  .indexOf(removeAccents(input).toLowerCase()) >= 0
              );
            }

            return true;
          }}
        >
          {listMember?.map((member) => (
            <Option key={member.userId} value={member.userId}>
              <MemberInfo info={member} />
            </Option>
          ))}
        </Select>
      </SectionHeader>
      <Table
        className="member-report-ranking__table"
        scroll={{ x: 600 }}
        loading={isLoading}
        columns={morningColums}
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          showSizeChanger: dataTable.length > 5,
        }}
        dataSource={dataTable}
      />
    </div>
  );
};
export default TableRaking;
const handleDataNew = (data) => {
  return data
    .sort((a, b) => {
      return b.count + b.countWrapUp - (a.count + a.countWrapUp);
    })
    .map((report, i) => {
      return {
        key: report.members.userId,
        rank: <RoleTop ranking={i + 1} />,
        name: <MemberInfo info={report.members} />,
        rankPoint: i + 1,
        totalReport: report.count + report.countWrapUp,
        count: report.count,
        countWrapup: report.countWrapUp,
        nameSearch: report.members.real_name,
      };
    });
};
