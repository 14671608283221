//import { IReportReminderConfig } from './workspace.interface';
//import { IResponse } from '../../types/response.interface';
import axiosClient from '../../configs/axios.configs';

export const getReminderWorkSpace = () => {
  return axiosClient.get('workspaces');
};
export const updateReminderWorkspace = (newWorkspaceConfigs) => {
  return axiosClient.patch('workspaces', newWorkspaceConfigs);
};
