import axios, { AxiosInstance } from 'axios';
import { isLogin } from 'src/helpers';
import { toast } from 'react-toastify';

const ADMIN_PATH = '/admin-manage/api';
const RESOURCE_PATH = '/resource/api';
const GOOGLE_PATH = '/google/api';

export const AdminAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + ADMIN_PATH,
  headers: { 'Content-Type': 'application/json' },
});

export const AdminUploadAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + ADMIN_PATH,
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const ResourceAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + RESOURCE_PATH,
  headers: { 'Content-Type': 'application/json' },
});

export const GoogleAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + GOOGLE_PATH,
  headers: { 'Content-Type': 'application/json' },
});

configAxios(AdminAPI);
configAxios(AdminUploadAPI);
configAxios(ResourceAPI);
configAxios(GoogleAPI);

function configAxios(instance: AxiosInstance) {
  instance.interceptors.request.use(async (config) => {
    if (isLogin()) {
      config.headers.token = localStorage.getItem('token');
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      // console.log(response.data);
      return response;
    },
    (error) => {
      // console.log('ERROR RES', error);
      if (!error.response) {
        // localStorage.removeItem('token');
        // window.location.href = '/error/500';
      }
      switch (error.response.status) {
        case 400:
          toast.error(
            error.response?.data?.errors?.message ||
              error.response?.data?.error?.messages ||
              'Something went wrong',
          );
          break;
        case 401:
          // localStorage.removeItem('token');
          // window.location.href = '/error/401';
          break;
        // case 403:
        //   window.location.href = '/error/403';
        //   break;
        case 500:
          window.location.href = '/error/500';
          break;
        default:
          // window.location.href = '/error';
          break;
      }

      return error;
    },
  );
}
