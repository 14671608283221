/* eslint-disable prettier/prettier */
import {
  CalendarOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ManageMember,
  ReportReminder,
  SetupTeam,
} from '../../components/TeamInfo';
import MainLayout from '../../layouts/MainLayout';
import * as notifyService from '../../services/reminder';
import * as TeamService from '../../services/team';
import {
  IChannel,
  IQuestionPack,
  ITeam,
} from '../../services/team/team.interface';
import * as workspaceService from '../../services/workspace';
import { teamNotify } from '../../constants';

const { TabPane } = Tabs;
const DetailTeam = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [detailTeams, setDetailTeams] = useState<ITeam>();
  const [listQuestionPacks, setListQuestionPacks] = useState<IQuestionPack[]>(
    [],
  );
  const [dateTime, setDateTime] = useState({
    time: '09:00',
    days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
  });

  const [listChannelsAvailable, setListChannelsAvailable] = useState<
    IChannel[]
  >([]);

  interface IParamTypes {
    id: string;
  }
  let { id } = useParams<IParamTypes>();
  const loadDetailTeam = useCallback(async () => {
    try {
      const { data } = await TeamService.getOneTeam(id);
      if (data.data) {
        setDetailTeams(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const loadQuestionPacks = async () => {
    try {
      const questionPacks = await TeamService.getListQuestionPacks();
      if (questionPacks.data.data) {
        setListQuestionPacks(questionPacks.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadWorkspaceConfig = async () => {
    try {
      const { data } = await workspaceService.getReminderWorkSpace();
      if (data.data) {
        const config = data.data.workspaceConfig;
        setDateTime({ time: config.time, days: config.days });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadReminder = useCallback(async () => {
    try {
      let { data } = await notifyService.getReminderTeam(id);
      //console.log(data);
      if (data.data) {
        setDateTime(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const loadChannelsAvailable = async () => {
    try {
      const channelsAvailable = await TeamService.getListChannelsAvailable();
      if (channelsAvailable.data.data) {
        setListChannelsAvailable(channelsAvailable.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reloadUpdateTeam = async () => {
    await loadDetailTeam();
    await loadChannelsAvailable();
    toast.success(teamNotify.update);
  };

  useEffect(() => {
    setIsLoading(true);
    ReactDOM.unstable_batchedUpdates(async () => {
      await Promise.all([
        loadDetailTeam(),
        loadWorkspaceConfig(),
        loadQuestionPacks(),
        loadChannelsAvailable(),
      ]);
      await loadReminder();
      setIsLoading(false);
    });
  }, [loadDetailTeam, loadReminder]);

  return (
    <MainLayout>
      {isLoading ? (
        <div className="wrap-container">
          <Spin />
        </div>
      ) : (
        <>
          <h3>{detailTeams?.name}</h3>

          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span>
                  <DatabaseOutlined />
                  Basic
                </span>
              }
              key="1"
            >
              <div style={{ maxWidth: 500, margin: 'auto' }}>
                <SetupTeam
                  listChannelsAvailable={listChannelsAvailable}
                  detailTeam={detailTeams}
                  listQuestionPacks={listQuestionPacks}
                  reload={reloadUpdateTeam}
                  type="UPDATE"
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <CalendarOutlined />
                  Setting
                </span>
              }
              key="2"
            >
              <div style={{ maxWidth: 500, margin: 'auto' }}>
                <ReportReminder
                  reminder={dateTime}
                  teamId={id}
                  onDetailTeam={true}
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <UsergroupAddOutlined />
                  Members
                </span>
              }
              key="3"
            >
              <ManageMember
                teamId={id}
                membersOnTeam={detailTeams?.members || []}
                reload={loadDetailTeam}
              />
            </TabPane>
          </Tabs>
        </>
      )}
    </MainLayout>
  );
};

export default DetailTeam;
