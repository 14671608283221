/* eslint-disable prettier/prettier */
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { MemberInfo } from 'src/components';
import { timesheetNotify } from 'src/constants/index';
import { removeAccents } from 'src/helpers';
import TimesheetStatusType from 'src/constants/TimesheetType';
import { IMember } from 'src/services/member';
import { createRequestTimesheet } from 'src/services/timesheet';

interface ICreateRequestType {
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setParam: any;
  param: any;
  listMember: IMember[];
}
const { RangePicker } = DatePicker;
const { RangePicker: RangePickTime } = TimePicker;
const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};
export const CreateNewRequest: React.FC<ICreateRequestType> = ({
  isShow,
  setShow,
  setParam,
  param,
  listMember,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [statusUpdate, setStatusUpdate] = useState<string>('MORNING OF');
  const [form] = Form.useForm();
  const handleCreateRequest = async (valueForm) => {
    const {
      rangePickerTime,
      rangePickHour,
      period,
      member,
      reason,
      createAt,
      type,
    } = valueForm;
    const startHour =
      rangePickHour === undefined ? null : moment(rangePickHour[0]._d);
    const endHour =
      rangePickHour === undefined ? null : moment(rangePickHour[1]._d);
    const total = moment
      .utc(
        moment(endHour, 'DD/MM/YYYY HH:mm').diff(
          moment(startHour, 'DD/MM/YYYY HH:mm'),
        ),
      )
      .format('HH:mm');
    let duration = moment.duration(total).asMinutes();
    // console.log(duration);
    const unixStartHour =
      rangePickHour === undefined ? null : moment(startHour).unix() * 1000;
    const unixEndHour =
      rangePickHour === undefined ? null : moment(endHour).unix() * 1000;
    const unixStartDate =
      rangePickerTime === undefined
        ? null
        : moment(rangePickerTime[0]._d).startOf('day').unix() * 1000;
    const unixEndDate =
      rangePickerTime === undefined
        ? null
        : moment(rangePickerTime[1]._d).endOf('day').unix() * 1000;
    const getPeriod =
      moment(new Date(period?._d)).hour() * 60 +
      moment(new Date(period?._d)).minutes();
    const totalDate = unixStartDate
      ? moment(
          moment(rangePickerTime[1]).format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).diff(
          moment(moment(rangePickerTime[0]).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
          'days',
        ) + 1
      : 0;
    let secondsPeriod = totalDate * 24 * 60 * 60;
    const periodDynamic = period === undefined ? secondsPeriod : getPeriod;
    if (
      periodDynamic === 0 &&
      statusUpdate !== TimesheetStatusType.MORNING_OFF &&
      statusUpdate !== TimesheetStatusType.AFTERNOON_OFF &&
      statusUpdate !== TimesheetStatusType.WORK_FROM_HOME_AFTERNOON &&
      statusUpdate !== TimesheetStatusType.WORK_FROM_HOME_MORNING &&
      duration === 0
    ) {
      toast.error(timesheetNotify.minDay);
      return;
    }
    const dataNewRequest =
      period === undefined
        ? statusUpdate === TimesheetStatusType.OT
          ? {
              timestamp: `${moment(createAt).unix()}`,
              member,
              request: {
                requestAt: `${moment(createAt).unix()}`,
                isOt: {
                  start: unixStartHour && unixStartHour / 1000,
                  end: unixEndHour && unixEndHour / 1000,
                },
                period: duration + 1,
                reason,
                type,
              },
            }
          : {
              timestamp: `${moment(createAt).unix()}`,
              member,
              request: {
                requestAt: `${moment(createAt).unix()}`,
                isOff: {
                  start: unixStartDate && unixStartDate / 1000,
                  end: unixEndDate && unixEndDate / 1000,
                },
                period: periodDynamic,
                reason,
                type,
              },
            }
        : {
            timestamp: `${moment(createAt).unix()}`,
            member,
            request: {
              requestAt: `${moment(createAt).unix()}`,
              period: periodDynamic,
              reason,
              type,
            },
          };
    // console.log(dataNewRequest)
    setLoading(true);
    try {
      const { data } = await createRequestTimesheet(dataNewRequest);
      if (data) {
        setLoading(false);
        toast.success(timesheetNotify.create);
        setShow(false);
        form.resetFields();
        setTimeout(() => {
          setParam({ ...param });
        }, 300);
      }
    } catch (error) {
      setLoading(false);
      toast.error(timesheetNotify.error);
      throw error;
    }
  };
  const handleStatusUpdate = (status: string) => {
    switch (status) {
      case TimesheetStatusType.DAY_OFF:
        return (
          <div className="form-control-date">
            <Form.Item
              name="rangePickerTime"
              label="Time"
              rules={[{ required: true }]}
            >
              <RangePicker />
            </Form.Item>
          </div>
        );
      case TimesheetStatusType.WORK_FROM_HOME:
        return (
          <div className="form-control-date">
            <Form.Item
              name="rangePickerTime"
              label="Time"
              rules={[{ required: true }]}
            >
              <RangePicker />
            </Form.Item>
          </div>
        );
      case TimesheetStatusType.OT:
        return (
          <div className="form-control-date">
            <Form.Item
              name="rangePickHour"
              label="Time"
              rules={[{ required: true }]}
            >
              <RangePickTime format="HH:mm" />
            </Form.Item>
          </div>
        );
      case TimesheetStatusType.MORNING_OFF:
        break;
      case TimesheetStatusType.AFTERNOON_OFF:
        break;
      case TimesheetStatusType.WORK_FROM_HOME_MORNING:
        break;
      case TimesheetStatusType.WORK_FROM_HOME_AFTERNOON:
        break;
      default:
        return (
          <div className="period-time-picker">
            <Form.Item rules={[{ required: true }]} name="period" label="Total">
              <TimePicker />
            </Form.Item>
          </div>
        );
    }
  };
  return (
    <Modal
      title={`Create new request`}
      visible={isShow}
      onCancel={() => setShow(false)}
      footer={null}
    >
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={handleCreateRequest}
      >
        <Form.Item rules={[{ required: true }]} name="member" label="Member">
          <Select
            className="member-create"
            showArrow
            showSearch
            placeholder="Select a member"
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option) {
                return (
                  removeAccents(option.children.props.info.real_name)
                    .toLowerCase()
                    .indexOf(removeAccents(input).toLowerCase()) >= 0
                );
              }

              return true;
            }}
            allowClear
          >
            {listMember?.map((member, i) => (
              <Option key={i} value={member.userId}>
                <MemberInfo info={member} />
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select
            placeholder="Select type"
            onChange={(value: string) => setStatusUpdate(value)}
          >
            <Select.Option value={TimesheetStatusType.EARLY}>
              {TimesheetStatusType.EARLY}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.LATE}>
              {TimesheetStatusType.LATE}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.BREAK}>
              {TimesheetStatusType.BREAK}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.AFTERNOON_OFF}>
              {TimesheetStatusType.AFTERNOON_OFF}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.MORNING_OFF}>
              {TimesheetStatusType.MORNING_OFF}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.DAY_OFF}>
              DAY OFF
            </Select.Option>
            <Select.Option value={TimesheetStatusType.WORK_FROM_HOME_AFTERNOON}>
              {TimesheetStatusType.WORK_FROM_HOME_AFTERNOON}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.WORK_FROM_HOME_MORNING}>
              {TimesheetStatusType.WORK_FROM_HOME_MORNING}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.WORK_FROM_HOME}>
              {TimesheetStatusType.WORK_FROM_HOME}
            </Select.Option>
            <Select.Option value={TimesheetStatusType.OT}>
              {TimesheetStatusType.OT}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="reason" label="Reason">
          <Input.TextArea placeholder="Enter reason" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="createAt"
          label="Apply On"
        >
          <DatePicker />
        </Form.Item>
        {handleStatusUpdate(statusUpdate)}
        <div className="button-update">
          <Form.Item>
            <Button type="primary" ghost onClick={() => setShow(false)}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
