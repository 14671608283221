import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalProps, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { addDepartment } from 'src/services/departments/department.services';
import { getListChannelsAll, IChannel } from 'src/services/team';
import { IDepartmentAddData } from 'src/services/departments';

interface AddDepartmentModalProps extends ModalProps {
  handleCloseAfterCreate: () => void;
}

export default function AddDepartmentModal({
  handleCloseAfterCreate,
  ...rest
}: AddDepartmentModalProps) {
  const [form] = Form.useForm();
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [loadChannels, setLoadChannels] = useState(false);
  const [loadAddDepartment, setLoadAddDepartment] = useState(false);

  async function getChannels() {
    try {
      setLoadChannels(true);
      const res = await getListChannelsAll();
      if (res.data.data) {
        setChannels(res.data.data);
      }
    } catch (error) {
      toast.error('Get list channels failed');
    } finally {
      setLoadChannels(false);
    }
  }

  async function handleAddDepartment(department: IDepartmentAddData) {
    try {
      setLoadAddDepartment(true);
      const res = await addDepartment(department);
      if (res?.data?.data) {
        toast.success('Department created successfully');
        handleCloseAfterCreate();
      }
      if (res instanceof Error) {
        let error = res as unknown as AxiosError;
        toast.error(error?.response?.data?.errors?.message);
      }
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoadAddDepartment(false);
    }
  }

  useEffect(() => {
    getChannels();
  }, []);

  return (
    <Modal {...rest}>
      <React.Fragment>
        <Form
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 12 }}
          onFinish={(values) =>
            handleAddDepartment({
              name: values.departmentName.trim(),
              description: values.departmentDescription
                ? values.departmentDescription.trim()
                : '',
              spaceIdDefault: values.channel,
            })
          }
          form={form}
        >
          <Form.Item
            name="departmentName"
            label="Department name"
            rules={[
              {
                type: 'string',
                required: true,
                message: 'Department name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="departmentDescription"
            label="Department description"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="channel"
            label="Channel / Space"
            tooltip={{
              title:
                'You have to add VMOBOT to the space/channel that you want to select',
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                type: 'string',
                required: true,
                message: 'Channel / Space is required',
              },
            ]}
          >
            <Select
              options={channels.map((channel) => ({
                value: channel.channelId,
                label: channel.channelName,
              }))}
              allowClear={true}
              loading={loadChannels}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 9, span: 12 }}>
            <div className="vbot-text-right">
              <Button
                loading={loadAddDepartment}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </React.Fragment>
    </Modal>
  );
}
