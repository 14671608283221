import React, { useState, useContext } from 'react';
import { Layout, Avatar, Row } from 'antd';
import { Menu, Dropdown } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import './style.less';
import { logout } from '../services/user';
import { useHistory } from 'react-router-dom';
import {
  LoginOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { AuthContext } from '../contexts/Auth';
import LogoVmo from '../assets/images/Logo2023.png';
import { Help } from './Help/Help';

const { Header } = Layout;

export const HeaderBar: React.FC = () => {
  const { user } = useContext(AuthContext);
  const curUser = user ? user : { avatar: '', real_name: 'user name' };
  const [visible, setVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const history = useHistory();

  const handleMenuClick = (e: any) => {
    if (e.key === '1') {
      setVisible(false);
      history.push('/profile');
    }
    if (e.key === '2') {
      logout();
      setVisible(false);
      history.push('/login');
    }
    if (e.key === '3') {
      setVisible(false);
      setModalVisible(true);
    }
    if (e.key === '4') {
      history.push('/payment');
    }
  };
  const handleModalVisibleChange = () => {
    setModalVisible(false);
  };
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <InfoCircleOutlined />
        Profile
      </Menu.Item>
      <Menu.Item key="4">
        <UpCircleOutlined />
        Upgrade plan
      </Menu.Item>
      <Menu.Item key="3">
        <QuestionCircleOutlined />
        Help
      </Menu.Item>
      <Menu.Item key="2">
        <LoginOutlined />
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Header
      className="site-layout-background" //  style={{ padding: 0, position: 'sticky', zIndex: 1, width: '100%' }}
    >
      <div className="header-bar">
        <img
          className="logo-vmo"
          src={LogoVmo}
          alt="logo vmodev"
          onClick={() => {
            history.push('/');
          }}
        />
        <Row>
          <div className="info-plan">
            <span>Free plan</span>
          </div>
          <div className="info-user">
            <Avatar
              src={curUser.avatar}
              size={32}
              icon={<UserOutlined />}
              style={{ marginRight: 8 }}
            />
            <Dropdown
              overlay={menu}
              onVisibleChange={handleVisibleChange}
              visible={visible}
            >
              <a
                className="ant-dropdown-link"
                href="/"
                onClick={(e) => e.preventDefault()}
              >
                <span className="ant-dropdown-link__member-name">
                  {curUser.real_name}
                  {` `}
                </span>
                <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </Row>
      </div>
      <div>
        <Help
          modalVisible={modalVisible}
          handleModalVisibleChange={handleModalVisibleChange}
        />
      </div>
    </Header>
  );
};
