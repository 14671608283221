import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  IChannel,
  IQuestionPack,
  ITeam,
} from '../../../services/team/team.interface';
import { SetupTeam } from '../../../components/TeamInfo';

type ChangeModalProps = {
  title: string;
  detailTeam?: Partial<ITeam>;
  BtnContent: React.ReactNode;
  listChannels: IChannel[];
  listQuestionPacks: IQuestionPack[];
  dateTimeConfig?: { time: string; days: string[] };
  type: 'ADD' | 'UPDATE';
  reload: () => Promise<void>;
  listChannelsAvailable?: IChannel[];
};

export const ChangeTeamModal: React.FC<ChangeModalProps> = (
  props: ChangeModalProps,
) => {
  const {
    title,
    BtnContent,
    detailTeam,
    listChannels,
    listQuestionPacks,
    dateTimeConfig,
    reload,
    type,
    listChannelsAvailable,
  } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          showModal();
        }}
      >
        {BtnContent}
      </div>

      <Modal
        visible={visible}
        title={title || 'Title'}
        onCancel={handleCancel}
        footer={null}
      >
        {visible && (
          <SetupTeam
            detailTeam={detailTeam}
            listQuestionPacks={listQuestionPacks}
            reload={reload}
            type={type}
            listChannels={listChannels}
            handleClose={handleCancel}
            dateTimeConfig={dateTimeConfig}
            listChannelsAvailable={listChannelsAvailable}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ChangeTeamModal;
