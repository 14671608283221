/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ApiTwoTone,
  BulbTwoTone,
  ExperimentTwoTone,
  LoginOutlined,
  RocketTwoTone,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Modal, Button, Col, Row, Skeleton, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import TickIcon from 'src/assets/images/tick.png';
import {
  getInfoPackage,
  updatePackage,
} from 'src/services/payment/payment.services';
import './style.less';
const { confirm } = Modal;
const Payment: React.FC = () => {
  const [monthlyPacks, setMonthlyPacks] = useState([]);
  const [anuallyPacks, setAnuallyPacks] = useState([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const arrIcon = [
    <BulbTwoTone twoToneColor="#ff7200" />,
    <ApiTwoTone twoToneColor="#ff7200" />,
    <ExperimentTwoTone twoToneColor="#ff7200" />,
    <RocketTwoTone twoToneColor="#ff7200" />,
    <ApiTwoTone twoToneColor="#ff7200" />,
  ];
  let pricingPacks;
  const [packSwitching, setPackSwtiching] = useState(true);
  const history = useHistory();
  pricingPacks = packSwitching ? monthlyPacks : anuallyPacks;
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await getInfoPackage();
        if (data) {
          setMonthlyPacks(
            data.data.filter((pack) => pack.interval === 'month'),
          );
          setAnuallyPacks(data.data.filter((pack) => pack.interval === 'year'));
          setLoading(false);
        }
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  function showConfirm(id, packageInfo) {
    confirm({
      title: `Are you sure to upgrade package ${packageInfo}?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const { data } = await updatePackage(id);
        const { error } = data.data.data[0].message;
        const { message } = data.data.data[0].message;
        if (error) {
          toast.error(message);
          return;
        }
        if (data.data.data[0].message === false) {
          toast.error('Cannot *Upgrade* because this is the current package');
          return;
        }
        if (
          data.data.data[0].message ===
          'Please cancel your current plan and register for the new one'
        ) {
          toast.error(data.data.data[0].message);
          return;
        }
        if (data.data.data[0].message === 'PaymentMethod is missing') {
          toast.error(
            `${data.data.data[0].message} . Please update *Payment method* before update package!`,
          );
          return;
        }
        toast.success('Upgrade package success!');
        setTimeout(() => {
          history.push('/payments');
        }, 1000);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  return (
    <div className="root">
      <span className="bg-root" />
      <div className="container">
        <Row className="titles" justify="center">
          <div
            className="close-button"
            onClick={() => history.push('/payments')}
          >
            <LoginOutlined />
          </div>
          <h2>Choose the plan that suits you.</h2>
          <p>
            Add super powers to DailyBot, get the best option based on your
            needs.✨.
          </p>
        </Row>
        <Row className="price">
          <Switch
            onChange={() => setPackSwtiching(!packSwitching)}
            checkedChildren="Monthly"
            unCheckedChildren="Anually"
            defaultChecked
          />
        </Row>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Row className="packcontainer" justify="center">
            {pricingPacks.map((pack, i) => {
              const isDisplayButton = () => {
                if (pack.name === 'Standard' || pack.name === 'Premium') {
                  return (
                    <Button
                      onClick={() => showConfirm(pack._id, pack.name)}
                      type="primary"
                      size={'large'}
                    >
                      Upgrade
                    </Button>
                  );
                } else if (pack.name === 'Enterprise') {
                  return (
                    <Button type="primary" size={'large'} ghost>
                      <a>Contact us</a>
                    </Button>
                  );
                }
              };
              return (
                <Col key={i} className="info-plan" span={6}>
                  <div className="info-price">
                    <h5>{pack.name}</h5>
                    <div className="icon-price">{arrIcon[i]}</div>
                    <div className="header-price">
                      <span className="price-simbol">
                        {pack.prices.value === -1 ? 'FOR BIG TEAM' : `$`}
                      </span>
                      <span className="price-value">
                        {pack.prices.value === -1 ? '' : `${pack.prices.value}`}
                      </span>
                      <span className="price-indicator">
                        {pack.prices.value === -1 ? '' : `/mo`}
                      </span>
                    </div>
                  </div>
                  <div className="info-content">
                    {shorten(pack.description).map((des, i) => {
                      return (
                        <p key={i}>
                          <img src={TickIcon} alt="" />
                          {des}
                        </p>
                      );
                    })}
                  </div>
                  {isDisplayButton()}
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </div>
  );
};

export default Payment;

function shorten(text = '') {
  return text.split('\n');
}
