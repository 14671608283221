import { Button, Modal, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { DuplicateMemberTable } from './duplicateMember';
import { importMembers } from 'src/services/member';
import { toast } from 'react-toastify';
import { useMemberContext } from 'src/contexts/MemberContext';
import { UploadProps } from 'antd/lib/upload';
import * as memberService from 'src/services/member';
import { UserRole } from 'src/constants/Roles';
import { AuthContext } from 'src/contexts/Auth';

const ModalImport = () => {
  const { user } = useContext(AuthContext);
  const {
    modalImportInfo,
    setModalImportInfo,
    departments,
    fetchFilterMembers,
  } = useMemberContext();
  const { fileSelected, isUploading, isUploadSuccess, listDuplicated } =
    modalImportInfo;

  const resetModalImportInfo = () => {
    setModalImportInfo({
      ...modalImportInfo,
      fileSelected: null,
      isUploading: false,
      isUploadSuccess: false,
      listDuplicated: [],
      departmentId: '',
    });
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    showUploadList: { showRemoveIcon: !isUploadSuccess },
    beforeUpload: (fileSelected) => {
      setModalImportInfo({ ...modalImportInfo, fileSelected });
      return false;
    },
    onRemove: resetModalImportInfo,
  };

  const handleUpload = async () => {
    setModalImportInfo({ ...modalImportInfo, isUploading: true });
    const res = await importMembers(fileSelected, modalImportInfo.departmentId);
    if (res.status === 200) {
      const duplicatedMembers = res.data.data;
      if (!duplicatedMembers) {
        return;
      }
      if (!duplicatedMembers.length) {
        toast.success('Imported success');
        await memberService.syncMembersAdmin();
        fetchFilterMembers();
        setModalImportInfo({ ...modalImportInfo, showModal: false });
        return;
      }

      const modifyDuplicatedMembers = duplicatedMembers.map((item) => ({
        ...item,
        selectedEmail: '',
      }));
      setModalImportInfo({
        ...modalImportInfo,
        isUploading: false,
        isUploadSuccess: true,
        listDuplicated: modifyDuplicatedMembers,
      });
    }
  };

  const createDepartmentOptions = () =>
    departments.map((d) => ({
      key: d._id,
      label: d.department_name,
      value: d._id,
    }));

  return (
    <Modal
      title="Import members"
      visible={modalImportInfo.showModal}
      onCancel={() =>
        setModalImportInfo({ ...modalImportInfo, showModal: false })
      }
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <a href="/template-import-members.xlsx" download>
        <i>Download template</i>
      </a>
      <div className="vbot-relative" style={{ margin: '0.5rem 0 1rem' }}>
        <Upload {...uploadProps}>
          <Button
            icon={<UploadOutlined />}
            disabled={fileSelected || isUploadSuccess}
          >
            Select Excel File
          </Button>
        </Upload>
        <div className="vbot-absolute" style={{ top: 0, left: 160 }}>
          {user.role === UserRole.WORKSPACE_ADMIN && (
            <Select
              placeholder="Select a department"
              allowClear={!isUploadSuccess}
              style={{ width: 170, marginRight: 10 }}
              onChange={(departmentId: string) =>
                setModalImportInfo({ ...modalImportInfo, departmentId })
              }
              options={createDepartmentOptions()}
              onClear={resetModalImportInfo}
            />
          )}
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={
              !fileSelected || !modalImportInfo.departmentId || isUploadSuccess
            }
            loading={isUploading}
          >
            {isUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div>
      </div>
      {listDuplicated.length > 0 && <DuplicateMemberTable />}
    </Modal>
  );
};

export default ModalImport;
