import React from 'react';
import classNames from 'classnames';
import '../style.less';
type RoleMemberProps = {
  ranking: number;
};
export const RoleTop: React.FC<RoleMemberProps> = (props) => {
  const { ranking } = props;
  return (
    <div className="warp-role-member">
      <div
        className={classNames(
          'flag',
          { 'top-one': ranking === 1 },
          { 'top-two': ranking === 2 },
          { 'top-three': ranking === 3 },
        )}
      />
      <span> {ranking} </span>
    </div>
  );
};
