enum ReminderType {
  ONETIME = 'oneTime',
  DAY = 'day',
  WORK_DAYS = 'working_days', // Mon - Fri
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export default ReminderType;
