import axiosClient from 'src/configs/axios.configs';
import { IResponse } from 'src/types/response.interface';
export const updateEmailPackage = (email) => {
  return axiosClient.post<IResponse<boolean>>('payment/email', { email });
};
export const updateCardPackage = (infoCard) => {
  return axiosClient.post('payment/paymentMethod', {
    paymentMethod: infoCard,
  });
};
export const updateWorkspaceFeature = (dataWorkspace) => {
  return axiosClient.post<IResponse<boolean>>('workspaces', dataWorkspace);
};
