import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as TeamService from '../../services/team/team.services';
import {
  IChannel,
  IQuestionPack,
  ITeam,
} from '../../services/team/team.interface';

const { Option } = Select;
type ChangeModalProps = {
  detailTeam?: Partial<ITeam>;
  listChannels?: IChannel[];
  listQuestionPacks: IQuestionPack[];
  type: 'ADD' | 'UPDATE';
  reload: () => Promise<void>;
  handleClose?: () => void;
  dateTimeConfig?: { time: string; days: string[] };
  listChannelsAvailable?: IChannel[];
};

export const SetupTeam: React.FC<ChangeModalProps> = (
  props: ChangeModalProps,
) => {
  const {
    detailTeam,
    listQuestionPacks,
    reload,
    type,
    handleClose,
    dateTimeConfig,
    listChannelsAvailable,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const tailLayout = {
    wrapperCol: { offset: 18, span: 4 },
  };

  const handleAdd = async (values) => {
    let newTeam = {
      channelReportId: values.channel.value,
      channelReportName: values.channel.label,
      name: values.name,
      questionPack: values.questionPack,
    };

    const res = await TeamService.createTeam({
      newTeam,
      dateTime: dateTimeConfig,
    });
    if (res.data) {
      await reload();
      setConfirmLoading(false);
      setDisableSubmit(true);
      if (handleClose) {
        handleClose();
      }
    }
    form.resetFields();
  };
  const handleUpdate = async (values) => {
    const res = await TeamService.updateTeam({
      ...values,
      _id: detailTeam?._id ? detailTeam._id : '',
    });
    if (res) {
      await reload();
      setConfirmLoading(false);
      setDisableSubmit(true);
      if (handleClose) {
        handleClose();
      }
    }
  };

  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      switch (type) {
        case 'ADD':
          handleAdd(values);
          break;
        case 'UPDATE':
          handleUpdate(values);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onValuesChange = () => {
    setDisableSubmit(false);
  };

  useEffect(() => {
    if (detailTeam && type === 'UPDATE') {
      form.setFieldsValue({
        name: detailTeam.name,
        questionPack: detailTeam.questionPack,
        channel: detailTeam.channelReportName,
      });
    }
    return () => {
      form.resetFields();
    };
  }, [form, detailTeam, type]);

  return (
    <Form
      {...layout}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      onValuesChange={onValuesChange}
      initialValues={{ channelId: detailTeam?.channelReportId }}
    >
      <Form.Item
        label="Name"
        name="name"
        hasFeedback={type === 'ADD' ? true : false}
        rules={[
          {
            required: true,
            message: "Please input your team's name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      {type === 'ADD' ? (
        <Form.Item
          name="channel"
          label="Channel / Space"
          tooltip={{
            title:
              'You have to add VMOBOT to the space / channel that you want to select',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Please select a channel / space!',
            },
          ]}
          hasFeedback
          validateFirst={true}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            disabled={listChannelsAvailable?.length === 0 ? true : false}
            labelInValue
            placeholder={`${
              listChannelsAvailable?.length === 0
                ? 'No channel / space available!'
                : 'Select a channel / space'
            }`}
            allowClear
          >
            {listChannelsAvailable?.map((item: IChannel) => (
              <Option key={item.channelId} value={item.channelId}>
                {item.channelName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          name="channelId"
          label="Channel"
          rules={[
            {
              required: true,
              message: 'Please select a channel / space!',
            },
          ]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            placeholder="Select a channel / space"
            allowClear
          >
            <Option
              key={detailTeam?.channelReportId}
              value={detailTeam?.channelReportId || ''}
            >
              {detailTeam?.channelReportName}
            </Option>

            {listChannelsAvailable?.map((item: IChannel) => {
              return (
                <Option key={item.channelId} value={item.channelId}>
                  {item.channelName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="questionPack"
        label="Question Pack"
        hasFeedback={type === 'ADD' ? true : false}
        rules={[
          {
            required: true,
            message: 'Please select a question pack!',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          placeholder="Select a question pack"
          allowClear
        >
          {listQuestionPacks.map((item: IQuestionPack) => (
            <Option value={item._id} key={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          loading={confirmLoading}
          disabled={disableSubmit}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
