import { Input } from 'antd';
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';

interface DepartmentFilterBarProps {
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
}

export default function DepartmentFilterBar({
  searchTerm,
  setSearchTerm,
}: DepartmentFilterBarProps) {
  return (
    <SectionHeader sectionName="Filter departments">
      <Input
        placeholder="Search for departments..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        prefix={<SearchOutlined />}
      />
    </SectionHeader>
  );
}
