import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from 'src/contexts/Auth';
import MainLayout from 'src/layouts/MainLayout';
import Popup from './components/DisplayPopup';
import './style.less';

const Home = () => {
  const { user } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);

  const isShowPopup = useMemo(() => {
    if (user && Object.keys(user)?.length !== 0) {
      return !user.birthday || !user.phone;
    }
    return false;
  }, [user]);

  useEffect(() => {
    setShowPopup(isShowPopup);
  }, [isShowPopup, user]);

  return (
    <MainLayout>
      <>
        {showPopup && (
          <Popup visible={showPopup} onCancel={() => setShowPopup(false)} />
        )}
      </>
      <div className="home-page">
        <h2>
          Welcome to VMO BOT!{' '}
          <span className="ready-text"> Ready to get started?</span>
        </h2>
        <p>Set daily activities to auto - it takes less than a minute.</p>
      </div>
    </MainLayout>
  );
};

export default Home;
