import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import axios from 'axios';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import GGchatLogo from 'src/assets/images/gg.png';
import { AuthContext } from 'src/contexts/Auth';
import { useGoogleLogin } from '@react-oauth/google';

const { confirm } = Modal;

const LoginWithGoogle = () => {
  const history = useHistory();
  const { setUser } = useContext(AuthContext);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse: any) => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_GOOGLE_API_URL}/signInWithGoogle`,
        { code: codeResponse.code },
      );
      if (data.token) {
        window.localStorage.setItem('token', data.token);
        setUser(data.data);
        setTimeout(() => {
          history.push('/');
        }, 2000);
      }
      if (data.ok === false) {
        confirm({
          title:
            'Oops 🤔 ! Looks like you haven"t added VMOBOT to your google workspace yet.',
          icon: <ExclamationCircleOutlined />,
          content: "Let's get started!",
          onOk() {
            history.push('/workspace-config/google', {
              accountType: codeResponse.hd,
            });
          },
        });
      }
    },
  });

  return (
    <Button
      type="default"
      shape="round"
      size="large"
      className="login-form-button"
      onClick={() => login()}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          style={{ marginRight: 5 }}
          src={GGchatLogo}
          alt="google-chat-logo"
          height="20"
        />{' '}
        <span>
          Log in with <b>Google</b>
        </span>
      </div>
    </Button>
  );
};

export default LoginWithGoogle;
