import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const BreadCrumbLink: React.FC = () => {
  const location = useLocation();
  const breadcrumbNameMap = {
    '/teams': 'Teams',
    '/members': 'Members',
    '/question-packs': 'Question Packs',
    '/workspace': 'Workspace',
    '/reminders': 'Reminders',
    '/profile': 'Profile',
    '/reports': 'Report',
    '/timesheet': 'Timesheet',
    '/payments': 'Payment',
    '/departments': 'Departments',
    '/contact': 'Contact',
  };
  let pathSnippets = location?.pathname.split('/').filter((i) => i);
  //pathSnippets = pathSnippets.length < 2 ? pathSnippets : pathSnippets.pop();
  if (pathSnippets?.length >= 2) {
    pathSnippets.pop();
  }
  const extraBreadcrumbItems = pathSnippets?.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{index === 0 && breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>{breadcrumbItems}</Breadcrumb>
  );
};
export default BreadCrumbLink;
