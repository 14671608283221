import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SectionHeader from 'src/components/SectionHeader/SectionHeader';
import { teamNotify } from '../../constants';
import MainLayout from '../../layouts/MainLayout';
import * as TeamService from '../../services/team';
import {
  IChannel,
  IQuestionPack,
  ITeam,
} from '../../services/team/team.interface';
import * as workspaceService from '../../services/workspace';
import {
  ChangeTeamModal,
  ManageMemberModal,
  ScheduleModal,
} from './components';
import './style.less';
import DeleteIcon from 'src/components/Icons/Delete';
import EditIcon from 'src/components/Icons/Edit';
import CalendarIcon from 'src/components/Icons/Calendar';
import UsergroupAddIcon from 'src/components/Icons/UsergroupAdd';
const { confirm } = Modal;

export const TeamPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listTeams, setListTeams] = useState<ITeam[]>([]);
  const [listChannels, setListChannels] = useState<IChannel[]>([]);
  const [listQuestionPacks, setListQuestionPacks] = useState<IQuestionPack[]>(
    [],
  );
  const [listChannelsAvailable, setListChannelsAvailable] = useState<
    IChannel[]
  >([]);

  const [dateTime, setDateTime] = useState({
    time: '09:00',
    days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
  });

  useEffect(() => {
    setIsLoading(true);
    ReactDOM.unstable_batchedUpdates(async () => {
      await Promise.all([
        loadListTeam(),
        loadQuestionPacks(),
        loadChannels(),
        loadWorkspaceConfig(),
        loadChannelsAvailable(),
      ]);
      setIsLoading(false);
    });
  }, []);
  const loadListTeam = async () => {
    try {
      const listData = await TeamService.getListTeam();
      if (listData.data.data) {
        setListTeams(
          listData.data.data.map((item: ITeam) => {
            return { ...item, key: item._id };
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadChannels = async () => {
    try {
      const channels = await TeamService.getListChannels();
      if (channels.data.data) {
        setListChannels(channels.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadQuestionPacks = async () => {
    try {
      const questionPacks = await TeamService.getListQuestionPacks();
      if (questionPacks.data.data) {
        setListQuestionPacks(questionPacks.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadWorkspaceConfig = async () => {
    const { data } = await workspaceService.getReminderWorkSpace();
    if (data.data) {
      const config = data.data.workspaceConfig;
      setDateTime({ time: config.time, days: config.days });
    }
  };

  const loadChannelsAvailable = async () => {
    try {
      const channelsAvailable = await TeamService.getListChannelsAvailable();
      if (channelsAvailable.data.data) {
        setListChannelsAvailable(channelsAvailable.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reloadCreateTeam = async () => {
    await loadListTeam();
    await loadChannelsAvailable();
    toast.success(teamNotify.create);
  };
  const reloadUpdateTeam = async () => {
    await loadListTeam();
    await loadChannelsAvailable();
    toast.success(teamNotify.update);
  };

  const showDeleteConfirm = (record: ITeam) => {
    confirm({
      title: 'Are you sure to delete this team?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        //  console.log('OK', record);
        try {
          let res = await TeamService.deleteTeam(record._id);
          if (res) {
            await loadListTeam();
            await loadChannelsAvailable();
            toast.success(teamNotify.delete);
          }
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        // console.log('Cancel', record);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      defaultSortOrder: 'ascend' as 'ascend',
      //sorter: (a, b) => a.name - b.name,
      sorter: {
        compare: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
          return 0;
        },
        multiple: 3,
      },
      render: (text: string, record) => (
        <Link to={`/teams/${record._id}`}>{text}</Link>
      ),
    },
    {
      title: 'Channel / Space',
      dataIndex: 'channelReportName',
      key: 'channelReportName',
      width: '20%',
      // sorter: {
      //   compare: (a, b) => a.channelReportName - b.channelReportName,
      //   multiple: 2,
      // },
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Total Member',
      dataIndex: 'totalMember',
      key: 'totalMember',
      width: '20%',
      render: (text: string, record) => {
        return <p>{record.members.length}</p>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right' as 'right',
      render: (_: any, record: ITeam) => (
        <Space key={record._id} size="small">
          <ManageMemberModal
            title={`${record.name}`}
            membersOnTeam={record.members}
            teamId={record._id}
            BtnContent={<UsergroupAddIcon tooltip="Manage members" />}
            reload={loadListTeam}
          />
          <ChangeTeamModal
            title="Update Team"
            type="UPDATE"
            BtnContent={<EditIcon tooltip="Edit" />}
            detailTeam={record}
            listChannels={listChannels}
            listQuestionPacks={listQuestionPacks}
            listChannelsAvailable={listChannelsAvailable}
            reload={reloadUpdateTeam}
          />
          <ScheduleModal
            title={`${record.name}`}
            teamId={record._id}
            BtnContent={
              record.notifyStatus ? (
                <CalendarIcon tooltip="On Reminder" />
              ) : (
                <CalendarIcon
                  tooltip="Off Reminder"
                  style={{ color: '#aaaaaa' }}
                />
              )
            }
            reload={loadListTeam}
          />
          <DeleteIcon
            tooltip="Delete"
            onClick={() => showDeleteConfirm(record)}
          />
        </Space>
      ),
    },
  ];
  return (
    <MainLayout>
      <PageHeader headerName="Teams">
        <ChangeTeamModal
          title="Add new team"
          type="ADD"
          BtnContent={
            <Button type="primary" icon={<PlusOutlined />}>
              Create new team
            </Button>
          }
          listChannels={listChannels}
          listQuestionPacks={listQuestionPacks}
          reload={reloadCreateTeam}
          dateTimeConfig={dateTime}
          listChannelsAvailable={listChannelsAvailable}
        />
      </PageHeader>
      <Divider />
      <div className="team-list">
        <SectionHeader
          className="team-list__header"
          sectionName="List of teams"
        />
        <Table
          className="team-list__table"
          scroll={{ x: 600 }}
          columns={columns}
          dataSource={listTeams}
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};
