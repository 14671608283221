import React from 'react';
import './PageHeader.less';

interface PageHeaderProps {
  headerName: string;
  children?: React.ReactNode;
  className?: string;
}

const PageHeader = ({ headerName, children, className }: PageHeaderProps) => {
  return (
    <div className={`page-header${className ? ` ${className}` : ''}`}>
      <h3 className="page-header__name">{headerName}</h3>
      <div className="page-header__content">{children}</div>
    </div>
  );
};

export default PageHeader;
