import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { ManageMember } from '../../../components/TeamInfo';

type ManageMemberProps = {
  title: string;
  BtnContent: React.ReactNode;
  teamId: string;
  membersOnTeam: { id: string; active: boolean }[];
  reload: () => Promise<void>;
};

export const ManageMemberModal: React.FC<ManageMemberProps> = (
  props: ManageMemberProps,
) => {
  const { title, BtnContent, teamId, membersOnTeam, reload } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const showModal = async () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Button type="link" onClick={showModal} icon={BtnContent} />
      {visible && (
        <Modal
          visible={visible}
          title={title || 'Title'}
          onCancel={handleCancel}
          footer={null}
        >
          <ManageMember
            teamId={teamId}
            membersOnTeam={membersOnTeam}
            reload={reload}
          />
        </Modal>
      )}
    </React.Fragment>
  );
};
