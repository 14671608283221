import axiosClient from '../../configs/axios.configs';
import { IResponse } from '../../types/response.interface';
import { IUser } from './user.interface';

export const signInWithSlack = (code: string) => {
  return axiosClient.get('sign-in-with-slack/callback', { params: { code } });
};
export const addToSlack = (code: string) => {
  return axiosClient.get('add-to-slack/callback', {
    params: { code },
  });
};
export const logout = () => {
  // call api logout in here
  localStorage.removeItem('token');
};

export const getUserInfo = () => {
  return axiosClient.get<IResponse<Partial<IUser>>>('members/info');
};
export const loginWithGoogle = (infoUser) => {
  return axiosClient.post('signInWithGoogle', infoUser);
};
