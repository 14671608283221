import React, { useContext } from 'react';
import { Space, Spin, Table } from 'antd';
import EditIcon from 'src/components/Icons/Edit';
import DeleteIcon from 'src/components/Icons/Delete';
import { AuthContext } from 'src/contexts/Auth';
import { UserRole } from 'src/constants/Roles';
import { IDepartment } from 'src/services/departments';

interface DepartmentTableProps {
  loading: boolean;
  departments: Array<IDepartment>;
  onClickEdit: (record: IDepartment) => void;
  onClickDelete: (id: string) => void;
}

export default function DepartmentTable({
  departments,
  loading,
  onClickEdit,
  onClickDelete,
}: DepartmentTableProps) {
  const { user } = useContext(AuthContext);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'department_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      render: (_: any, record: IDepartment) => (
        <Space size="small">
          <EditIcon
            tooltip="Edit"
            onClick={() => onClickEdit(record)}
            style={{ marginRight: 8 }}
          />
          {user.role === UserRole.WORKSPACE_ADMIN && (
            <DeleteIcon
              tooltip="Delete"
              onClick={() => onClickDelete(record._id)}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      className="member-table"
      pagination={false}
      columns={columns}
      dataSource={departments}
      loading={{
        spinning: loading,
        indicator: <Spin />,
        tip: 'Loading...',
      }}
      rowKey="_id"
    />
  );
}
