import './style.less';
import React, { useEffect, useState } from 'react';
import { Divider, Modal } from 'antd';
import DepartmentHeader from 'src/pages/Departments/DepartmentHeader';
import AddDepartmentModal from 'src/pages/Departments/AddDepartmentModal';
import {
  deleteDepartment,
  getDepartments,
} from 'src/services/departments/department.services';
import DepartmentTable from 'src/pages/Departments/DepartmentTable';
import MainLayout from 'src/layouts/MainLayout';
import EditDepartmentModal from 'src/pages/Departments/EditDepartmentModal';
import {
  IDepartment,
  IDepartmentQuery,
} from 'src/services/departments/department.interface';
import { DepartmentTablePagination } from 'src/pages/Departments/DepartmentTablePagination';
import DepartmentFilterBar from 'src/pages/Departments/DepartmentFilterBar';
import { toast } from 'react-toastify';
import { IPagination } from 'src/interfaces/pagination';
import useDebounce from 'src/hooks/useDebounce';
import { ModalType } from 'src/enums';

export default function Departments() {
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    offset: 0,
    limit: 10,
    total: 0,
    currentPage: 1,
  });

  const [loadDeleteDepartment, setLoadDeleteDepartment] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDepartmentId, setDeleteDepartmentId] = useState('');
  const [selectedDepartment, setSelectedDepartment] =
    useState<IDepartment | null>(null);
  const [openModal, setOpenModal] = useState<ModalType>(ModalType.Idle);

  const debounceSearchTerm = useDebounce(searchTerm.trim());

  async function fetchDepartment(query: Partial<IDepartmentQuery> = {}) {
    try {
      setLoading(true);
      const res = await getDepartments({ ...query });
      setDepartments(res.data.data.data);
      setPagination((prev) => ({ ...prev, total: res.data.data.data.length }));
    } catch (error) {
      toast.error('Get departments failed.');
    } finally {
      setLoading(false);
    }
  }

  function reloadMainScreen() {
    const queryObj: IDepartmentQuery = {
      page: pagination.currentPage,
      perPage: pagination.limit,
      isRemoved: false,
      search: debounceSearchTerm,
    };
    fetchDepartment(queryObj);
  }

  function handleClickEdit(record: IDepartment) {
    setOpenModal(ModalType.Edit);
    setSelectedDepartment(record);
  }

  async function handlePageChange(
    currentPage: number,
    pageSize: number | undefined,
  ) {
    if (pageSize) {
      const offset = (currentPage - 1) * pageSize;
      if (pagination.total === 0) {
        return;
      } else {
        setLoading(true);
        setPagination((prevState) => ({
          ...prevState,
          currentPage,
          offset,
          limit: pageSize,
        }));
      }
    }
  }

  function handleCloseEdit() {
    setOpenModal(ModalType.Idle);
  }

  function handleCloseAfterEdit() {
    reloadMainScreen();
  }

  function handleCloseAfterCreate() {
    setOpenModal(ModalType.Idle);
    reloadMainScreen();
  }

  async function handleDeleteDepartment() {
    setLoadDeleteDepartment(true);
    try {
      const res = await deleteDepartment(deleteDepartmentId);
      if (res.data.data) {
        toast.success('Delete department successfully');
        setOpenModal(ModalType.Idle);
        reloadMainScreen();
      }
    } catch (error) {
      toast.error('Can not delete this department');
    } finally {
      setLoadDeleteDepartment(false);
    }
  }

  function handleClickDelete(id: string) {
    setOpenModal(ModalType.Delete);
    setDeleteDepartmentId(id);
  }

  useEffect(() => {
    const queryObj: IDepartmentQuery = {
      page: pagination.currentPage,
      perPage: pagination.limit,
      isRemoved: false,
      search: debounceSearchTerm,
    };
    fetchDepartment(queryObj);
  }, [pagination.currentPage, pagination.limit, debounceSearchTerm]);

  return (
    <MainLayout>
      <DepartmentHeader onAddDepartment={() => setOpenModal(ModalType.Add)} />
      <Divider />
      <DepartmentFilterBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Divider />
      <DepartmentTable
        loading={loading}
        departments={departments}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <DepartmentTablePagination
        pagination={pagination}
        handlePageChange={handlePageChange}
      />

      {openModal === ModalType.Edit && (
        <EditDepartmentModal
          title="Edit department"
          visible={true}
          onOk={() => setOpenModal(ModalType.Idle)}
          onCancel={() => setOpenModal(ModalType.Idle)}
          footer={null}
          selectedDepartment={selectedDepartment}
          handleCloseAfterEdit={handleCloseAfterEdit}
          handleCloseEdit={handleCloseEdit}
        />
      )}

      {openModal === ModalType.Add && (
        <AddDepartmentModal
          title="Add department"
          visible={true}
          footer={null}
          onCancel={() => setOpenModal(ModalType.Idle)}
          handleCloseAfterCreate={handleCloseAfterCreate}
        />
      )}

      {openModal === ModalType.Delete && (
        <Modal
          title="Confirm delete"
          visible={true}
          onCancel={() => setOpenModal(ModalType.Idle)}
          confirmLoading={loadDeleteDepartment}
          onOk={handleDeleteDepartment}
          children="Are you sure you want to delete this department?"
        />
      )}
    </MainLayout>
  );
}
