import {
  Button,
  Form,
  Input,
  Modal,
  ModalProps,
  Switch,
  TimePicker,
  Typography,
} from 'antd';
import React, { useState } from 'react';

import moment from 'moment';
import { toast } from 'react-toastify';
import {
  IDepartment,
  IDepartmentUpdateData,
} from 'src/services/departments/department.interface';
import { updateDepartment } from 'src/services/departments/department.services';
import DeleteIcon from 'src/components/Icons/Delete';

const { Title } = Typography;

interface IEditDepartmentModal extends ModalProps {
  handleUpdateDepartment?: () => void;
  handleCloseAfterEdit: () => void;
  handleCloseEdit: () => void;
  selectedDepartment: IDepartment | null;
}

export default function EditDepartmentModal({
  selectedDepartment,
  handleCloseAfterEdit,
  handleCloseEdit,
  ...rest
}: IEditDepartmentModal) {
  const [form] = Form.useForm();
  const [enableBirthday, setEnableBirthday] = useState(
    selectedDepartment?.config.birthday_notify,
  );

  const [messages, setMessages] = useState(
    convertMessageToObject(selectedDepartment?.config.birthday_messages || []),
  );

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
  };

  async function handleUpdateDepartment(newProps: IDepartmentUpdateData) {
    try {
      setLoadingUpdate(true);
      const res = await updateDepartment({ ...newProps });
      setLoadingUpdate(false);
      if (res.data.data) {
        toast.success('Update successfully');
        handleCloseAfterEdit();
        handleCloseEdit();
      }
    } catch (error) {
      toast.error('Cannot update this department');
    }
  }

  async function onFinish(values) {
    const birthdayMessages: string[] = [];
    for (let key in values) {
      if (key.includes('message_')) {
        birthdayMessages.push(values[key]);
      }
    }

    const newData: IDepartmentUpdateData = {
      id: selectedDepartment?._id as string,
      description: values.departmentDescription
        ? values.departmentDescription.trim()
        : '',
      birthdayNotify: values.configEnableBirthdayNotify,
      birthdayMessages: birthdayMessages,
      spaceIdDefault: selectedDepartment?.config.space_id_default as string,
      birthdayTime: moment(values.birthdayTime).format('HH:mm'),
    };
    await handleUpdateDepartment(newData);
  }

  const messagesObj = messages.reduce((acc, curr, idx) => {
    acc[curr.id] = curr.message;
    return acc;
  }, {});

  function handleDeleteMessage(deletedMessage) {
    if (messages.length <= 1) {
      toast.error('One message is required');
      return;
    }

    const newArr = messages.filter((msg, idx) => msg.id !== deletedMessage.id);
    setMessages([...newArr]);
  }

  function handleAddMessage() {
    if (messages.length >= 6) {
      toast.error('You can only create less or equal than 6 messages');
      return;
    }
    setMessages([
      ...messages,
      { id: randStr('message_'), message: 'Happy birthday!' },
    ]);
  }

  function convertMessageToObject(messages: string[]) {
    return messages.map((msg, idx) => ({
      id: randStr('message_'),
      message: msg,
    }));
  }

  function randStr(prefix: string) {
    return Math.random()
      .toString(36)
      .replace('0.', prefix || '');
  }

  return (
    <Modal {...rest}>
      {selectedDepartment && (
        <React.Fragment>
          <Form
            {...layout}
            onFinish={onFinish}
            form={form}
            initialValues={{
              birthdayTime: moment(
                selectedDepartment.config.birthday_time,
                'hh:mm',
              ),
              ...messagesObj,
              configEnableBirthdayNotify:
                selectedDepartment.config.birthday_notify,
            }}
          >
            <Form.Item
              name="departmentName"
              label="Department name"
              initialValue={selectedDepartment.department_name}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="departmentDescription"
              label="Department description"
              initialValue={selectedDepartment.description}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="configEnableBirthdayNotify"
              label="Birthday notification"
            >
              <Switch
                checked={enableBirthday}
                defaultChecked={selectedDepartment.config.birthday_notify}
                onChange={() => setEnableBirthday(!enableBirthday)}
                checkedChildren="Active"
                unCheckedChildren="Inactive"
              />
            </Form.Item>
            <Form.Item
              name="birthdayTime"
              label="Birthday time"
              rules={[{ required: true }]}
            >
              <TimePicker format="HH:mm" className="vbot-w-full" />
            </Form.Item>

            <Title level={5}>Birthday messages</Title>
            {messages.map((msg, idx) => (
              <React.Fragment key={'messageId' + idx}>
                <Form.Item
                  label={`Message ${idx + 1}`}
                  name={msg.id}
                  rules={[{ required: true, message: 'Message is required' }]}
                  extra={
                    <DeleteIcon
                      tooltip="Delete"
                      onClick={() => handleDeleteMessage(msg)}
                    />
                  }
                  className="modal-edit-department-form-item-message"
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    className="custom-scrollbar"
                  >
                    {msg.message}
                  </Input.TextArea>
                </Form.Item>
              </React.Fragment>
            ))}

            <Form.Item {...tailLayout}>
              <div className="vbot-text-right">
                <Button type="link" onClick={handleAddMessage}>
                  Add a message
                </Button>
                <Button
                  type="primary"
                  loading={loadingUpdate}
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </React.Fragment>
      )}
    </Modal>
  );
}
