import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Chart,
  Interval,
  Tooltip,
  Axis,
  Coordinate,
  Interaction,
} from 'bizcharts';
import ColumnLineChart from './ColumnLineChart';
import { ITeam } from '../../../services/team';
import { Button, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { IPieChartReportByTeam, IReportByTeam } from '../../../services/report';
//import { SelectDateRange } from './SelectDateRange';
//import { rangeDate } from '../../../constants';

type PieChartTeamProps = {
  data: IPieChartReportByTeam;
  resetOverview: Dispatch<SetStateAction<Partial<IReportByTeam>>>;
};
export const PieChartTeam: React.FC<PieChartTeamProps> = (
  props: PieChartTeamProps,
) => {
  const [showTeamReport, setShowTeamReport] = useState<boolean>(false);
  const [detailTeam, setDetailTeam] = useState<Partial<ITeam>>({
    _id: '',
    name: '',
  });
  const changeReport = () => {
    if (showTeamReport) {
      resetOverview({
        pending: data.pending,
        reported: data.reported,
        totalObstacles: data.totalObstacles,
        teamId: '',
      });
    }
    setShowTeamReport(!showTeamReport);
  };
  //const loadPieChartData = async () => {};
  const { data, resetOverview } = props;
  const cols = {
    percent: {
      formatter: (val) => {
        val = Math.round(val * 100) + '%';
        return val;
      },
    },
  };
  return (
    <div>
      {showTeamReport ? (
        <ColumnLineChart viewChart={changeReport} detailTeam={detailTeam} />
      ) : (
        <>
          <div className="header-report-chart">
            <h3 style={{ paddingLeft: 32 }}>Report by teams</h3>

            {/* <SelectDateRange
              rangeDateOption={rangeDate}
              reloadData={loadPieChartData}
            /> */}
          </div>
          {data.reported === 0 ? (
            <div className="wrap-container">
              <Empty description={<b>No report today</b>}>
                <Link to="/teams">
                  <Button type="primary">View teams</Button>
                </Link>
              </Empty>
            </div>
          ) : (
            <Chart
              height={400}
              data={data.reportsByTeam}
              scale={cols}
              autoFit
              onGetG2Instance={(c) => {
                c.geometries[0].elements.forEach((e, idx) => {
                  //  e.setState('selected', idx === 0 ? true : false);
                  e.setState('selected', false);
                });
              }}
              onIntervalClick={(ev) => {
                const data = ev.data;
                if (data) {
                  changeReport();
                  const team = data.data;
                  setDetailTeam(team);
                  resetOverview({
                    pending: team.members - team.totalReports,
                    reported: team.totalReports,
                    totalObstacles: team.totalObstacles,
                    teamId: team._id,
                  });
                  //  console.log(data.data);
                }
              }}
            >
              <Coordinate type="theta" radius={0.75} />
              <Tooltip showTitle={false} />
              <Axis visible={false} />
              <Interval
                position="percent"
                adjust="stack"
                color="name"
                style={{
                  lineWidth: 1,
                  stroke: '#fff',
                }}
                label={[
                  'count',
                  {
                    content: (data) => {
                      return `${data.name} : ${data.count} reports`;
                    },
                  },
                ]}
              />
              <Interaction type="element-single-selected" />
            </Chart>
          )}
        </>
      )}
    </div>
  );
};
