import { Avatar } from 'antd';
import React from 'react';

interface IProps {
  avatar: string;
  name: string;
}

const AvatarInfo = ({ avatar, name }: IProps) => {
  return (
    <div className="warp-info-member">
      <div className="avt-member">
        <Avatar src={avatar} shape="square" size="large" />
      </div>
      <div className="content">
        <p>{name}</p>
      </div>
    </div>
  );
};

export default AvatarInfo;
