import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

export const FooterBar: React.FC = () => {
  return (
    <Footer
      style={{ textAlign: 'center', paddingTop: '13px', paddingBottom: '13px' }}
    >
      VMOBOT © 2021
    </Footer>
  );
};
