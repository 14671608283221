import React, { useState } from 'react';
import { Space, Spin, Table, Modal } from 'antd';
import { MemberInfo } from 'src/components';
import { useMemberContext } from 'src/contexts/MemberContext';
import * as notifyService from '../../../../services/member';
import './style.less';
import ModalMember from '../Modal';
import { toast } from 'react-toastify';
import { dateFormat } from 'src/constants';
import moment from 'moment';
import { UserRole } from 'src/constants/Roles';
import RoleMembers from 'src/pages/Members/components/RoleMembers';
import * as memberService from 'src/services/member';
import EditIcon from 'src/components/Icons/Edit';
import CloseCircleIcon from 'src/components/Icons/CloseCircle';
import CheckCircleIcon from 'src/components/Icons/CheckCircle';

export const DisplayMemberTable = () => {
  const { loading, members, fetchFilterMembers, sortedInfo, setSortedInfo } =
    useMemberContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [record, setRecord] = useState();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteMember, setDeleteMember] = useState({
    name: '',
    id: '',
  });
  const [openModalActive, setOpenModalActive] = useState(false);
  const [activeMember, setActiveMember] = useState({
    name: '',
    id: '',
  });

  const handleDeleteMember = async () => {
    try {
      const res = await notifyService.deleteMember(deleteMember.id);
      if (res?.data?.data) {
        await fetchFilterMembers();
        memberService.syncMembers();
        setOpenModalDelete(false);
        toast.success(`${deleteMember.name} has been deactivated`);
      }
    } catch (error) {
      toast.error('Can not deactive this member');
    }
  };

  const handleActiveMember = async () => {
    try {
      const res = await notifyService.activeMember(activeMember.id);
      if (res?.data?.data) {
        await fetchFilterMembers();
        memberService.syncMembers();
        setOpenModalActive(false);
        toast.success(`${activeMember.name} has been activated`);
      }
    } catch (error) {
      toast.error('Can not active this member');
    }
  };

  const checkRole = (record) => {
    let role = { name: 'Full Member', order: 0, role: 'user' };
    if (record.role === UserRole.DEPARTMENT_ADMIN) {
      role = {
        name: 'Department Admin',
        order: 1,
        role: UserRole.DEPARTMENT_ADMIN,
      };
    }
    if (record.role === UserRole.WORKSPACE_ADMIN) {
      role = {
        name: 'Workspace Admin',
        order: 2,
        role: UserRole.WORKSPACE_ADMIN,
      };
    }
    return role;
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'real_name',
      key: 'real_name',
      width: 170,
      render: (_, record) => <MemberInfo info={record} />,
    },
    {
      title: 'Account Type',
      dataIndex: 'role',
      key: 'role',
      width: 160,
      sorter: (a, b) => {
        return checkRole(a).order - checkRole(b).order;
      },
      defaultSortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
      sortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
      sortDirections: ['descend' as 'descend'],
      render: (_: any, record) => {
        // return <RoleMember role={checkRole(record)} />;
        return (
          <RoleMembers
            key={record.role + record.departmentId}
            member={record}
          />
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 130,
      render: (_: any, record) => record.email,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      width: 80,
      key: 'phone',
      render: (_: any, record) =>
        record.phone ? record.phone.replaceAll(' ', '') : '',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: 80,
      key: 'department',
      render: (_: any, record) => record.departmentName,
    },
    {
      title: 'Birthday',
      dataIndex: 'birthday',
      width: 100,
      key: 'birthday',
      render: (_: any, record) => record.birthday?.replaceAll('/', '-'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 120,
      key: 'createdAt',
      sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
      sorter: (a, b) => {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix();
      },
      render: (_: any, record) =>
        moment(record.createdAt).format(dateFormat.DMY),
    },
    // {
    //   title: 'On Service',
    //   dataIndex: 'onService',
    //   width: 80,
    //   key: 'onService',
    //   sorter: (a, b) => a.onService - b.onService,
    //   sortOrder: sortedInfo.columnKey === 'onService' && sortedInfo.order,
    //   render: (_: any, record) => {
    //     return (
    //       <UpdateStatusMember
    //         reload={fetchFilterMembers}
    //         active={record.onService}
    //         id={record.userId}
    //       />
    //     );
    //   },
    // },
    {
      title: 'Action',
      key: 'action',
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      width: 80,
      render: (_: any, record) => {
        const isDeactivated = record.hasOwnProperty('deactivatedDate');
        return (
          <Space size="small">
            <EditIcon
              tooltip="Edit"
              onClick={() => {
                setRecord(record);
                setIsModalOpen(true);
              }}
              style={{ marginRight: 8 }}
            />
            {isDeactivated ? (
              <CheckCircleIcon
                tooltip="Active"
                onClick={() => {
                  setOpenModalActive(true);
                  setActiveMember({
                    name: record.display_name,
                    id: record._id,
                  });
                }}
              />
            ) : (
              <CloseCircleIcon
                tooltip="Deactive"
                onClick={() => {
                  setOpenModalDelete(true);
                  setDeleteMember({
                    name: record.display_name,
                    id: record._id,
                  });
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <>
      {isModalOpen && (
        <ModalMember
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={() => setIsModalOpen(false)}
          initialValues={record}
        />
      )}
      <Table
        className="member-table"
        pagination={false}
        columns={columns}
        dataSource={members}
        loading={{
          spinning: loading,
          indicator: <Spin />,
          tip: 'Syncing data',
        }}
        rowKey="_id"
        scroll={{ x: 1000 }}
        onChange={handleChange}
      />
      {openModalDelete && (
        <Modal
          title="Confirm deactive"
          visible={openModalDelete}
          onCancel={() => setOpenModalDelete(false)}
          onOk={handleDeleteMember}
          children="Are you sure you want to deactive this member?"
        />
      )}
      {openModalActive && (
        <Modal
          title="Confirm active"
          visible={openModalActive}
          onCancel={() => setOpenModalActive(false)}
          onOk={handleActiveMember}
          children="Are you sure you want to active this member?"
        />
      )}
    </>
  );
};
