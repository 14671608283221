import React, { useState } from 'react';
import { Modal, Button, Row, Col, Space, message, Select } from 'antd';
import { Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import * as questionPackService from '../../../services/questionPack';
import { IQuestionPack } from '../../../services/team';
import { toast } from 'react-toastify';
import { questionPackNotify } from '../../../constants';

const { Option } = Select;

type ChangeQuestionPackModalProps = {
  title: string;
  BtnContent: React.ReactNode;
  detailQuestionPack: Partial<IQuestionPack>;
  reload: () => Promise<void>;
  type: 'ADD' | 'UPDATE';
};

export const ChangeQuestionPackModal: React.FC<ChangeQuestionPackModalProps> = (
  props: ChangeQuestionPackModalProps,
) => {
  const { title, BtnContent, detailQuestionPack, reload, type } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 4,
        offset: 18,
      },
    },
  };

  const onFinish = async (values) => {
    //console.log('Received values of form:', values);
    setConfirmLoading(true);
    try {
      const res =
        type === 'ADD'
          ? await questionPackService.createQuestionPack(values)
          : await questionPackService.updateQuestionPack({
              _id: detailQuestionPack._id,
              updatedPack: values,
            });
      if (res.data) {
        await reload();
        type === 'ADD'
          ? toast.success(questionPackNotify.create)
          : toast.success(questionPackNotify.update);
        setConfirmLoading(false);
        setVisible(false);
        form.resetFields();
      }
    } catch (error) {}
  };
  const showModal = async () => {
    if (detailQuestionPack._id) {
      try {
        setLoadingQuestions(true);
        const { data } = await questionPackService.getQuestionsOfPack(
          detailQuestionPack._id,
        );
        //console.log(data);
        if (data.data) {
          form.setFieldsValue({ questions: data.data });
          setLoadingQuestions(false);
          setVisible(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else setVisible(true);
  };
  const deleteQuestion = async (questionId) => {
    // let isTouched = form.isFieldsTouched(['name', 'questions']);
    const key = 'deleteQuestion';
    message.loading({ content: 'Loading...', key });
    try {
      const { data } = await questionPackService.deleteQuestion({
        packId: detailQuestionPack._id,
        questionId: questionId,
      });
      setConfirmLoading(false);
      if (data) {
        message.success({ content: 'Deleted question!', key, duration: 2 });
        // isTouched ? setDisableSubmit(false) : setDisableSubmit(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const onValuesChange = () => {
    setDisableSubmit(false);
    if (
      !form.getFieldValue('questions') ||
      form.getFieldValue('questions').length <= 0
    ) {
      setDisableSubmit(true);
    }
  };
  return (
    <React.Fragment>
      {type === 'ADD' ? (
        <div style={{ cursor: 'pointer' }} onClick={showModal}>
          {BtnContent}
        </div>
      ) : (
        <Button
          type="link"
          onClick={showModal}
          loading={loadingQuestions}
          icon={BtnContent}
        />
      )}

      <Modal
        visible={visible}
        title={title || 'Title'}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <Form
          name="dynamic_form_item"
          {...formItemLayout}
          form={form}
          onFinish={onFinish}
          initialValues={{
            name: detailQuestionPack.name,
          }}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your question pack name!',
              },
            ]}
          >
            <Input style={{ width: '90%' }} />
          </Form.Item>

          <Form.List name="questions">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0
                        ? formItemLayout
                        : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Questions' : ''}
                      rules={[
                        {
                          required: true,
                          message: 'Please type question or delete this field.',
                        },
                      ]}
                      key={field.key}
                    >
                      <Space style={{ display: 'flex' }} align="start">
                        <Form.Item
                          {...field}
                          name={[field.name, 'content']}
                          fieldKey={[field.fieldKey, 'content']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please type question .',
                            },
                          ]}
                          noStyle
                        >
                          <Input placeholder="Type a question" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'hint']}
                          fieldKey={[field.fieldKey, 'hint']}
                          noStyle
                        >
                          <Input placeholder="Type a hint" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'type']}
                          fieldKey={[field.fieldKey, 'type']}
                          noStyle
                        >
                          <Select>
                            <Option value="NORMAL">Normal</Option>
                            <Option value="OBSTACLE">Obstacle</Option>
                          </Select>
                        </Form.Item>
                        {fields.length > 0 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ margin: '0 8px' }}
                            onClick={async () => {
                              let deleteData =
                                form.getFieldValue('questions')[field.name];
                              remove(field.name);
                              if (deleteData && deleteData._id) {
                                deleteQuestion(deleteData._id);
                              }
                            }}
                          />
                        ) : null}
                      </Space>
                    </Form.Item>
                  ))}
                  <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({ type: 'NORMAL' });
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add new question
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  // onClick={onFinish}
                  disabled={disableSubmit}
                  loading={confirmLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
