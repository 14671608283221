import React from 'react';
import { CalendarFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface IProps {
  tooltip?: string;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
}

const CalendarIcon = ({ tooltip, onClick, style, className }: IProps) => {
  return (
    <Tooltip title={tooltip}>
      <CalendarFilled
        onClick={onClick}
        style={{ color: '#13c2c2', fontSize: 16, ...style }}
        className={className}
      />
    </Tooltip>
  );
};

export default CalendarIcon;
