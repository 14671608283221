import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserRole } from 'src/constants/Roles';
import { OnService } from 'src/helpers';
import { IMember, IModalImportInfo } from 'src/services/member';
import * as memberService from '../services/member';
import { IPagination } from 'src/interfaces/pagination';
import { IDepartment, getDepartments } from 'src/services/departments';

interface ISortedInfo {
  columnKey: string;
  order: any;
}

interface IMemberContext {
  loading: boolean;
  members: IMember[];
  activeMembers: IMember[];
  searchText: string;
  onService: OnService;
  pagination: IPagination;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMembers: React.Dispatch<React.SetStateAction<IMember[]>>;
  setActiveMembers: React.Dispatch<React.SetStateAction<IMember[]>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setOnService: React.Dispatch<React.SetStateAction<OnService>>;
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>;
  fetchFilterMembers: () => Promise<void>;
  sortedInfo: ISortedInfo;
  setSortedInfo: (data: ISortedInfo) => void;
  setAccountType: React.Dispatch<React.SetStateAction<UserRole | undefined>>;
  setDepartmentId: React.Dispatch<React.SetStateAction<string | undefined>>;
  modalImportInfo: IModalImportInfo;
  setModalImportInfo: React.Dispatch<React.SetStateAction<IModalImportInfo>>;
  departments: IDepartment[];
}

const MemberContext = React.createContext<IMemberContext>({
  loading: true,
  members: [],
  activeMembers: [],
  searchText: '',
  onService: OnService.All,
  pagination: {
    offset: 0,
    limit: 10,
    total: 0,
    currentPage: 0,
  },
  sortedInfo: {
    columnKey: 'accountType',
    order: 'descend',
  },
  setLoading: () => Boolean,
  setMembers: () => [],
  setActiveMembers: () => [],
  setSearchText: () => String,
  setOnService: () => OnService,
  setPagination: () => {},
  fetchFilterMembers: async () => undefined,
  setSortedInfo: () => {},
  setAccountType: () => undefined,
  setDepartmentId: () => undefined,
  modalImportInfo: {
    showModal: false,
    fileSelected: null,
    isUploading: false,
    isUploadSuccess: false,
    listDuplicated: [],
    departmentId: '',
  },
  setModalImportInfo: () => undefined,
  departments: [],
});

export const MemberProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<IMember[]>([]);
  const [activeMembers, setActiveMembers] = useState<IMember[]>([]);
  const [searchText, setSearchText] = useState('');
  const [onService, setOnService] = useState<OnService>(OnService.All);
  const [pagination, setPagination] = useState<IPagination>({
    offset: 0,
    limit: 10,
    total: 0,
    currentPage: 1,
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'role',
    order: 'descend',
  });

  const [accountType, setAccountType] = useState<UserRole | undefined>(
    undefined,
  );
  const [departmentId, setDepartmentId] = useState<string | undefined>(
    undefined,
  );
  const [modalImportInfo, setModalImportInfo] = useState<IModalImportInfo>({
    showModal: false,
    fileSelected: null,
    isUploading: false,
    isUploadSuccess: false,
    listDuplicated: [],
    departmentId: '',
  });
  const [departments, setDepartments] = useState<IDepartment[]>([]);

  const fetchFilterMembers = useCallback(async () => {
    const responseMembers = await memberService.getFilteredMembers({
      text: searchText,
      onService,
      offset: pagination.offset,
      limit: pagination.limit,
      accountType: accountType,
      departmentId,
    });

    const responseActiveMembers = await memberService.getListActiveMember();

    if (responseMembers?.data?.data) {
      const { count: totalCountMembers, members: filterMembers } =
        responseMembers.data.data;
      setMembers(filterMembers);
      setPagination((prevState) => ({
        ...prevState,
        total: totalCountMembers,
      }));
    }

    if (responseActiveMembers.data) {
      if (responseActiveMembers.data.data) {
        const { data: activeMembers } = responseActiveMembers.data;
        setActiveMembers(activeMembers);
      }
    }
    setLoading(false);
  }, [
    onService,
    pagination.limit,
    pagination.offset,
    searchText,
    accountType,
    departmentId,
  ]);

  useEffect(() => {
    fetchFilterMembers();
  }, [fetchFilterMembers]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const res = await getDepartments();
        if (res.data) {
          setDepartments(res.data.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MemberContext.Provider
      value={{
        loading,
        members,
        activeMembers,
        searchText,
        onService,
        pagination,
        sortedInfo,
        setLoading,
        setMembers,
        setActiveMembers,
        setSearchText,
        setOnService,
        setPagination,
        fetchFilterMembers,
        setSortedInfo,
        setAccountType,
        setDepartmentId,
        modalImportInfo,
        setModalImportInfo,
        departments,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMemberContext = () => {
  return useContext(MemberContext);
};
