import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { isLogin } from '../helpers';

export const PublicRoute: React.FC<{ restricted: boolean } & RouteProps> = ({
  children,
  restricted,
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest}>
      {isLogin() && restricted ? <Redirect to="/" /> : children}
    </Route>
  );
};
