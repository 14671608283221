enum TimesheetStatusType {
  LATE = 'LATE',
  MORNING_OFF = 'MORNING OFF',
  AFTERNOON_OFF = 'AFTERNOON OFF',
  BREAK = 'BREAK',
  EARLY = 'EARLY',
  DAY_OFF = 'DAYOFF',
  WORK_FROM_HOME = 'WFH',
  WORK_FROM_HOME_MORNING = 'WFH MORNING',
  WORK_FROM_HOME_AFTERNOON = 'WFH AFTERNOON',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
  OT = 'OT',
}
export interface IParamDate {
  startTime: number;
  endTime: number;
  top?: number;
}
export default TimesheetStatusType;
