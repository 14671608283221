import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Switch } from 'antd';
import React, { useState } from 'react';
import { updateWorkspaceFeature } from 'src/services/paymentPackage';
interface IUpdateFeatureMemberType {
  featureData: {
    enable: boolean;
    featureName: string;
    _id: string;
  };
  active: () => void;
}
const { confirm } = Modal;
export const UpdateFeature: React.FC<IUpdateFeatureMemberType> = ({
  featureData,
  active,
}) => {
  const [checked, setChecked] = useState(featureData.enable);
  function confirmUpdate() {
    confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure ${
        featureData.enable ? 'disable' : 'enable'
      } feature "${featureData.featureName}". ${
        featureData.enable
          ? ' This action may prevent you from receiving reports in the future'
          : ''
      }`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const featureUpdate = {
          feature: {
            id: featureData._id,
            enable: !featureData.enable,
          },
        };
        try {
          const { data } = await updateWorkspaceFeature(featureUpdate);
          if (data.data) {
            setTimeout(() => {
              active();
            }, 1000);
          }
        } catch (error) {
          setChecked(checked);
          throw error;
        }
      },
      onCancel() {
        setChecked(checked);
      },
    });
  }
  return (
    <div className="allow-feature" key={featureData._id}>
      <p className="tag-desc"> - {featureData.featureName}</p>
      <Switch
        onClick={() => setChecked(!checked)}
        onChange={confirmUpdate}
        checkedChildren="Enable"
        unCheckedChildren="Disable"
        defaultChecked={checked}
        checked={checked}
      />
    </div>
  );
};
