import { Tag } from 'antd';
import React from 'react';
import TimesheetStatusType from 'src/constants/TimesheetType';

export default function CheckStatus({ status }: { status: string }) {
  let color = 'magenta';
  switch (status) {
    case TimesheetStatusType.LATE: {
      color = 'red';
      break;
    }
    case TimesheetStatusType.AFTERNOON_OFF: {
      color = 'purple';
      break;
    }
    case TimesheetStatusType.MORNING_OFF: {
      color = 'magenta';
      break;
    }
    case TimesheetStatusType.WORK_FROM_HOME_MORNING: {
      color = 'pink';
      break;
    }
    case TimesheetStatusType.WORK_FROM_HOME_AFTERNOON: {
      color = 'blue';
      break;
    }
    case TimesheetStatusType.WORK_FROM_HOME: {
      color = 'gold';
      break;
    }
    case TimesheetStatusType.EARLY: {
      color = 'green';
      break;
    }
    case TimesheetStatusType.BREAK: {
      color = 'geekblue';
      break;
    }
    default:
      color = 'gray';
      break;
  }
  return (
    <Tag className="tag-status" color={color}>
      {status}
    </Tag>
  );
}
