import { Select } from 'antd';
import React from 'react';

const { Option } = Select;
type DateRange = {
  value: number | string;
  label: string;
};
type TSelectDateRange = {
  rangeDateOption: { value: number | string; label: string }[];
  reloadData: (dateRange: DateRange) => Promise<void>;
};
export const SelectDateRange: React.FC<TSelectDateRange> = (
  props: TSelectDateRange,
) => {
  const { rangeDateOption, reloadData } = props;
  const handleChange = (value) => {
    reloadData(value);
  };
  return (
    <Select
      defaultValue={rangeDateOption[0].value}
      style={{ width: 150 }}
      onChange={handleChange}
      placeholder="Choose date range"
    >
      {rangeDateOption.map((item, index) => (
        <Option value={item.value} key={index}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};
