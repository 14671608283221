import React from 'react';
import '../style.less';
import { IReportContent } from '../../../services/report';
import classNames from 'classnames';

type TReportContent = {
  content: IReportContent[];
};
export const ReportContent: React.FC<TReportContent> = (
  props: TReportContent,
) => {
  const { content } = props;
  return (
    <div>
      {content.map((item) => {
        const answer = item.answer ? item.answer.split('\n') : ['No respone'];
        return (
          <div
            key={item.question._id}
            className={classNames('report-content', {
              obstacle: item.question.type === 'OBSTACLE' ? true : false,
            })}
          >
            <b>{item.question.content}</b>
            <span>{item.question.hint && ` ( ${item.question.hint} )`}</span>
            <br />
            {/* <p>{item.question.hint}</p> */}
            {answer.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        );
      })}
    </div>
  );
};
