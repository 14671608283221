import React, { useEffect, useRef, useState } from 'react';
import { Modal, Switch } from 'antd';
import { INewReminder } from 'src/services/reminder';
import { toast } from 'react-toastify';
import * as reminderService from 'src/services/reminder';
import { AxiosError } from 'axios';

type Props = {
  reminder: INewReminder;
  reload: () => void;
};

export function SwitchReminder({ reminder, reload }: Props) {
  const [checked, setChecked] = useState(reminder.enable);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const prevCheckedRef = useRef<boolean | undefined>(undefined);

  function handleChangeSwitch(checked: boolean) {
    setOpenConfirm(true);
    setChecked(checked);
    setLoading(true);
  }

  async function handleOnOk() {
    try {
      setConfirmLoading(true);

      const resToggleReminder = await reminderService.toggleReminder(
        reminder._id,
        checked,
      );
      if (resToggleReminder instanceof Error) {
        let error = resToggleReminder as unknown as AxiosError;

        toast.error(
          error.response?.data?.errors?.message || 'Can not update reminder',
        );
      } else {
        setOpenConfirm(false);
        toast.success(
          `${checked ? 'Enabled' : 'Disabled'} reminder successfully`,
        );
        reload();
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
      setConfirmLoading(false);
    }
  }

  const prevChecked = prevCheckedRef.current;

  function closeConfirm() {
    setChecked(Boolean(prevChecked));
    setOpenConfirm(false);
    setLoading(false);
    setConfirmLoading(false);
  }

  useEffect(() => {
    prevCheckedRef.current = checked;
  }, [checked]);

  useEffect(() => {
    setChecked(reminder.enable);
  }, [reminder.enable]);

  return (
    <>
      <Switch
        loading={loading}
        checked={checked}
        onChange={handleChangeSwitch}
        checkedChildren="Enabled"
        unCheckedChildren="Disabled"
      />
      {openConfirm && (
        <Modal
          onCancel={closeConfirm}
          onOk={handleOnOk}
          visible={openConfirm}
          title="Update reminder"
          confirmLoading={confirmLoading}
        >
          <div>
            Do you want to {checked ? 'enable' : 'disable'} this reminder?
          </div>
        </Modal>
      )}
    </>
  );
}
