import React, { useMemo, useState } from 'react';
import * as FileSaver from 'file-saver';
//import XLSX from 'xlsx';
import { Button } from 'antd';
import moment from 'moment';
import { getReports } from '../../../services/report';
import { dateFormat } from '../../../constants';
import XlsxTemplate from 'xlsx-template';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

export const ExportExcel = ({ filterTeam, dateRange, listTeams }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let fileName = 'Report';
  const teams = useMemo(
    () =>
      listTeams.reduce((accumulator, currentValue) => {
        accumulator[currentValue.value] = currentValue.text;
        return accumulator;
      }, {}),
    [listTeams],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const loadXlsx = async (reportData) => {
    const teamName = filterTeam
      ? filterTeam.reduce(
          (accumulator, curVal) => accumulator + `+${teams[curVal]}`,
          '',
        )
      : '';
    fileName += teamName !== '' ? ' ' + teamName.slice(1) : ' AllTeam';
    fileName += ` ${moment(dateRange[0] * 1000).format(
      dateFormat.DMY,
    )}-${moment(dateRange[1] * 1000).format(dateFormat.DMY)}`;
    try {
      await axios
        .get('template.xlsx', { responseType: 'arraybuffer' })
        .then((data) => {
          // console.log('data', data);
          var template = new XlsxTemplate(data.data);
          var sheetNumber = 1;
          // const values = { ...reportData };
          template.substitute(sheetNumber, reportData);
          var a = template.generate() as any;
          FileSaver.saveAs(
            new Blob([s2ab(a)], { type: fileType }),
            fileName + fileExtension,
          );
        });
    } catch (error) {
      console.log('error', error);
    }
  };
  const loadListReports = async (
    page: number = 1,
    limit: number = 0,
    startDate: number,
    endDate: number,
  ) => {
    try {
      const { data } = await getReports({
        page,
        limit,
        startDate,
        endDate,
        teams: filterTeam,
      });
      let headerArr: string[] = [];
      if (data.data) {
        const reportExcel = data.data.reports.map((item, index) => {
          let reportContent = item.reportContent.reduce(
            (accumulator, item, index) => {
              if (index + 1 > headerArr.length / 2) {
                headerArr = headerArr.concat([
                  `Question${index + 1}`,
                  `Answer${index + 1}`,
                ]);
              }
              accumulator[`Question${index + 1}`] = `${item.question.content}`;
              accumulator[`Answer${index + 1}`] = `${item.answer}`;
              return accumulator;
            },
            {},
          );
          return {
            Name: item.member.real_name,
            Team: item.team.name,
            Date: moment(item.createdAt).format(dateFormat.DMY),
            ...reportContent,
          };
        });
        return { data: reportExcel, headerArr };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClick = async () => {
    setIsLoading(true);
    const reportData = await loadListReports(1, 0, dateRange[0], dateRange[1]);
    //  console.log(reportData);
    setIsLoading(false);
    loadXlsx(reportData);
    // exportToCSV(reportData, fileName);
  };
  return (
    <div style={{ marginLeft: 16 }}>
      <Button
        type="primary"
        onClick={onClick}
        loading={isLoading}
        icon={<DownloadOutlined />}
      >
        Report
      </Button>
    </div>
  );
};
