import { Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Obstacle from '../../../assets/images/obstacle.svg';
import { getObstacles, IReport } from '../../../services/report';
import '../style.less';
import { ListObstacleModal } from './ListObstacleModal';
import moment from 'moment';

type TObstacleCount = {
  obstacles: number;
  teamId: string;
};
export const ObstacleCount: React.FC<TObstacleCount> = (
  props: TObstacleCount,
) => {
  const { obstacles, teamId } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [listObstacles, setListObstacles] = useState<Partial<IReport>[]>([]);
  const handleCancel = () => {
    setVisible(false);
  };
  const onObstacleClick = () => {
    setVisible(true);
  };
  const loadListObstacle = useCallback(async () => {
    const { data } = await getObstacles({
      teamId: teamId,
      date: moment().unix(),
    });
    setListObstacles(data.data);
  }, [teamId]);
  useEffect(() => {
    loadListObstacle();
    return () => {};
  }, [loadListObstacle]);
  return (
    <>
      <div className="obstacle-wrap" onClick={onObstacleClick}>
        <span>{obstacles} obstacles</span>
        <img src={Obstacle} alt="block" height={160} />
      </div>
      <Modal
        visible={visible}
        title="List obstacles"
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        {visible && <ListObstacleModal listObstacles={listObstacles} />}
      </Modal>
    </>
  );
};
