/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Mentions } from 'antd';

import {
  Modal,
  Button,
  TimePicker,
  Tag,
  Input,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Radio,
} from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import { INewReminder } from 'src/services/reminder';
import { IChannel } from 'src/services/team';
import * as notifyService from 'src/services/reminder';
import { toast } from 'react-toastify';
import { dateFormat, reminderNotify, reminderTypes } from 'src/constants';
import ReminderType from 'src/constants/ReminderType';
import { listDays, daysOfWeek } from 'src/constants';
import { IMention, IMentionsOfChannel } from 'src/services/member';
import { AxiosError } from 'axios';
import { allowDatePicker } from 'src/helpers';

const { Option } = Select;
const { CheckableTag } = Tag;

type ChangeReminderModalProps = {
  title: string;
  BtnContent: React.ReactNode;
  reminder: Partial<INewReminder>;
  listChannels: IChannel[];
  typeModal: 'ADD' | 'UPDATE';
  reload: () => Promise<void>;
  mentions: IMentionsOfChannel[];
};
export const ChangeReminderModal: React.FC<ChangeReminderModalProps> = (
  props: ChangeReminderModalProps,
) => {
  const {
    title,
    BtnContent,
    reminder,
    listChannels,
    typeModal,
    reload,
    mentions,
  } = props;
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [checkEndDate, setCheckEndDate] = useState<boolean>(
    Boolean(Number(reminder.endDate) || 0),
  );

  const [type, setType] = useState<string>(ReminderType.ONETIME);
  const [visible, setVisible] = useState<boolean>(false);
  const [detailReminder, setDetailReminder] = useState<Partial<INewReminder>>({
    time: '07:00',
    date: [],
  });

  // Google Chat: fetch to check if current user can edit or not;
  const [reminderFetched, setReminderFetched] = useState<
    (INewReminder & { isSpaceManager: boolean }) | null
  >(null);
  const [isFetchingReminder, setIsFetchingReminder] = useState(false);

  const [reminderMentionTag, setReminderMentionTag] = useState<IMention[]>([]);
  const [availableMentions, setAvailableMentions] = useState<IMention[]>([]);
  const [errorWorkDays, setErrorWorkDays] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
  };
  const onEndDateChange = (e) => {
    if (e.target.value === 1) {
      setCheckEndDate(true);
    } else {
      setCheckEndDate(false);
    }
  };

  const showModal = async () => {
    if (reminder.message) {
      const mentionsOfChannel = mentions.find(
        (mention) => mention.channelId === reminder.notifyAtChannel?.channelId,
      );
      if (mentionsOfChannel) {
        setReminderMentionTag(mentionsOfChannel?.listMentions);
        setAvailableMentions(mentionsOfChannel?.listMentions);
      }
    }

    form.setFieldsValue({
      name: reminder.name,
      message: reminder.message,
      time: moment(reminder.time, 'HH:mm'),
      date:
        reminder.startDate && reminder.startDate?.length > 0
          ? moment(reminder.startDate[0] * 1000)
          : undefined,
      type: reminder.type ? reminder.type : type,
      notifyAtChannel: reminder.notifyAtChannel
        ? {
            value: reminder.notifyAtChannel.channelId,
            label: reminder.notifyAtChannel.name,
          }
        : undefined,
      endDate: reminder.endDate ? 1 : 0,
      recurrence: reminder.recurrence ? reminder.recurrence : 1,
      customEndDate: reminder.endDate
        ? moment(reminder.endDate * 1000)
        : undefined,
    });
    setCheckEndDate(Boolean(reminder.endDate));
    setDetailReminder({ ...reminder });
    if (reminder.type) {
      setType(reminder.type);
      if (reminder.startDate && reminder.startDate?.length > 0) {
        if (reminder.type === ReminderType.MONTH) {
          form.setFieldsValue({
            date: moment(reminder.startDate[0] * 1000).format(dateFormat.DD),
          });
        }
        if (reminder.type === ReminderType.ONETIME) {
          form.setFieldsValue({
            date:
              moment(reminder.startDate[0] * 1000) > moment().endOf('day')
                ? moment(reminder.startDate[0] * 1000)
                : moment(),
          });
        }
      }
    }
    setVisible(true);
  };
  const formatValuesSubmit = (values) => {
    let reminder: Partial<INewReminder> = {
      name: values.name,
      notifyAtChannel: {
        channelId: values.notifyAtChannel.value,
        name: values.notifyAtChannel.label,
      },
      time: values.time.format('HH:mm:ss'),
      message: values.message,
      tagMessage: '',
    };

    // get tagMessage: format from "hello @userName do something" to "hello <users/userId> do something"
    reminder.tagMessage = availableMentions.reduce(
      (acc, curr) =>
        `${acc}`.replaceAll(`@${curr.displayName}`, `<${curr.name}>`),
      reminder.message,
    );

    if (type === ReminderType.ONETIME) {
      reminder.startDate = [moment(values.date).unix()];
      reminder.endDate = moment(values.date).unix();
      reminder.type = ReminderType.DAY;
      reminder.recurrence = 0;

      return reminder;
    }
    reminder.endDate = values.endDate === 0 ? 0 : values.customEndDate.unix();
    reminder.type = values.type;
    reminder.recurrence = 1;
    switch (type) {
      case ReminderType.DAY:
        reminder.startDate = [moment().unix()];
        break;
      case ReminderType.WEEK:
        reminder.startDate = detailReminder?.date?.map((item) =>
          moment().day(daysOfWeek[item]).unix(),
        );
        break;
      case ReminderType.WORK_DAYS:
        reminder.startDate = listDays
          .slice(0, listDays.length - 2)
          .map((item) => moment().day(daysOfWeek[item]).unix());
        break;
      case ReminderType.MONTH:
        reminder.startDate = [moment().dates(values.date).unix()];
        break;
      default:
        reminder.startDate = [values.date.unix()];
        break;
    }
    return reminder;
  };
  const handleCancel = () => {
    setReminderMentionTag([]);
    setVisible(false);
    form.resetFields();
  };
  const handleChangeType = (value) => {
    form.resetFields(['date']);
    setType(value);
    form.validateFields(['time']);
  };

  const handleChangeChannel = async (option) => {
    if (option) {
      const availableMentions = mentions.find(
        (mention) => mention.channelId === option.value,
      );
      if (availableMentions) {
        setAvailableMentions(availableMentions.listMentions);
        setReminderMentionTag(availableMentions.listMentions);
      }
    } else {
      setReminderMentionTag([]);
    }
  };

  const handleChangeDays = (tag, checked) => {
    const nextSelectedTags = checked
      ? [
          ...(Array.isArray(detailReminder.date) ? detailReminder.date : []),
          tag,
        ]
      : detailReminder.date.filter((t) => t !== tag);
    setDetailReminder({ ...detailReminder, date: nextSelectedTags });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };
  const onFinish = async (values) => {
    form.validateFields();
    if (errorWorkDays) {
      return;
    }
    formatValuesSubmit(values);
    try {
      setConfirmLoading(true);
      let res;
      if (typeModal === 'ADD' && !reminder.userId) {
        res = await notifyService.createReminder(formatValuesSubmit(values));
      } else if (typeModal === 'ADD' && reminder.userId) {
        res = await notifyService.copyReminder({
          ...formatValuesSubmit(values),
          enable: reminder.enable,
          _id: reminder._id,
        });
      } else {
        res = await notifyService.updateAReminder({
          ...formatValuesSubmit(values),
          enable: reminder.enable,
          _id: reminder._id,
        });
      }
      if (res.data) {
        await reload();
        if (typeModal === 'ADD') {
          toast.success(
            detailReminder.userId ? reminderNotify.copy : reminderNotify.create,
          );
        } else toast.success(reminderNotify.update);

        setConfirmLoading(false);
        setReminderMentionTag([]);
        setVisible(false);
      }

      if (res instanceof Error) {
        setConfirmLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
    form.resetFields();
    setReminderMentionTag([]);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setReminderMentionTag([]);
  };

  useEffect(() => {
    if (formRef.current) {
      form.validateFields(['customEndDate']);
    }
  }, [form, formRef]);

  useEffect(() => {
    if (detailReminder?.date?.length === 0 && type === 'week') {
      setErrorWorkDays(true);
    } else {
      setErrorWorkDays(false);
    }
  }, [detailReminder?.date?.length, type]);

  useEffect(() => {
    const getReminder = async () => {
      if (reminder._id && visible) {
        try {
          setIsFetchingReminder(true);
          let memberService = notifyService;
          const res = await memberService.getReminder(reminder?._id);
          if (res instanceof Error) {
            let error = res as unknown as AxiosError;
            toast.error(
              error.response?.data?.errors?.message ||
                'Can not check editing rights',
            );
          }

          setReminderFetched(res.data.data);
        } catch (error) {
          toast.error('Something went wrong');
        } finally {
          setIsFetchingReminder(false);
        }
      }
    };
    getReminder();
  }, [reminder._id, visible]);

  return (
    <React.Fragment>
      <span onClick={() => showModal()}>{BtnContent}</span>
      {visible && (
        <Modal
          visible={visible}
          title={title || 'Title'}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            {...layout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            ref={formRef}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your team's name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="notifyAtChannel"
              label="Channel / Space"
              tooltip={{
                title:
                  'The list only shows channels/spaces that contain VMO Bot, where you are the channel/space manager.',
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: 'Please select a channel!',
                },
              ]}
            >
              <Select
                loading={isFetchingReminder}
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                labelInValue
                placeholder="Select an available channel/space option"
                allowClear
                disabled={
                  !reminderFetched?.isSpaceManager && typeModal !== 'ADD'
                }
                onChange={handleChangeChannel}
              >
                {listChannels &&
                  listChannels.map((item: IChannel) => (
                    <Option key={item.channelId} value={item.channelId}>
                      {item.channelName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="type"
              label="Type Reminder"
              rules={[
                {
                  required: true,
                  message: 'Please select type!',
                },
              ]}
            >
              <Select onChange={handleChangeType}>
                {reminderTypes.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* {type !== ReminderType.ONETIME && (
              <Form.Item label="Repeat every">
                <Form.Item
                  name="recurrence"
                  noStyle
                  rules={[
                    { required: true, message: 'Please input recurrence' },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <span style={{ margin: '0 8px' }}>
                  {type === ReminderType.WORK_DAYS ? ReminderType.WEEK : type}
                  {` (s)`}
                </span>
              </Form.Item>
            )} */}

            {type === ReminderType.WEEK && (
              <Form.Item
                label="Work days"
                extra={
                  <div style={{ color: '#ff4d4f' }}>
                    {errorWorkDays && 'Work days is required'}
                  </div>
                }
              >
                {listDays.map((tag) => (
                  <CheckableTag
                    key={tag}
                    checked={detailReminder.date.indexOf(tag) > -1}
                    onChange={(checked) => handleChangeDays(tag, checked)}
                  >
                    <Button
                      shape="circle"
                      type={
                        detailReminder.date.indexOf(tag) > -1
                          ? 'primary'
                          : 'default'
                      }
                    >
                      {tag}
                    </Button>
                  </CheckableTag>
                ))}
              </Form.Item>
            )}
            {type === ReminderType.WORK_DAYS && (
              <Form.Item
                label="Weekdays"
                extra={
                  <div style={{ color: '#ff4d4f' }}>
                    {errorWorkDays && 'Work days is required'}
                  </div>
                }
              >
                {listDays.slice(0, listDays.length - 2).map((tag) => (
                  <CheckableTag key={tag} checked>
                    <Button
                      style={{ cursor: 'default' }}
                      shape="circle"
                      type={'primary'}
                    >
                      {tag}
                    </Button>
                  </CheckableTag>
                ))}
              </Form.Item>
            )}
            {type === ReminderType.MONTH && (
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: 'Please select date!',
                  },
                ]}
              >
                <InputNumber min={1} max={31} />
              </Form.Item>
            )}
            {type === ReminderType.YEAR && (
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select date!',
                  },
                ]}
              >
                <DatePicker
                  format={dateFormat.DM}
                  placeholder={dateFormat.DM}
                  className="vbot-w-full"
                />
              </Form.Item>
            )}
            {type === ReminderType.ONETIME && (
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select date!',
                  },
                ]}
              >
                <DatePicker
                  placeholder={dateFormat.DMY}
                  format={dateFormat.DMY}
                  disabledDate={disabledDate}
                  className="vbot-w-full"
                  onChange={() => {
                    form.validateFields(['time']);
                  }}
                  onKeyDown={allowDatePicker}
                />
              </Form.Item>
            )}
            <Form.Item
              name="time"
              label="Time Reminder"
              validateFirst={true}
              rules={[
                {
                  type: 'object',
                  required: true,
                  message: 'Please select time!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('type') === 'oneTime' &&
                      getFieldValue('date')
                    ) {
                      if (
                        getFieldValue('date') <= moment().endOf('day') &&
                        value < moment().add(5, 'minutes')
                      ) {
                        return Promise.reject(
                          'Time must be in the future (more than 5 minutes)',
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TimePicker format="HH:mm" className="vbot-w-full" />
            </Form.Item>
            {type !== ReminderType.ONETIME && (
              <>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose start date!',
                    },
                  ]}
                >
                  <Radio.Group onChange={onEndDateChange}>
                    <Radio style={radioStyle} value={0}>
                      No end date
                    </Radio>
                    <Radio style={radioStyle} value={1}>
                      End date by :{' '}
                      <Form.Item
                        name="customEndDate"
                        noStyle
                        rules={[
                          {
                            type: 'object',
                            required: checkEndDate,
                            message: 'Please select date!',
                          },
                          {
                            validator(rule, value) {
                              if (detailReminder.endDate) {
                                if (
                                  detailReminder.endDate > 0 &&
                                  // typeModal === 'ADD' &&
                                  form.getFieldValue('endDate') === 1
                                )
                                  if (value.unix() < moment().unix()) {
                                    return Promise.reject(
                                      'The end date must be greater than or equal to today',
                                    );
                                  }
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <DatePicker
                          size="middle"
                          placeholder={dateFormat.DMY}
                          format={dateFormat.DMY}
                          disabled={!checkEndDate}
                          disabledDate={disabledDate}
                          suffixIcon={false}
                          allowClear={false}
                          onKeyDown={allowDatePicker}
                        />
                      </Form.Item>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}

            <Form.Item
              name="message"
              label="Message"
              rules={[
                {
                  required: true,
                  message: 'Please input your message!',
                },
              ]}
            >
              <Mentions
                rows={3}
                onSelect={(option) => {
                  const filterMentions = reminderMentionTag.filter(
                    (mention) => mention.name !== option.key,
                  );
                  setReminderMentionTag(filterMentions);
                }}
              >
                {reminderMentionTag.map((groupMember) => (
                  <Option
                    key={groupMember.name}
                    value={groupMember.displayName}
                  >
                    {groupMember.displayName}
                  </Option>
                ))}
              </Mentions>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <div className="vbot-text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </React.Fragment>
  );
};
