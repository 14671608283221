import React from 'react';
import MainLayout from 'src/layouts/MainLayout';

export default function Contact() {
  return (
    <MainLayout>
      <div className="page-header">
        <div>
          <h3 className="page-header__name">Contact</h3>
          <p>
            Welcome to the VMO Group workspace. Your role is full member, please
            direct any inquiries or requests to your workspace administrator,{' '}
            <b>HuyHQ</b>, at huyhq@vmogroup.com
          </p>
        </div>
      </div>
    </MainLayout>
  );
}
