import React from 'react';
import './SectionHeader.less';

interface SectionHeaderProps {
  sectionName: string;
  children?: React.ReactNode;
  className?: string;
}

const SectionHeader = ({
  sectionName,
  children,
  className,
}: SectionHeaderProps) => {
  return (
    <div className={`section-header${className ? ` ${className}` : ''}`}>
      <h4 className="section-header__name">{sectionName}</h4>
      <div className="section-header__content">{children}</div>
    </div>
  );
};

export default SectionHeader;
