import { UserOutlined } from '@ant-design/icons';
import { Badge, Button, Descriptions, Form, Input, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateEmailPackage } from 'src/services/paymentPackage';
import { IDataWorkspace } from 'src/services/team';
import { getReminderWorkSpace } from 'src/services/workspace';
import { UpdateFeature } from './UpdateFeature';
import { dateFormat } from 'src/constants';
interface IInfoPackageType {
  reset: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
export const InfoPackage: React.FC<IInfoPackageType> = ({
  reset,
  setRefresh,
}) => {
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showModalUpdate, setShowModalUpdate] = useState<boolean>(false);
  const [dataWorkspace, setDataWorkspace] = useState<IDataWorkspace>();
  useEffect(() => {
    (async () => {
      try {
        const { data: dataGetWorkspace } = await getReminderWorkSpace();
        if (dataGetWorkspace) {
          setDataWorkspace(dataGetWorkspace.data);
        }
      } catch (error) {
        throw error;
      }
    })();
  }, [reset]);
  const handleUpdateEmail = async (valueEmail) => {
    setUpdate(true);
    try {
      const { data } = await updateEmailPackage(valueEmail.email);
      if (data) {
        setUpdate(false);
        toast.success('Update email success');
        setShowModalUpdate(false);
        setTimeout(() => {
          setRefresh(!reset);
        }, 500);
      }
    } catch (error) {
      setUpdate(false);
      throw error;
    }
  };

  return (
    <Descriptions title="Package Info" bordered className="des-package">
      <Descriptions.Item label="Workspace Name">
        <b>{dataWorkspace?.workspaceName}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Package Name" span={2}>
        {dataWorkspace?.paymentPackage?.packageName
          ? dataWorkspace?.paymentPackage?.packageName
          : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Start Time">
        {moment(dataWorkspace?.createdAt).format(dateFormat.DMY)}
      </Descriptions.Item>
      <Descriptions.Item label="Price Per Active Member">
        {dataWorkspace?.paymentPackage?.pricePerActiveMember}
      </Descriptions.Item>
      <Descriptions.Item label="On Trial">
        {dataWorkspace?.paymentPackage?.onTrial ? 'Yes' : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label="Status" span={3}>
        <Badge status="processing" text="Running" />
      </Descriptions.Item>
      <Descriptions.Item label="Payment Email">
        <div className="payment-email">
          <p>{protect_email(dataWorkspace?.paymentPackage?.email)}</p>
          <Button type="primary" ghost onClick={() => setShowModalUpdate(true)}>
            Update Email
          </Button>
          <Modal
            title="Update Email"
            okText="Update"
            visible={showModalUpdate}
            onCancel={() => setShowModalUpdate(false)}
            footer={null}
          >
            <Form {...layout} name="basic" onFinish={handleUpdateEmail}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please your email!' }]}
              >
                <Input allowClear prefix={<UserOutlined />} type="email" />
              </Form.Item>
              <div className="button-email">
                <Form.Item {...tailLayout}>
                  <Button onClick={() => setShowModalUpdate(false)}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit" loading={isUpdate}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Payment Method">
        {dataWorkspace?.paymentPackage?.paymentMethod ? 'Yes' : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label="End Trial At ">
        {dataWorkspace?.paymentPackage?.endTrialAt
          ? moment
              .unix(dataWorkspace?.paymentPackage?.endTrialAt)
              .format(dateFormat.DMY)
          : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label="Allowed Feature" span={1}>
        {dataWorkspace?.allowedFeature.map((feature) => (
          <UpdateFeature
            active={() => setRefresh(!reset)}
            key={feature._id}
            featureData={feature}
          />
        ))}
      </Descriptions.Item>
    </Descriptions>
  );
};
const protect_email = function (user_email) {
  if (!user_email) return;
  var avg, splitted, part1, part2;
  splitted = user_email.split('@');
  part1 = splitted[0];
  avg = part1.length / 2;
  part1 = part1.substring(0, part1.length - avg);
  part2 = splitted[1];
  const a = ['@'];
  for (let i = 0; i < Math.floor(splitted[0].length / 2); i++) {
    a.push('*');
  }
  return part1 + a.reverse().join('') + part2;
};
