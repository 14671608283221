import { AdminAPI } from 'src/configs/axiosInstances';
import {
  IDepartmentAddData,
  IDepartmentQuery,
  IDepartmentUpdateData,
} from './department.interface';
const queryString = require('query-string');

export const getDepartments = (queryParams: Partial<IDepartmentQuery> = {}) => {
  const queryStr = queryString.stringifyUrl(
    { url: '/departments', query: queryParams },
    { skipEmptyString: true },
  );
  return AdminAPI.get(queryStr);
};

export const updateDepartment = (postData: IDepartmentUpdateData) => {
  return AdminAPI.put(`/departments/${postData.id}`, { ...postData });
};

export const deleteDepartment = (id: string) => {
  return AdminAPI.delete(`/departments/${id}`);
};

export const addDepartment = (department: IDepartmentAddData) => {
  return AdminAPI.post('/departments', department);
};
