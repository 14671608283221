import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { toast } from 'react-toastify';
import { updateCardPackage } from 'src/services/paymentPackage';
interface ICheckoutFormType {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  reset: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
const layout = {
  wrapperCol: { span: 16 },
};
export const CheckoutForm: React.FC<ICheckoutFormType> = ({
  setShowModal,
  setRefresh,
  reset,
}) => {
  const [form] = Form.useForm();
  const [valueForm, setValueForm] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const [focus, setFocus] = useState<string>('');
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === 'expiry') {
      value = formatExpirationDate(value);
    }
    setValueForm({ ...valueForm, [name]: value });
  };
  const handleUpdateCard = async (valueForm) => {
    const { name, expiry, ...dataForm } = valueForm;
    dataForm.expMonth = expiry.slice(0, 2);
    dataForm.expYear = expiry.slice(2, 4);
    setUpdate(true);
    try {
      const { data } = await updateCardPackage(dataForm);
      if (data.data.error) {
        toast.error('Update Card Error');
        setUpdate(false);
        return;
      }
      toast.success('Update Card Success');
      setShowModal(false);
      setUpdate(false);
      setRefresh(!reset);
      form.resetFields();
      setFocus('');
      setValueForm({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
      });
    } catch (error) {
      setUpdate(false);
      throw error;
    }
  };

  return (
    <div id="PaymentForm">
      <Cards
        cvc={valueForm.cvc}
        expiry={valueForm.expiry}
        focused={focus}
        name={valueForm.name}
        number={valueForm.number}
        preview={true}
      />
      <Form
        {...layout}
        form={form}
        onFinish={handleUpdateCard}
        name="control-hooks"
      >
        <Form.Item
          name="number"
          label="Card Number"
          rules={[{ required: true }]}
          hasFeedback={valueForm.number.length === 16}
        >
          <Input
            type="number"
            name="number"
            placeholder="card number"
            onChange={handleInputChange}
            onFocus={(e) => setFocus(e.target.name)}
            maxLength={4}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="name"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input
            name="name"
            placeholder="name"
            type="text"
            onChange={handleInputChange}
            onFocus={(e) => setFocus(e.target.name)}
            maxLength={30}
          />
        </Form.Item>
        <div className="form-payment-number">
          <Form.Item
            hasFeedback={valueForm.expiry.length === 5}
            name="expiry"
            label="Expiry"
            rules={[{ required: true }]}
          >
            <Input
              type="number"
              name="expiry"
              value={valueForm.expiry}
              placeholder="expiry"
              onChange={handleInputChange}
              onFocus={(e) => setFocus(e.target.name)}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="cvc"
            label="Cvc"
            rules={[
              { required: true, type: 'regexp', pattern: new RegExp('d{3,4}') },
            ]}
          >
            <Input
              type="number"
              name="cvc"
              value={valueForm.cvc}
              placeholder="cvc"
              onChange={handleInputChange}
              onFocus={(e) => setFocus(e.target.name)}
            />
          </Form.Item>
        </div>
        <div className="form-update-card">
          <div>
            <Button type="primary" ghost onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button htmlType="submit" loading={isUpdate} type="primary">
              Update
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}
