import axiosClient from 'src/configs/axios.configs';
import { IResponse } from 'src/types/response.interface';
import {
  IDataMember,
  IDataTimesheets,
  IRequestTimesheet,
} from './timesheet.interface';

export const getLimitRequestTimesheet = (param) => {
  return axiosClient.get<IResponse<IDataTimesheets>>(`timesheets?${param}`);
};
export const getAllRequestTimesheet = (param) => {
  return axiosClient.get<IResponse<IDataMember[]>>(
    `timesheets/export?${param}`,
  );
};
export const updateRequestTimesheet = (request) => {
  return axiosClient.post<IResponse<IRequestTimesheet>>(
    `timesheets/${request.id}`,
    request.data,
  );
};
export const createRequestTimesheet = (dataNewRequest) => {
  return axiosClient.post<IResponse<string>>(`timesheets`, dataNewRequest);
};

export const getRankingRequestTimesheet = (param = '') => {
  return axiosClient.get<IResponse<any>>(`timesheets/rank?${param}`);
};
