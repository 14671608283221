import React from 'react';
import { Pagination } from 'antd';

export const DepartmentTablePagination = ({
  pagination,
  handlePageChange,
}: any) => {
  if (pagination.total === 0) return null;

  return (
    <Pagination
      className="member-pagination"
      current={pagination.currentPage}
      onChange={handlePageChange}
      pageSize={pagination.limit}
      total={pagination.total}
    />
  );
};
