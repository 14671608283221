import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Chart, Tooltip, Legend, Point, Line, Interval } from 'bizcharts';
import { Button, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import '../style.less';
import { ITeam } from '../../../services/team';
import { rangeDate } from '../../../constants';
import { SelectDateRange } from './SelectDateRange';
import { getTeamReport, ITeamReport } from '../../../services/report';
import { checkDateRange } from '../../../helpers';

type ColumnLineChartProps = {
  viewChart: () => void;
  detailTeam: Partial<ITeam>;
};
export const ColumnLineChart: React.FC<ColumnLineChartProps> = (
  props: ColumnLineChartProps,
) => {
  const { viewChart, detailTeam } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listTeamReports, setListTeamReports] = useState<ITeamReport[]>([]);
  //const data =
  let chartIns: any = null;
  const scale = {
    totalObstacle: {
      min: 0,
      max: detailTeam.members,
    },
    totalReport: {
      min: 0,
      max: detailTeam.members,
    },
  };
  const colors = ['#ff7200', '#62daaa'];
  const loadTeamData = useCallback(
    async (days) => {
      try {
        setIsLoading(true);
        const { data } = await getTeamReport({
          ...checkDateRange(days),
          id: detailTeam._id,
        });

        setIsLoading(false);
        if (data.data) {
          setListTeamReports(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [detailTeam._id],
  );

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(async () => {
      if (detailTeam._id !== '') {
        await Promise.all([loadTeamData('thisWeek')]);
      }
      setIsLoading(false);
    });
  }, [loadTeamData, detailTeam._id]);
  return (
    <div>
      <div className="header-report-chart" style={{ paddingBottom: 16 }}>
        <div>
          <Button
            shape="circle"
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={viewChart}
          />
          <span>{detailTeam.name} </span>
        </div>
        <SelectDateRange
          rangeDateOption={rangeDate.slice(2)}
          reloadData={loadTeamData}
        />
      </div>
      {isLoading ? (
        <div className="wrap-container">
          <Spin />
        </div>
      ) : (
        <Chart
          scale={scale}
          forceFit
          autoFit
          height={400}
          data={listTeamReports}
          onGetG2Instance={(chart) => {
            chartIns = chart;
            chartIns.on('interval:mouseenter', (e) => {
              // console.log(chartIns);
              chartIns.geometries.forEach((g) => {
                if (g.type === 'interval') {
                  (g.getShapes() || []).forEach((s) => {
                    s.set('origin_fill', s.get('attrs').fill);
                    // s.attr('fill', '#6395f9');
                  });
                }
              });
            });
            chartIns.on('interval:mouseleave', (e) => {
              //console.log(chartIns);
              chartIns.geometries.forEach((g) => {
                if (g.type === 'interval') {
                  (g.getShapes() || []).forEach((s) => {
                    s.attr('fill', s.get('origin_fill'));
                  });
                }
              });
            });
          }}
        >
          {/*  如需使用单轴 
  <Axis name="totalReport" visible={true} />
  <Axis name="totalObstacle" visible={false} /> 
  */}
          <Legend
            custom={true}
            allowAllCanceled={true}
            items={[
              {
                value: 'totalReport',
                name: 'Reported',
                marker: {
                  symbol: 'square',
                  style: { fill: colors[0], r: 5 },
                },
              },
              {
                value: 'totalObstacle',
                name: 'Obstacle',
                marker: {
                  symbol: 'hyphen',
                  style: { stroke: colors[1], r: 5, lineWidth: 3 },
                },
              },
            ]}
            onChange={(ev) => {
              const item = ev && ev.item;
              const value = item.value;
              const checked = !item.unchecked;
              const geoms = chartIns.geometries;

              for (let i = 0; i < geoms.length; i++) {
                const geom = geoms[i];
                if (geom.getYScale().field === value) {
                  if (checked) {
                    geom.show();
                  } else {
                    geom.hide();
                  }
                }
              }
            }}
          />
          <Tooltip shared={true} />
          <Interval position="date*totalReport" color={colors[0]} />
          <Line
            position="date*totalObstacle"
            color={colors[1]}
            size={3}
            shape="smooth"
          />
          <Point
            position="date*totalObstacle"
            color={colors[1]}
            size={3}
            shape="circle"
          />
        </Chart>
      )}
    </div>
  );
};
export default ColumnLineChart;
