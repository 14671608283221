import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  TimePicker,
  Tag,
  Form,
  Divider,
  Select,
  Spin,
  Input,
  Switch,
  InputNumber,
} from 'antd';
import * as workspaceService from '../../services/workspace';
import moment from 'moment';
import MainLayout from '../../layouts/MainLayout';
import { IChannel } from '../../services/team';
import * as TeamService from '../../services/team';
import ReactDOM from 'react-dom';
import { listDays, maxWidthInput } from '../../constants';
import PageHeader from '../../components/PageHeader/PageHeader';
import { toast } from 'react-toastify';
import DeleteIcon from 'src/components/Icons/Delete';
import { convertMessageToObject, randStr } from 'src/helpers';

const WorkSpace = () => {
  const { CheckableTag } = Tag;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listChannels, setListChannels] = useState<IChannel[]>([]);
  const [disable, setDisable] = useState(true);
  const [disableBirthday, setDisableBirthday] = useState(false);
  const [disableRecurring, setDisableRecurring] = useState(false);
  const [disableFinalRecurrence, setDisableFinalRecurrence] = useState(false);
  const [workspaceConfig, setWorkspaceConfig] = useState({
    time: '08:45',
    days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    birthdayTime: '09:10',
    channelIdDefault: '',
  });
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
  };
  const handleChange = (tag, checked) => {
    setDisable(false);
    const nextSelectedTags = checked
      ? [...workspaceConfig.days, tag]
      : workspaceConfig.days.filter((t) => t !== tag);
    setWorkspaceConfig({ ...workspaceConfig, days: nextSelectedTags });
  };
  const onValuesChange = (changedValues: any, values: any) => {
    const { channelIdDefault, birthdayTime } = values;
    if (!channelIdDefault || !birthdayTime) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  const onChangeRecurringNotify = (checked) => {
    setDisableRecurring(!checked);
  };
  const onChangeBirthdayNotify = (checked) => {
    setDisableBirthday(!checked);
    setDisable(false);
  };
  const onChangeEnableReportChannelNotify = (checked) => {
    setDisableFinalRecurrence(!checked);
  };

  const onFinish = async (values) => {
    const birthdayMessage: string[] = [];
    for (let key in values) {
      if (key.includes('message_')) {
        birthdayMessage.push(values[key]);
      }
    }
    try {
      setConfirmLoading(true);
      let res = await workspaceService.updateReminderWorkspace({
        ...values,
        time: values.time.format('HH:mm:ss'),
        birthdayTime: values.birthdayTime.format('HH:mm:ss'),
        days: workspaceConfig.days,
        recurrenceInterval: values.recurrenceInterval * 60,
        birthdayMessage,
      });
      if (res.data.data) {
        setConfirmLoading(false);
        setDisable(true);
        toast.success('Update successfully');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadChannels = async () => {
    try {
      const channels = await TeamService.getListChannelsAll();
      if (channels.data.data) {
        setListChannels(channels.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadWorkspaceConfig = async () => {
    try {
      const { data } = await workspaceService.getReminderWorkSpace();
      if (data.data) {
        const config = data.data.workspaceConfig;
        setWorkspaceConfig({
          ...config,
          recurrenceInterval: config.recurrenceInterval / 60,
          channelIdDefault: config.channelIdDefault,
        });
        setMessages(convertMessageToObject(config.birthdayMessage || []));
        setDisableBirthday(!config.isUsingBirthdayNotify);
        setDisableRecurring(!config.enableRecurringNotify);
        setDisableFinalRecurrence(!config.enableReportChannelNotify);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const messagesObj = messages.reduce((acc, curr, idx) => {
    acc[curr.id] = curr.message;
    return acc;
  }, {});
  const handleDeleteMessage = (deletedMessage) => {
    if (messages.length <= 1) {
      toast.error('One message is required');
      return;
    }

    const newArr = messages.filter((msg, idx) => msg.id !== deletedMessage.id);
    setMessages([...newArr]);
  };
  const handleAddMessage = () => {
    if (messages.length >= 6) {
      toast.error('You can only create less or equal than 6 messages');
      return;
    }
    setMessages([
      ...messages,
      { id: randStr('message_'), message: 'Happy birthday!' },
    ]);
  };
  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(async () => {
      await Promise.all([loadChannels(), loadWorkspaceConfig()]);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    if (formRef.current) {
      form.validateFields([
        'reportChannelNotifyMessage',
        'numberOfRepetition',
        'recurrenceInterval',
        'birthdayMessage',
      ]);
    }
  }, [
    disableRecurring,
    disableFinalRecurrence,
    disableBirthday,
    formRef,
    form,
  ]);
  const existInListChannel = () => {
    const isExist = listChannels.some(
      (channel) => channel.channelId === workspaceConfig.channelIdDefault,
    );
    return isExist;
  };
  return (
    <MainLayout>
      <div>
        <PageHeader headerName="Workspace Configs" />
        <Divider />
        {isLoading ? (
          <div className="wrap-container">
            <Spin />
          </div>
        ) : (
          <Form
            {...layout}
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
              ...workspaceConfig,
              ...messagesObj,
              time: moment(workspaceConfig.time, 'hh:mm'),
              birthdayTime: moment(workspaceConfig.birthdayTime, 'hh:mm'),
              channelIdDefault: existInListChannel()
                ? workspaceConfig.channelIdDefault
                : undefined,
            }}
            ref={formRef}
          >
            <h4 className="vbot-hidden">Reminder Configs</h4>
            <Form.Item label="Work days" className="vbot-hidden">
              {listDays.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={workspaceConfig.days.indexOf(tag) > -1}
                  onChange={(checked) => handleChange(tag, checked)}
                >
                  <Button
                    shape="circle"
                    type={
                      workspaceConfig.days.indexOf(tag) > -1
                        ? 'primary'
                        : 'default'
                    }
                  >
                    {tag}
                  </Button>
                </CheckableTag>
              ))}
            </Form.Item>
            <Form.Item
              className="vbot-hidden"
              name="time"
              label="Time Reminder"
              rules={[
                {
                  required: true,
                  message: 'Please select time!',
                },
              ]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
            <Form.Item
              className="vbot-hidden"
              name="enableRecurringNotify"
              label="Recurring Reminder"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Y"
                unCheckedChildren="N"
                onChange={onChangeRecurringNotify}
              />
            </Form.Item>
            <Form.Item label="Repeat Reminder" className="vbot-hidden">
              <Form.Item
                name="numberOfRepetition"
                noStyle
                rules={[
                  {
                    required: !disableRecurring,
                    message: 'Please input number of repetition!',
                  },
                ]}
              >
                <InputNumber min={1} disabled={disableRecurring} />
              </Form.Item>
              {` `}time(s) every{` `}
              <Form.Item
                name="recurrenceInterval"
                noStyle
                rules={[
                  {
                    required: !disableRecurring,
                    message: 'Please input time of repetition!',
                  },
                ]}
              >
                <InputNumber min={1} disabled={disableRecurring} max={1200} />
              </Form.Item>
              {` `}min(s){` `}
            </Form.Item>
            <Form.Item
              className="vbot-hidden"
              name="enableReportChannelNotify"
              label="Report Channel Notify"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Y"
                unCheckedChildren="N"
                disabled={disableRecurring}
                onChange={onChangeEnableReportChannelNotify}
              />
            </Form.Item>
            <Form.Item
              className="vbot-hidden"
              name="reportChannelNotifyMessage"
              label="Final Message"
              validateFirst={true}
              rules={[
                {
                  required:
                    disableFinalRecurrence || disableRecurring ? false : true,
                  message: 'Please input your message!',
                },
                () => ({
                  validator(rule, value) {
                    if (disableFinalRecurrence || disableRecurring)
                      return Promise.resolve();
                    if (value.includes('@name')) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Your input must have '@name' !");
                  },
                }),
              ]}
            >
              <Input.TextArea
                autoSize
                style={{ maxWidth: maxWidthInput }}
                placeholder="I haven't received your reports @name . What's wrong?"
                disabled={disableFinalRecurrence || disableRecurring}
              />
            </Form.Item>
            <h4>Birthday Configs</h4>
            <Form.Item
              name="isUsingBirthdayNotify"
              label="Birthday Notify"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Y"
                unCheckedChildren="N"
                onChange={onChangeBirthdayNotify}
              />
            </Form.Item>
            {/* <Form.Item
              name="birthdayMessage"
              label="Birthday Message"
              className="vbot-hidden"
              validateFirst={true}
              rules={[
                {
                  required: !disableBirthday,
                  message: 'Please input your message!',
                },
                () => ({
                  validator(rule, value) {
                    if (disableBirthday) return Promise.resolve();
                    if (value.includes('@name')) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Your input must have '@name' !");
                  },
                }),
              ]}
            >
              <Input.TextArea
                autoSize
                placeholder="Happy birthday to @name !!!"
                disabled={disableBirthday}
              />
            </Form.Item> */}
            <Form.Item
              name="birthdayTime"
              label="Time Notify"
              rules={[
                {
                  required: !disableBirthday,
                  message: 'Please select time!',
                },
              ]}
            >
              <TimePicker format="HH:mm" disabled={disableBirthday} />
            </Form.Item>
            <Form.Item
              name="channelIdDefault"
              label="Channel / Space"
              rules={[
                {
                  required: !disableBirthday,
                  message: 'Channel / Space is required!',
                },
              ]}
            >
              <Select
                placeholder={
                  existInListChannel()
                    ? 'Select a channel / Space'
                    : workspaceConfig.channelIdDefault
                }
                allowClear
                disabled={disableBirthday}
                options={listChannels.map((channel) => ({
                  value: channel.channelId,
                  label: channel.channelName,
                }))}
              />
            </Form.Item>
            {messages.map((msg, idx) => (
              <React.Fragment key={'messageId' + idx}>
                <Form.Item
                  label={`Message ${idx + 1}`}
                  name={msg.id}
                  rules={[
                    {
                      required: !disableBirthday,
                      message: 'Message is required',
                    },
                  ]}
                  extra={
                    <Button
                      disabled={disableBirthday}
                      onClick={() => handleDeleteMessage(msg)}
                    >
                      <DeleteIcon tooltip="Delete" />
                    </Button>
                  }
                  className="modal-edit-department-form-item-message"
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    className="custom-scrollbar"
                    disabled={disableBirthday}
                  >
                    {msg.message}
                  </Input.TextArea>
                </Form.Item>
              </React.Fragment>
            ))}
            <h4 className="vbot-hidden">Welcome configs</h4>
            <Form.Item
              className="vbot-hidden"
              name="welcomeMessage"
              label="Welcome Message"
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: 'Please input your message!',
                },
                () => ({
                  validator(rule, value) {
                    if (value.includes('@name')) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Your input must have '@name' !");
                  },
                }),
              ]}
            >
              <Input.TextArea
                autoSize
                style={{ maxWidth: maxWidthInput }}
                placeholder="Welcome to @name !!!"
              />
            </Form.Item>

            <Form.Item
              className="vbot-hidden"
              name="instructionMessage"
              label="Instruction Message"
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: 'Please input your message!',
                },
              ]}
            >
              <Input.TextArea
                autoSize
                style={{ maxWidth: maxWidthInput }}
                placeholder="Instruction..."
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="link"
                disabled={disableBirthday}
                onClick={handleAddMessage}
              >
                Add a message
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={confirmLoading}
                disabled={disable}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </MainLayout>
  );
};

export default WorkSpace;
