import { Form, Modal, Select } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { UserRole } from 'src/constants/Roles';
import { AuthContext } from 'src/contexts/Auth';
import { IUser } from 'src/services/user';
import * as memberService from 'src/services/member';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
interface RoleMembersProps {
  member: IUser;
}

const { Option } = Select;

function RoleMembers({ member }: RoleMembersProps) {
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentAccountType, setCurrentAccountType] = useState(member.role);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const preAccountTypeRef = useRef(currentAccountType);

  const createRoleOptions = () => {
    const roles = Object.keys(UserRole) as Array<keyof typeof UserRole>;
    return roles.map((role) => {
      if (role === 'WORKSPACE_ADMIN') {
        return {
          value: UserRole[role],
          label: 'Workspace Admin',
          shouldDisable: user.role !== UserRole.WORKSPACE_ADMIN,
        };
      }

      if (role === 'DEPARTMENT_ADMIN') {
        return {
          value: UserRole[role],
          label: 'Department Admin',
          shouldDisable: false,
        };
      }
      return {
        value: UserRole[role],
        label: 'Full member',
        shouldDisable: false,
      };
    });
  };

  const handleUpdateRole = async () => {
    try {
      setLoadingUpdate(true);
      const accountType = form.getFieldValue('accountType') as UserRole;
      const res = await memberService.updateAccountType(
        member._id as string,
        accountType.toUpperCase() as UserRole,
      );
      if (res instanceof Error) {
        let error = res as unknown as AxiosError;
        toast.error(error.response?.data.errors.message);
        return;
      }
      preAccountTypeRef.current = accountType;
      setOpenConfirm(false);
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong');
    } finally {
      setLoadingUpdate(false);
    }
  };

  const isMe = member.userId === user.userId;

  return (
    <div>
      <Form
        initialValues={{
          accountType: member.role,
        }}
        form={form}
      >
        <Form.Item name="accountType" style={{ marginBottom: 0 }}>
          <Select
            disabled={
              (user.role === UserRole.DEPARTMENT_ADMIN &&
                member.role === UserRole.WORKSPACE_ADMIN) ||
              isMe
            }
            onSelect={(v, o) => {
              if (currentAccountType !== member.role) {
                setOpenConfirm(true);
              }
              setCurrentAccountType(v as UserRole);
              setOpenConfirm(true);
            }}
          >
            {createRoleOptions().map((role) => (
              <Option
                value={role.value}
                key={role.value}
                disabled={
                  role.shouldDisable || preAccountTypeRef.current === role.value
                }
              >
                {role.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {openConfirm && (
        <Modal
          maskClosable={false}
          visible={openConfirm}
          title="Confirm change"
          onCancel={() => {
            setOpenConfirm(false);
            form.resetFields();
          }}
          onOk={handleUpdateRole}
          confirmLoading={loadingUpdate}
        >
          Are you sure to change this user to{' '}
          {
            createRoleOptions().find(
              (role) => role.value === currentAccountType,
            )?.label
          }
          ?
        </Modal>
      )}
    </div>
  );
}

export default RoleMembers;
