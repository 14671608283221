import React from 'react';
import MainLayout from 'src/layouts/MainLayout';

const PaymentsHistory = () => {
  return (
    <MainLayout>
      <h1>hello</h1>
    </MainLayout>
  );
};

export default PaymentsHistory;
