import axiosClient from '../../configs/axios.configs';
import {
  AdminAPI,
  AdminUploadAPI,
  GoogleAPI,
} from 'src/configs/axiosInstances';
import { IResponse } from '../../types/response.interface';
import {
  IMember,
  IMention,
  ImportMembersResponse,
  UpdateMembersDto,
} from './member.interface';
import { IUser } from '../user';
import { OnService } from 'src/helpers';
import { UserRole } from 'src/constants/Roles';

export const getListMember = () => {
  return axiosClient.get<IResponse<IMember[]>>('members');
};
export const getListNewMember = (departmentId: string) => {
  return AdminAPI.get<IResponse<IMember[]>>('members/new', {
    params: {
      departmentId,
    },
  });
};
export const updateBirthdayMember = (data: Partial<IUser>) => {
  return axiosClient.patch<IResponse<boolean>>('members/' + data.userId, {
    ...data,
    dob: data.birthday,
  });
};
export const postMember = (member: Partial<IMember>) => {
  return AdminAPI.post<IResponse<IMember>>('/members', member);
};
export const updateMember = (member: IMember) => {
  return AdminAPI.put<IResponse<IMember>>('/members/' + member._id, member);
};
export const updateProfile = (member: IMember) => {
  return AdminAPI.put<IResponse<IMember>>('/members/profile', member);
};
export const deleteMember = (memberID: string) => {
  return AdminAPI.delete<IResponse<IMember>>('/members/' + memberID);
};
export const activeMember = (memberID: string) => {
  return AdminAPI.put<IResponse<IMember>>('/members/active-member/' + memberID);
};
export const getListActiveMember = () => {
  return axiosClient.get<IResponse<IMember[]>>('active-member');
};

export const updateStatusMember = (idMember) => {
  return axiosClient.patch<IResponse<boolean>>('active-member/' + idMember);
};

export const getFilterMember = ({
  text,
  onService,
  offset,
  limit,
}: {
  text?: string;
  onService?: OnService;
  offset?: number;
  limit?: number;
}) => {
  if (text === '') {
    text = undefined;
  }
  return axiosClient.get<
    IResponse<{
      count: number;
      data: IMember[];
    }>
  >('members/search', {
    params: {
      text,
      onService,
      offset,
      limit,
    },
  });
};

type FilterMember = {
  text?: string;
  onService?: OnService | boolean;
  offset?: number;
  limit?: number;
  departmentId?: string;
  accountType?: UserRole | undefined;
};

export const getFilterMemberV2 = ({
  text,
  onService,
  offset,
  limit,
  departmentId,
  accountType,
}: FilterMember) => {
  if (text === '') {
    text = undefined;
  }

  switch (onService) {
    case OnService.All:
      onService = undefined;
      break;
    case OnService.Active:
      onService = true;
      break;
    case OnService.InActive:
      onService = false;
      break;
  }
  return axiosClient.get<
    IResponse<{
      count: number;
      members: IMember[];
    }>
  >('members/search', {
    params: {
      text,
      onService,
      offset,
      limit,
      sortKey: 'createdAt',
      sortValue: 'DESC',
      departmentId,
      accountType,
    },
  });
};
export const getFilteredMembers = ({
  text,
  onService,
  offset,
  limit,
  departmentId,
  accountType,
}: FilterMember) => {
  if (text === '') {
    text = undefined;
  }

  switch (onService) {
    case OnService.All:
      onService = undefined;
      break;
    case OnService.Active:
      onService = true;
      break;
    case OnService.InActive:
      onService = false;
      break;
  }
  return AdminAPI.get<
    IResponse<{
      count: number;
      members: IMember[];
    }>
  >('members', {
    params: {
      text,
      active: onService,
      offset,
      limit,
      sortKey: 'createdAt',
      sortValue: 'DESC',
      departmentId,
      accountType,
    },
  });
};

export const syncMembers = () => {
  return axiosClient.post('/members/sync-member', {});
};

export const syncMembersAdmin = (departmentId?: string) => {
  return AdminAPI.post('/members/sync-member', {
    departmentId,
  });
};

export const updateAccountType = (userId: string, accountType: UserRole) => {
  return AdminAPI.post<IResponse<boolean>>(
    `/departments/update-member/${userId}`,
    {
      accountType: accountType.toUpperCase(),
    },
  );
};

export const getMentionsByChannelId = (channelId: string) => {
  return GoogleAPI.get<IResponse<IMention[]>>('members', {
    params: { channelId },
  });
};

export const importMembers = (fileSelected: any, departmentId: string) => {
  const formData = new FormData();
  formData.append('file', fileSelected);
  formData.append('departmentId', departmentId);
  return AdminUploadAPI.post<IResponse<ImportMembersResponse[]>>(
    '/members/import',
    formData,
  );
};

export const updateMembers = (payload: UpdateMembersDto) => {
  return AdminAPI.post<IResponse<boolean>>('/members/update-members', payload);
};
