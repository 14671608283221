import React, { useState } from 'react';
import { Modal, Button, List } from 'antd';
import * as questionPackService from '../../../services/questionPack';
import { IQuestionPack } from '../../../services/team';
import { IQuestion } from '../../../services/questionPack/questionPack.interface';
import '../style.less';

type ViewModalProps = {
  title: string;
  BtnContent: React.ReactNode;
  detailQuestionPack: IQuestionPack;
};

export const ViewQuestionPackModal: React.FC<ViewModalProps> = (
  props: ViewModalProps,
) => {
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(false);
  const { title, BtnContent, detailQuestionPack } = props;
  const [questionList, setQuestionList] = useState<IQuestion[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const showModal = async () => {
    setLoadingQuestions(true);
    const { data } = await questionPackService.getQuestionsOfPack(
      detailQuestionPack._id,
    );
    if (data.data) {
      setQuestionList(data.data);
      setLoadingQuestions(false);
      setVisible(true);
    }
    // setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Button
        type="link"
        onClick={showModal}
        loading={loadingQuestions}
        icon={BtnContent}
      />
      <div className="view-question-modal">
        <Modal
          visible={visible}
          title={title || 'Title'}
          onCancel={handleCancel}
          footer={null}
        >
          <List
            dataSource={questionList}
            renderItem={(item, index) => (
              <List.Item>
                <div className="view-question">
                  <p className="question">{index + 1 + '. ' + item.content}</p>
                  {item.hint && (
                    <p className="question-hint">Hint : {item.hint}</p>
                  )}
                  <p className="question-hint">Type : {item.type}</p>
                </div>
              </List.Item>
            )}
          />
        </Modal>
      </div>
    </React.Fragment>
  );
};
