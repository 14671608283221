import { Button } from 'antd';
import React, { useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AuthContext } from 'src/contexts/Auth';
import { UserRole } from 'src/constants/Roles';
interface DepartmentHeaderProps {
  onAddDepartment: () => void;
}
export default function DepartmentHeader({
  onAddDepartment,
}: DepartmentHeaderProps) {
  const { user } = useContext(AuthContext);

  return (
    <div className="page-header">
      <div>
        <h3 className="page-header__name">Departments</h3>
        <p style={{ fontStyle: 'italic' }}>
          This page is designed for department administrators to configure their
          department information. They can update the department name, birthday
          message, and description.
          <br />
          Any changes made here will overwrite the current configuration in the
          workspace.
        </p>
      </div>
      <div className="page-header__content">
        {user.role === UserRole.WORKSPACE_ADMIN && (
          <Button
            onClick={onAddDepartment}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add a department
          </Button>
        )}
      </div>
    </div>
  );
}
