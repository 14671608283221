/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Spin, Empty } from 'antd';
import {
  CountReport,
  ObstacleCount,
  PieChartTeam,
  TeamReport,
} from './components';
import MainLayout from '../../layouts/MainLayout';
import './style.less';
import * as reportService from '../../services/report';
import moment from 'moment';
import { PAGINATION } from '../../constants';
import {
  IListReports,
  IPieChartReportByTeam,
  IReportByTeam,
} from '../../services/report';
import TableRanking from './components/TableRanking';
import { getListActiveMember, IMember } from 'src/services/member';
const Report: React.FC = () => {
  const [listMember, setListMember] = useState<IMember[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listReports, setListReports] = useState<IListReports>({
    page: PAGINATION.page,
    totalPages: 0,
    reports: [],
  });
  const [overview, setOverview] = useState<Partial<IReportByTeam>>({
    pending: 0,
    reported: 0,
    totalObstacles: 0,
    teamId: '',
  });
  const [reportsByTeam, setReportsByTeam] = useState<IPieChartReportByTeam>({
    pending: 0,
    reported: 0,
    totalObstacles: 0,
    reportsByTeam: [],
  });

  const loadReportByTeam = async () => {
    try {
      const { data } = await reportService.getReportsByTeam();
      if (data.data) {
        const totalReports = data.data.reported;
        let totalObstacles = 0;
        let totalPercent = 0;
        let mapReport = data.data.reportsByTeam.map((item) => {
          totalObstacles += item.totalObstacles;
          totalPercent +=
            Math.round((item.totalReports * 1000) / totalReports) / 1000;
          return {
            name: item.teamName,
            count: item.totalReports,
            percent:
              Math.round((item.totalReports * 1000) / totalReports) / 1000,
            _id: item.teamId,
            members: item.totalMembers,
            totalObstacles: item.totalObstacles,
            totalReports: item.totalReports,
          };
        });
        if (totalPercent !== 1) {
          mapReport[mapReport.length - 1].percent =
            mapReport[mapReport.length - 1].percent - (totalPercent - 1);
        }
        setReportsByTeam({
          pending: data.data.pending,
          reported: data.data.reported,
          totalObstacles: totalObstacles,
          reportsByTeam: mapReport,
        });
        setOverview({
          pending: data.data.pending,
          reported: data.data.reported,
          totalObstacles: totalObstacles,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadListReports = async (
    page: number = PAGINATION.page,
    limit: number = PAGINATION.pageSize,
    startDate: number = moment().subtract(7, 'days').unix(),
    endDate: number = moment().unix(),
    teams: string[] = [],
  ) => {
    try {
      const { data } = await reportService.getReports({
        page,
        limit,
        startDate,
        endDate,
        teams,
      });
      if (data.data) {
        setListReports(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(async () => {
      await Promise.all([loadReportByTeam(), loadListReports()]);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getListActiveMember();
        if (data.data) {
          setListMember(data.data);
        }
      } catch (error) {
        throw error;
      }
    })();
  }, []);
  return (
    <MainLayout>
      {isLoading ? (
        <div className="wrap-container">
          <Spin />
        </div>
      ) : (
        <>
          {reportsByTeam.reportsByTeam.length <= 0 ||
          reportsByTeam.pending + reportsByTeam.reported <= 0 ? (
            <div className="wrap-container">
              <Empty />
            </div>
          ) : (
            <>
              <Row className="report-chart" gutter={8}>
                <Col sm={24} md={7}>
                  <div className="chart-right">
                    {/* <TestExcel /> */}
                    <h3>Overview Today</h3>
                    <CountReport
                      pending={overview.pending || 0}
                      reported={overview.reported || 0}
                    />
                    <ObstacleCount
                      obstacles={overview.totalObstacles || 0}
                      teamId={overview.teamId || ''}
                    />
                  </div>
                </Col>
                <Col sm={24} md={17}>
                  <div className="chart-left">
                    <PieChartTeam
                      data={reportsByTeam}
                      resetOverview={setOverview}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ paddingTop: 32 }}>
                  <TeamReport
                    listTeams={reportsByTeam.reportsByTeam.map((item) => {
                      return { text: item.name, value: item._id };
                    })}
                    listDefaultReports={listReports}
                    loadListReports={loadListReports}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <div className="report-ranking-container">
        <TableRanking
          listTeams={reportsByTeam.reportsByTeam.map((item) => {
            return { text: item.name, value: item._id };
          })}
          // listDefaultReports={listReports}
          // loadListReports={loadListReports}
          listMember={listMember}
        />
      </div>
    </MainLayout>
  );
};

export default Report;
