import { IWorkspaceConfig } from './../workspace/workspace.interface';
import { IResponse } from '../../types/response.interface';
import { ITeam, IChannel, IQuestionPack } from './team.interface';
import axiosClient from '../../configs/axios.configs';

export const getListTeam = () => {
  return axiosClient.get<IResponse<ITeam[]>>('teams');
};
export const getOneTeam = (id: string) => {
  return axiosClient.get<IResponse<ITeam>>('teams/' + id);
};
export const getListChannels = () => {
  return axiosClient.get<IResponse<IChannel[]>>('channels');
};
export const getListChannelsAll = () => {
  return axiosClient.get<IResponse<IChannel[]>>('channels/all');
};
export const getListChannelsAvailable = () => {
  return axiosClient.get<IResponse<IChannel[]>>('channels/available');
};
export const checkChannelOnTeam = (id: any) => {
  return axiosClient.post<IResponse<boolean>>('channels', id);
};
export const getListQuestionPacks = () => {
  return axiosClient.get<IResponse<IQuestionPack[]>>('question-pack');
};
export const createTeam = (data: {
  newTeam: Partial<ITeam>;
  dateTime?: Partial<IWorkspaceConfig>;
}) => {
  return axiosClient.post<IResponse<ITeam>>('teams', data);
};
export const updateTeam = (newTeam: Partial<ITeam>) => {
  return axiosClient.patch<IResponse<ITeam>>('teams/' + newTeam._id, newTeam);
};
export const deleteTeam = (id: string) => {
  return axiosClient.delete<IResponse<boolean>>('teams/' + id);
};
export const updateActiveMember = (data) => {
  return axiosClient.patch<IResponse<boolean>>(
    'teams/' + data.teamId + '/update-status',
    {
      member: data.memberId,
    },
  );
};
export const addMemberForTeam = (data: Partial<ITeam>) => {
  return axiosClient.put<IResponse<boolean>>(
    'teams/' + data._id + '/add-members',
    {
      members: data.members,
    },
  );
};
export const removeMemberOnTeam = (data) => {
  return axiosClient.patch<IResponse<boolean>>(
    'teams/' + data.teamId + '/remove-member',
    {
      members: data.userId,
    },
  );
};
